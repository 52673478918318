import React from "react";
import { TouchableOpacity, View, Text, StyleSheet } from "react-native";

import getFlagComponent from "./getFlagComponent";

interface PaisType {
  id: string;
  name: string;
  institucionPais: string;
  idType: string;
  idExample: string;
  idFormat: string;
  noUserMessage: string;
  errorPass: string;
  helpUserMessage: string;
  helpPassMessage: string;
}

interface PaisButtonType {
  pais: PaisType;
  onPress: () => void;
}

export default function PaisButton({ pais, onPress }: PaisButtonType) {
  return (
    <TouchableOpacity
      style={styles.button}
      onPress={onPress}
      testID="paisButton"
      data-test="paisButton"
    >
      <View style={styles.stretch}>{getFlagComponent(pais.name)}</View>
      <Text style={styles.text}>{pais.name}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    margin: 20,
    paddingVertical: 5,
    borderRadius: 8,
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.5,
    shadowColor: "grey",
    elevation: 5,
  },
  stretch: {
    width: 120,
    height: 100,
    resizeMode: "stretch",
    shadowColor: "grey",
    elevation: 5,
  },
  text: {
    fontWeight: "bold",
    fontSize: 20,
  },
});
