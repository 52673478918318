import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";
import moment from "moment";

import { exerciseInitialStatus } from "./exerciseInitialState";
import {
  ExerciseGraphQL,
  ExerciseIndexState,
  ExerciseProgressUploadType,
  ExerciseWithStatus,
  FlagsInfoType,
  InfoFlags,
  InputFinishExerciseType,
  MergeExerciseType,
  TareaGraphQL,
  TareaWithStatus,
} from "../Interfaces";
import {
  exerciseLocalStorageKey,
  exerciseUserInfoStorageKey,
} from "../components/Utils";

export const ASSETS_VERSION = 5;

export function updateExercise(
  tareasIndex: TareaWithStatus[],
  tareaAsignadaId: number,
  exerciseId: number,
  mergeObject: MergeExerciseType
) {
  return _.chain(tareasIndex)
    .map((tarea) => {
      if (tarea.tarea_asignada_id === tareaAsignadaId) {
        const newEjercicios = _.map(tarea.ejercicios, (ejercicio) => {
          if (ejercicio.id === exerciseId) {
            if (ejercicio.user.completed) {
              return { ...ejercicio };
            } else {
              return { ...ejercicio, ...mergeObject };
            }
          } else {
            return { ...ejercicio };
          }
        });
        return { ...tarea, ejercicios: newEjercicios };
      } else {
        return { ...tarea };
      }
    })
    .filter((tarea) => {
      return !_.isEmpty(tarea.ejercicios);
    })
    .value();
}

export function flagsCurrentYear(flags: FlagsInfoType) {
  const yearC = "Y" + moment().year().toString();
  return flags.flagsYear[yearC] || 0;
}

export function addFlags(flagsCounters: FlagsInfoType, increment: number) {
  const flags = { ...flagsCounters };
  const yearC = "Y" + moment().year().toString();
  const monthC = "M" + moment().format("YYYY#MM").toString();
  const weekC = "W" + moment().format("YYYY#WW").toString();
  flags.flagsYear[yearC] = (flags.flagsYear[yearC] || 0) + increment;
  flags.flagsMonth[monthC] = (flags.flagsMonth[monthC] || 0) + increment;
  flags.flagsWeek[weekC] = (flags.flagsWeek[weekC] || 0) + increment;
  return flags;
}

export function mergeIndex(
  currentIndex: TareaWithStatus[],
  newIndex: TareaWithStatus[]
) {
  const output = [];

  const tareasProcesadas = [];
  for (const currentTarea of currentIndex) {
    const newTarea = _.find(newIndex, {
      tarea_asignada_id: currentTarea.tarea_asignada_id,
    });
    if (newTarea) {
      output.push(_.merge(newTarea, currentTarea));
    } else {
      //set each exercise as completed
      const currentExercises = _.map(currentTarea.ejercicios, (e) => {
        return { ...e, completed: true };
      });
      output.push({ ...currentTarea, ejercicios: currentExercises });
    }
    tareasProcesadas.push(currentTarea.tarea_asignada_id);
  }

  for (const newTarea of newIndex) {
    if (!tareasProcesadas.includes(newTarea.tarea_asignada_id)) {
      output.push({ ...newTarea });
    }
  }

  return output;
}

export function finishExercise(
  state: ExerciseIndexState,
  action: InputFinishExerciseType
) {
  //if the user finishes an exercise we need to increase flags counter and set the exercise as completed
  const currentTarea = _.find(state.tareas, {
    tarea_asignada_id: action.tareaAsignadaId,
  });
  if (!currentTarea) {
    return state;
  }
  const currentExercise = _.find(currentTarea.ejercicios, {
    id: action.exerciseId,
  });
  if (!currentExercise) {
    return state;
  }
  if (currentExercise.user.completed) {
    return state;
  }

  return {
    ...state,
    flags: addFlags(state.flags, action.flags),
    tareas: updateExercise(
      state.tareas,
      action.tareaAsignadaId,
      action.exerciseId,
      {
        completed: true,
        banderas: action.flags,
        nota: action.nota,
        omitido: action.omitido,
      }
    ),
    exercisesPendingSync: true,
  };
}

async function getStatusForExercise(
  tareaAsignadaId: number,
  exercise: ExerciseGraphQL
): Promise<ExerciseWithStatus> {
  const exerciseId = exercise.id;
  const exerciseUserKey = exerciseUserInfoStorageKey(
    exerciseId,
    tareaAsignadaId,
    exercise.position
  );
  const userInfoDBRaw = await AsyncStorage.getItem(exerciseUserKey);
  const storedExercise = await AsyncStorage.getItem(
    exerciseLocalStorageKey(exerciseId, tareaAsignadaId)
  );
  let availableOffline = false;
  if (storedExercise) {
    availableOffline = exercise.updated_at === storedExercise;
  }
  const initialStatus = {
    ...exerciseInitialStatus(exerciseId, tareaAsignadaId),
  };
  if (userInfoDBRaw) {
    const userInfoDB = JSON.parse(userInfoDBRaw) as ExerciseProgressUploadType;
    initialStatus.user = { ...initialStatus.user, ...userInfoDB.payload };
  }

  return { ...exercise, user: initialStatus.user, availableOffline };
}

async function getStatusForTarea(tarea: TareaGraphQL) {
  const output = { ...tarea };
  const exercises = [];
  for (const exercise of tarea.ejercicios) {
    exercises.push(
      await getStatusForExercise(tarea.tarea_asignada_id, exercise)
    );
  }
  output.ejercicios = exercises as ExerciseWithStatus[];
  return output;
}

export const getUserStatusForTareas = async (
  tareas: TareaGraphQL[]
): Promise<TareaWithStatus[]> => {
  const exerciseStatus = [];
  for (const tarea of tareas) {
    exerciseStatus.push(await getStatusForTarea(tarea));
  }
  return exerciseStatus;
};

export function mapFlags(flags: InfoFlags[]) {
  if (flags) {
    return _.chain(flags)
      .map((flagsInPeriod) => {
        return [flagsInPeriod.key, flagsInPeriod.flags];
      })
      .fromPairs()
      .value();
  } else {
    return {};
  }
}

export function extractFlags(alumnoCurso: {
  flagsMonth?: InfoFlags[];
  flagsWeek?: InfoFlags[];
  flagsYear?: InfoFlags[];
}) {
  console.log("extractFlags", alumnoCurso);
  return {
    flagsMonth: mapFlags(alumnoCurso.flagsMonth),
    flagsWeek: mapFlags(alumnoCurso.flagsWeek),
    flagsYear: mapFlags(alumnoCurso.flagsYear),
  };
}
