import React from "react";
import { StyleProp } from "react-native";
import { G } from "react-native-svg";

import { OnFocusFnType } from "./RectInterfaces";
import { getProperties, answerIsANumber } from "./rectFunctions";
import { AnswerStatus } from "../ExerciseComponents/AnswerFeedback/Draw";
import { RectB9, TextB9 } from "../ExerciseComponents/SvgB9";

interface RenderTextInputType {
  id: string;
  frase?: { $: string };
  fontAnswerSize: number;
  fontTextSize: number;
  fontAnswerWeight: string;
  typeReviewAnswer: "phraseReview" | "letterReview";
  text: string;
  userAnswer: string;
  userAnswerStatus: string;
  isPlayButtonVisible: boolean;
  styles: StyleProp<any>;
  onInputOnFocus: (input: OnFocusFnType) => void;
  hasText: boolean;
}

export default function RenderTextInput(props: RenderTextInputType) {
  const [userAnswer, changeText] = React.useState(props.userAnswer);
  const textIsNumber = answerIsANumber(props.frase);

  const { dimensions, fontSize, textCoors } = getProperties(props);

  function onPress() {
    return props.onInputOnFocus({
      recId: props.id,
      questionText: props.text,
      userAnswer,
      styles: props.styles,
      correctAnswer: props.frase.$,
      changeText,
      answerIsNumber: textIsNumber,
      typeReviewAnswer: props.typeReviewAnswer,
    });
  }

  if (Object.entries(props.frase).length === 0) {
    return null;
  } else {
    const stroke =
      props.userAnswerStatus === AnswerStatus.RIGHT ? "green" : "lightgrey";
    return (
      <G>
        <RectB9
          y={dimensions.y}
          x={dimensions.x}
          width={dimensions.width}
          height={dimensions.height}
          fill="white"
          rx={4}
          ry={4}
          stroke={stroke}
          strokeWidth={2}
          onPressIn={onPress}
        />
        <TextB9
          y={textCoors.y}
          x={textCoors.x}
          id="Text"
          fontWeight={props.fontAnswerWeight}
          fontFamily="Arial"
          fontSize={fontSize}
          textAnchor="middle"
          fill="black"
          onPressIn={onPress}
        >
          {userAnswer}
        </TextB9>
      </G>
    );
  }
}
