import React from "react";
import { View, TouchableOpacity, Text } from "react-native";

interface InputSkip {
  open: (isOpen: boolean) => void;
  styles: { skipButton: object; textSkipButton: object; skipBox: object };
  show: boolean;
}

export default function SkipButton({ open, styles, show }: InputSkip) {
  const openModal = () => {
    open(true);
  };

  if (show) {
    return (
      <View style={styles.skipBox}>
        <TouchableOpacity style={styles.skipButton} onPress={openModal}>
          <Text
            style={styles.textSkipButton}
            selectable={false}
            allowFontScaling={false}
          >
            Omitir
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
  return <View />;
}
