import React from "react";
import { View, Text } from "react-native";

import { getStyles } from "./getStyles";

interface InputTimer {
  accumulatedTime: { mins: string; secs: string };
}
export default function Timer({ accumulatedTime }: InputTimer) {
  const styles = getStyles("green");

  return (
    <View style={styles.headerBox}>
      <View>
        <Text selectable={false} allowFontScaling={false}>
          Tiempo
        </Text>
      </View>
      <View style={styles.numberView}>
        <Text
          allowFontScaling={false}
          style={styles.number}
          selectable={false}
        >{`${accumulatedTime.mins}:${accumulatedTime.secs}`}</Text>
      </View>
    </View>
  );
}
