import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

import ModalAuthError from "../../components/AuthComponents/ModalAuthError";
import { AuthenticationContext } from "../../components/AuthenticationContext";
import ConectaIdeasClient from "../../components/ConectaIdeas/ConectaIdeasClient";
import { Logger } from "../../components/Logger";
import Logo from "../../components/LogoComponents/Logo";
import LogoBrain from "../../components/LogoComponents/LogoBrain";
import { getPais } from "../../components/functions/authFunctions";
import Layout from "../../constants/Layout";
import { loginStyles } from "../../constants/LoginStyles";
import { defaultColors } from "../../constants/defaultColors";
import useConnectionStatus from "../../hooks/useConnectionStatus";
import { InputPasswordProps } from "../../navigation/Routes";

const HEIGHT_MODAL_ERROR = {
  PERU: 500,
  OTRO: 180,
};

export default function LoginInputPasswordScreen({
  route,
  navigation,
}: InputPasswordProps) {
  const [pass, setPassword] = useState("");
  const [securePass, setSecurePass] = useState(true);
  const [isError, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const conectaideas = new ConectaIdeasClient();
  const { signIn } = React.useContext(AuthenticationContext);
  const [showHelp, setHelp] = useState(false);
  const isOnline = useConnectionStatus();
  const [{ isSimple, cargando }, setIsSimple] = useState({
    isSimple: false,
    cargando: true,
  });

  const state = {
    pais: getPais(route.params.paisId),
    user: route.params.user,
    usernameId: route.params.usernameId,
    mobile: Layout.isSmallScreen,
  };

  useEffect(() => {
    if (state.pais) {
      setIsSimple({
        isSimple: state.pais.name === "Perú" || state.pais.name === "Paraguay",
        cargando: false,
      });
    } else {
      navigation.navigate("SelectPais");
    }
  }, [state.pais]);

  function performLogin() {
    setLoading(true);

    conectaideas
      .signIn(state.usernameId, pass)
      .then(async (user) => {
        signIn(user);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        //usuario no existe o contraseña incorrecta no es un error que queramos reportar a sentry
        if (
          error.code !== "UserNotFoundException" &&
          error.code !== "NotAuthorizedException"
        ) {
          Logger.error("Error inicio sesión " + JSON.stringify(error));
          throw new Error(
            "Error inicio sesión " + error.code + ":" + error.message
          );
        }
      });
  }
  if (cargando) return <ActivityIndicator />;
  return (
    <View style={loginStyles.containerFlex}>
      {isSimple ? (
        <View style={loginStyles.logoBrain}>
          <LogoBrain color={defaultColors.fucsia} />
        </View>
      ) : (
        <View style={loginStyles.stretch}>
          <Logo />
        </View>
      )}

      <View style={loginStyles.itemContainer}>
        <Text style={loginStyles.flatListText} allowFontScaling={false}>
          {state.user}
        </Text>
      </View>
      <Text style={loginStyles.text} allowFontScaling={false}>
        Ingresa tu contraseña
      </Text>
      <View
        style={[
          loginStyles.inputContainer,
          { borderColor: isSimple ? defaultColors.yellow : defaultColors.blue },
        ]}
      >
        <TextInput
          style={loginStyles.inputPass}
          secureTextEntry={securePass}
          autoFocus
          autoCorrect={false}
          autoCapitalize="none"
          onChangeText={(text) => setPassword(text)}
          placeholder="Contraseña"
          disableFullscreenUI
          data-test="passwordInput"
          testID="passwordInput"
        />
        <Icon
          size={22}
          color="grey"
          name={securePass ? "eye" : "eye-slash"}
          onPress={() => setSecurePass(!securePass)}
        />
      </View>

      <View
        style={[
          loginStyles.loginContainerPeru,
          { justifyContent: isSimple ? "space-between" : "center" },
        ]}
      >
        <Text
          style={loginStyles.linkText}
          allowFontScaling={false}
          onPress={() => setHelp(true)}
          testID="passHelp"
        >
          No sé mi contraseña
        </Text>
        {isSimple &&
          (loading ? (
            <ActivityIndicator size="large" color={defaultColors.calipso} />
          ) : (
            <TouchableOpacity onPress={performLogin}>
              <Text style={loginStyles.flecha}>➡️</Text>
            </TouchableOpacity>
          ))}
      </View>
      {!showHelp && !isError && !isSimple && (
        <TouchableOpacity
          style={pass ? loginStyles.button : loginStyles.buttonDisabled}
          onPress={performLogin}
          disabled={!pass || loading}
          data-test="signInButton"
          testID="signInButton"
        >
          <Text style={loginStyles.textButton} allowFontScaling={false}>
            {loading ? "INGRESANDO..." : "INGRESAR  "}
          </Text>
          {loading ? null : <Icon size={22} name="sign-in" color="white" />}
        </TouchableOpacity>
      )}
      {!isOnline && (
        <Text allowFontScaling={false}>
          Actualmente no estás conectado a internet. Recuerda que para descargar
          los ejercicios necesitas estar conectado.
        </Text>
      )}

      <View style={{ height: 80 }} />
      {showHelp && (
        <ModalAuthError
          onPress={() => setHelp(false)}
          mensaje={state.pais.helpPassMessage}
          height={350}
          color={isSimple ? defaultColors.calipso : defaultColors.blue}
          button={isSimple ? "ACEPTAR" : "Aceptar"}
        />
      )}
      {isError && (
        <ModalAuthError
          onPress={() => setError(false)}
          mensaje={state.pais.errorPass}
          color={isSimple ? defaultColors.calipso : defaultColors.blue}
          button={isSimple ? "ACEPTAR" : "Aceptar"}
          height={isSimple ? HEIGHT_MODAL_ERROR.PERU : HEIGHT_MODAL_ERROR.OTRO}
        />
      )}
    </View>
  );
}
