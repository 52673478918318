import { gql, useApolloClient } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useContext, useState } from "react";
import { Text, View, TouchableOpacity, ActivityIndicator } from "react-native";

import { AuthenticationContext } from "../components/AuthenticationContext";
import { Logger } from "../components/Logger";
import { getCurrentExpoToken } from "../components/NotificacionesComponents/pushUtils";
import { stylesExitScreen as styles } from "../constants/NewStyles";
import StorageKeys from "../constants/StorageKeys";
import { deleteToken } from "../graphql/mutation";
import parameters from "../metadata.json";
import { ExitProps } from "../navigation/Routes";

const version = parameters.version;

export default function ExitScreen({ navigation }: ExitProps) {
  const { signOut } = useContext(AuthenticationContext);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  async function exit() {
    setLoading(true);

    const token = await getCurrentExpoToken();
    if (token) {
      try {
        if (token.shouldReport) {
          await client.mutate({
            mutation: gql(deleteToken),
            variables: { token: token.token, version },
          });
        }
      } catch (error) {
        Logger.error("Error al reportar eliminación de token", error);
      }
      try {
        await AsyncStorage.removeItem(StorageKeys.NOTIFICATIONS_TOKEN);
      } catch (e) {
        Logger.error("Error al limpiar token", e);
      }
    }
    await signOut();
  }

  if (loading) {
    return (
      <View style={styles.container}>
        <Text>Cerrando sesión de forma segura</Text>
        <ActivityIndicator
          size="large"
          color="rgb(32,96,234)"
          testID="cerrando"
        />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text
        style={styles.text}
        testID="warning_message"
        allowFontScaling={false}
      >
        Al desconectarse se perderán todos los avances no guardados.
      </Text>
      <View style={styles.containerButtons}>
        <TouchableOpacity
          style={styles.button}
          onPress={() => navigation.navigate("Actividades")}
          testID="back_button"
          data-test="back_button"
        >
          <Text style={styles.textButton} allowFontScaling={false}>
            Volver a ConectaIdeas
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.button}
          onPress={exit}
          testID="cerrar_button"
          data-test="cerrar_button"
        >
          <Text style={styles.textButton} allowFontScaling={false}>
            Cerrar
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
