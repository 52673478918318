import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, View } from "react-native";

import Bandera from "../Bandera";

function getFlags(score: number) {
  const flagsArray = [
    { id: 1, active: false },
    { id: 2, active: false },
    { id: 3, active: false },
  ];

  for (let i = 0; i < score; i++) {
    flagsArray[i] = { ...flagsArray[i], active: true };
  }
  return flagsArray;
}

export default function Flags({ score }: { score: number }) {
  return (
    <View style={styles.container}>
      {getFlags(score).map((bandera) => {
        return (
          <View key={bandera.id} style={styles.flags}>
            <Bandera key={bandera.id} active={bandera.active} color="black" />
          </View>
        );
      })}
    </View>
  );
}

Flags.propTypes = {
  score: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  flags: {
    height: "100%",
    width: "30%",
  },
});
