import { HeaderBackButton } from "@react-navigation/elements";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { PerfilStackParamList } from "./Routes";
import TopRightButton from "../components/TopRightButton";
import { headerNavigatorHeight } from "../constants/Layout";
import PerfilScreen from "../screens/PerfilScreen";
import { BotonesPerfil } from "../screens/PerfilScreens/BotonesPerfil";

const Stack = createStackNavigator<PerfilStackParamList>();
export default function PerfilStackNavigator() {
  return (
    <Stack.Navigator
      screenOptions={({ navigation }) => ({
        headerRight: () => {
          return <TopRightButton navigation={navigation} />;
        },
      })}
    >
      <Stack.Screen
        name="Perfil"
        component={PerfilScreen}
        options={{
          title: "Mi Perfil",
          headerLeft: () => {
            return null;
          },
        }}
      />

      {BotonesPerfil.map((boton) => {
        return (
          <Stack.Screen
            key={boton.id}
            name={boton.name}
            component={boton.component}
            options={({ navigation }) => ({
              title: boton.title,
              headerBackTitle: "Perfil",
              headerLeft: () => (
                <HeaderBackButton
                  onPress={() => {
                    navigation.push("Perfil");
                  }}
                />
              ),
              headerStyle: { height: headerNavigatorHeight },
            })}
          />
        );
      })}
    </Stack.Navigator>
  );
}
