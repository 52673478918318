import React from "react";
import { View, Text } from "react-native";

import Bandera from "../Bandera";

export default function renderFlagsInfo(
  color: string,
  title: string,
  flags: number
) {
  return (
    <View
      style={{
        flexDirection: "row",
        height: 50,
        alignItems: "center",
        marginVertical: 10,
        borderBottomColor: "grey",
        borderBottomWidth: 1,
      }}
    >
      <Text style={{ fontSize: 20 }} allowFontScaling={false}>
        {title}
      </Text>
      <Text style={{ fontSize: 20 }} allowFontScaling={false}>
        {String(flags)}
      </Text>
      <View style={{ height: 50, width: 50, marginLeft: 10 }}>
        <Bandera color={color} />
      </View>
    </View>
  );
}
