import { graphql } from "../gql/gql";

export const INITIAL_DATA_FETCH = graphql(`
  query getEverything {
    getActividades {
      tareas {
        tarea_asignada_id
        clase
        nivel
        descripcion
        total_ejercicios
        end_time_utc
        start_time_utc
        ejercicios {
          id
          tarea_asignada_id
          name
          position
          updated_at
          key
        }
      }
    }
    getUsuario {
      id
      nombre
      username
      apellido_materno
      apellido_paterno
      pais
      alumnoCurso {
        usuario_id
        curso_id
        flagsMonth {
          flags
          key
        }
        flagsWeek {
          flags
          key
        }
        flagsYear {
          flags
          key
        }
        curso {
          id
          nivel
          nombre
          message {
            text
            name
          }
        }
        preguntasMetacognitivasPendientes {
          items {
            id
            title
            question
            choices {
              alternativeId
              text
            }
            multiple
            created_at
          }
        }
      }
    }
    getMensaje {
      text
      name
      id
    }
  }
`);

export const getExerciseKeyById = graphql(`
  query getExerciseKey($exerciseId: Int!) {
    getExerciseKey(exerciseId: $exerciseId)
  }
`);
