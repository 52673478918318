import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, View, Text } from "react-native";
import "moment/locale/es";

import { FlagsInfoType } from "../../Interfaces";
import Bandera from "../Bandera";
import { getFlagsResume } from "../functions/flagsFunctions";
export default function FlagsCounter({ flags }: { flags?: FlagsInfoType }) {
  const nameMonth = moment().locale("es").format("MMMM");

  if (!flags) {
    return null;
  }
  const flagsResume = getFlagsResume(flags);

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        Banderas {nameMonth}
        {": "}{" "}
      </Text>
      <Text style={styles.text}> {flagsResume.month} </Text>

      <View style={styles.flags}>
        <Bandera color="green" />
      </View>
    </View>
  );
}

FlagsCounter.propTypes = {
  data: PropTypes.object,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    height: 45,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 3,
    right: 1,
    padding: 2,
    justifyContent: "flex-end",
    marginHorizontal: 1,
  },
  subContainer: {
    alignItems: "center",
    height: 45,
    justifyContent: "center",
  },
  flags: {
    height: "100%",
    width: 35,
    paddingLeft: 2,
  },
  text: {
    fontWeight: "bold",
    fontStyle: "italic",
  },
  resumeFlags: {
    height: 199,
    width: 250,
    top: 110,
    right: 1,
    position: "absolute",
    backgroundColor: "white",
    alignItems: "center",
    borderRadius: 3,
  },
});
