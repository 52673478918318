import _ from "lodash";

import { ColorType } from "./Recuadro/RectInterfaces";

/**
 * conversion from xml to json can sometimes give us a property
 * as a single element and sometimes as an array.
 * this function will search for ´prop´ inside container, if
 * the element is an array then return the array, if is just an element
 * then return a new array with just one element on it.
 * @param container
 * @param prop
 * @returns {*[]|*}
 */

const TYPE_POSITION = 2;
const FACTOR_COLOR = 255;

export const getPropertyAsArray = (container, prop) => {
  if (_.isUndefined(container[prop])) {
    return [];
  }
  const value = container[prop];
  if (Array.isArray(value)) {
    return value;
  } else {
    return [value];
  }
};

/**
 * Returns the boundaries of an exercise.
 * Boundaries are computed based on the size and location of rectangles and images
 * @param imagenes
 * @param recuadros
 */
export const getExerciseDimensions = (imagenes, recuadros) => {
  //min size
  let width = 100;
  let height = 100;

  _.each(imagenes, (imagen) => {
    width = Math.max(width, imagen.x + imagen.w);
    height = Math.max(height, imagen.y + imagen.h);
  });

  _.each(recuadros, (recuadro) => {
    width = Math.max(
      width,
      parseInt(recuadro.x.$, 10) + parseInt(recuadro.w.$, 10)
    );
    height = Math.max(
      height,
      parseInt(recuadro.y.$, 10) + parseInt(recuadro.h.$, 10)
    );
  });

  return { exerciseWidth: width, exerciseHeight: height };
};

function base64Icon(nombreImage, allImages, format = undefined) {
  let type;
  if (format) {
    type = format;
  } else {
    type = _.split(nombreImage, ".", TYPE_POSITION)[1];
  }
  const image = _.filter(allImages, ["@nombre", nombreImage]);
  return `data:image/${type};base64,${image[0].$}`;
}

export const parseImages = (cx8) => {
  const imagenes = getPropertyAsArray(cx8.ejercicio.imagenes, "imagen");
  const allImg64 = getPropertyAsArray(cx8.imagenes64, "imagen");
  const imgs = [];
  for (let i = 0; i < imagenes.length; i++) {
    imgs[i] = {
      id: "img" + imagenes[i]["@id"],
      x: parseInt(imagenes[i].x.$, 10),
      y: parseInt(imagenes[i].y.$, 10),
      w: parseInt(imagenes[i].w.$, 10),
      h: parseInt(imagenes[i].h.$, 10),
      img64: base64Icon(
        imagenes[i].nom_img.$,
        allImg64,
        imagenes[i].nom_img.format
      ),
    };
  }
  return imgs;
};

export function getColor(color: ColorType) {
  return `rgba(${parseInt(color.r.$, 10)},${parseInt(color.g.$, 10)},${parseInt(
    color.b.$,
    10
  )},${parseInt(color.a.$, 10) / FACTOR_COLOR})`;
}
