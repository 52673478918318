import { createStackNavigator } from "@react-navigation/stack";
import React, { Dispatch, SetStateAction } from "react";

import PermissionsScreen from "../screens/PermissionsScreen";

const Permissions = createStackNavigator();

interface PermissionsType {
  setRequestPermissions: Dispatch<SetStateAction<boolean>>;
}

export default function PermissionsNavigator({
  setRequestPermissions,
}: PermissionsType) {
  return (
    <Permissions.Navigator>
      <Permissions.Screen
        name="Permissions"
        options={{ title: "Bienvenido a ConectaIdeas" }}
      >
        {() => (
          <PermissionsScreen setRequestPermissions={setRequestPermissions} />
        )}
      </Permissions.Screen>
    </Permissions.Navigator>
  );
}
