import { useQuery } from "@apollo/client";
import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
} from "@apollo/client/core";
import {
  QueryHookOptions,
  QueryResult,
} from "@apollo/client/react/types/types";
import { useEffect, useMemo } from "react";

export default function useQueryWithOfflineSupport<
  TData = any,
  TVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const newQueryOptions = { ...options };
  newQueryOptions.fetchPolicy = "cache-first";

  const baseQueryResult = useQuery<TData, TVariables>(query, newQueryOptions);

  useEffect(() => {
    const handler = setTimeout(() => {
      return baseQueryResult.refetch();
    }, 5000);

    return () => {
      clearTimeout(handler);
    };
  }, []);

  return useMemo(() => {
    const proxy = { ...baseQueryResult };

    if (proxy.error) {
      console.error("base query returned an error", proxy.error);
      delete proxy.error;
      proxy.data = baseQueryResult.client.readQuery({ query });
    }

    return proxy;
  }, [baseQueryResult.loading, baseQueryResult.error, baseQueryResult.data]);
}
