import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { LoginStackParamList } from "./Routes";
import LoginInputPasswordScreen from "../screens/LoginScreens/LoginInputPasswordScreen";
import LoginInputUsernameScreen from "../screens/LoginScreens/LoginInputUsernameScreen";
import LoginSelectInstitutionScreen from "../screens/LoginScreens/LoginSelectInstitutionScreen";
import LoginSelectPaisScreen from "../screens/LoginScreens/LoginSelectPaisScreen";

const Auth = createStackNavigator<LoginStackParamList>();

export default function LoginStackNavigator() {
  return (
    <Auth.Navigator>
      <Auth.Screen
        name="SelectPais"
        component={LoginSelectPaisScreen}
        options={{ title: "Bienvenido a ConectaIdeas" }}
      />
      <Auth.Screen
        name="InputUsername"
        component={LoginInputUsernameScreen}
        options={{ title: "Bienvenido a ConectaIdeas" }}
      />
      <Auth.Screen
        name="SelectInstitution"
        component={LoginSelectInstitutionScreen}
        options={{ title: "Bienvenido a ConectaIdeas" }}
      />
      <Auth.Screen
        name="InputPassword"
        component={LoginInputPasswordScreen}
        options={{ title: "Bienvenido a ConectaIdeas" }}
      />
    </Auth.Navigator>
  );
}
