/* eslint-disable */
import * as types from "./graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  mutation saveToken($token: String, $platform: String, $version: String!) {\n    saveToken(token: $token, platform: $platform, version: $version)\n  }\n":
    types.SaveTokenDocument,
  "\n  query getEverything {\n    getActividades {\n      tareas {\n        tarea_asignada_id\n        clase\n        nivel\n        descripcion\n        total_ejercicios\n        end_time_utc\n        start_time_utc\n        ejercicios {\n          id\n          tarea_asignada_id\n          name\n          position\n          updated_at\n          key\n        }\n      }\n    }\n    getUsuario {\n      id\n      nombre\n      username\n      apellido_materno\n      apellido_paterno\n      pais\n      alumnoCurso {\n        usuario_id\n        curso_id\n        flagsMonth {\n          flags\n          key\n        }\n        flagsWeek {\n          flags\n          key\n        }\n        flagsYear {\n          flags\n          key\n        }\n        curso {\n          id\n          nivel\n          nombre\n          message {\n            text\n            name\n          }\n        }\n        preguntasMetacognitivasPendientes {\n          items {\n            id\n            title\n            question\n            choices {\n              alternativeId\n              text\n            }\n            multiple\n            created_at\n          }\n        }\n      }\n    }\n    getMensaje {\n      text\n      name\n      id\n    }\n  }\n":
    types.GetEverythingDocument,
  "\n  query getExerciseKey($exerciseId: Int!) {\n    getExerciseKey(exerciseId: $exerciseId)\n  }\n":
    types.GetExerciseKeyDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation saveToken($token: String, $platform: String, $version: String!) {\n    saveToken(token: $token, platform: $platform, version: $version)\n  }\n"
): typeof documents["\n  mutation saveToken($token: String, $platform: String, $version: String!) {\n    saveToken(token: $token, platform: $platform, version: $version)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getEverything {\n    getActividades {\n      tareas {\n        tarea_asignada_id\n        clase\n        nivel\n        descripcion\n        total_ejercicios\n        end_time_utc\n        start_time_utc\n        ejercicios {\n          id\n          tarea_asignada_id\n          name\n          position\n          updated_at\n          key\n        }\n      }\n    }\n    getUsuario {\n      id\n      nombre\n      username\n      apellido_materno\n      apellido_paterno\n      pais\n      alumnoCurso {\n        usuario_id\n        curso_id\n        flagsMonth {\n          flags\n          key\n        }\n        flagsWeek {\n          flags\n          key\n        }\n        flagsYear {\n          flags\n          key\n        }\n        curso {\n          id\n          nivel\n          nombre\n          message {\n            text\n            name\n          }\n        }\n        preguntasMetacognitivasPendientes {\n          items {\n            id\n            title\n            question\n            choices {\n              alternativeId\n              text\n            }\n            multiple\n            created_at\n          }\n        }\n      }\n    }\n    getMensaje {\n      text\n      name\n      id\n    }\n  }\n"
): typeof documents["\n  query getEverything {\n    getActividades {\n      tareas {\n        tarea_asignada_id\n        clase\n        nivel\n        descripcion\n        total_ejercicios\n        end_time_utc\n        start_time_utc\n        ejercicios {\n          id\n          tarea_asignada_id\n          name\n          position\n          updated_at\n          key\n        }\n      }\n    }\n    getUsuario {\n      id\n      nombre\n      username\n      apellido_materno\n      apellido_paterno\n      pais\n      alumnoCurso {\n        usuario_id\n        curso_id\n        flagsMonth {\n          flags\n          key\n        }\n        flagsWeek {\n          flags\n          key\n        }\n        flagsYear {\n          flags\n          key\n        }\n        curso {\n          id\n          nivel\n          nombre\n          message {\n            text\n            name\n          }\n        }\n        preguntasMetacognitivasPendientes {\n          items {\n            id\n            title\n            question\n            choices {\n              alternativeId\n              text\n            }\n            multiple\n            created_at\n          }\n        }\n      }\n    }\n    getMensaje {\n      text\n      name\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getExerciseKey($exerciseId: Int!) {\n    getExerciseKey(exerciseId: $exerciseId)\n  }\n"
): typeof documents["\n  query getExerciseKey($exerciseId: Int!) {\n    getExerciseKey(exerciseId: $exerciseId)\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
