import { Auth, CognitoUser } from "@aws-amplify/auth";
import React, { useState } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

import ModalAuthError from "../../components/AuthComponents/ModalAuthError";
import { Logger } from "../../components/Logger";
import { loginStyles } from "../../constants/LoginStyles";

interface ChangePassType {
  user: CognitoUser;
}

export default function ChangePassVerified({ user }: ChangePassType) {
  const [oldPass, setOldPass] = useState("");
  const [oldSecurePass, setOldSecurePass] = useState(true);

  const [newPass, setNewPass] = useState("");
  const [newSecurePass, setNewSecurePass] = useState(true);

  const [newPassConfirm, setNewPassConfirm] = useState("");
  const [newSecurePassConfirm, setNewSecurePassConfirm] = useState(true);

  const [isError, setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const changePass = async () => {
    setError(false);
    Auth.changePassword(user, oldPass, newPass)
      .then(() => setOpenModal(true))
      .catch((error) => {
        Logger.info("ERROR:", error);
        setError(true);
        setOpenModal(true);
      });
  };

  return (
    <ScrollView style={{ backgroundColor: "white" }}>
      <View style={loginStyles.container}>
        <Text style={loginStyles.text} allowFontScaling={false}>
          Ingresa tu Contraseña Actual
        </Text>
        <View style={loginStyles.inputContainer}>
          <TextInput
            style={loginStyles.inputPass}
            secureTextEntry={oldSecurePass}
            autoCorrect={false}
            onChangeText={(text) => setOldPass(text)}
            placeholder="Contraseña"
            disableFullscreenUI
            testID="passwordInput"
          />
          <Icon
            size={22}
            color="grey"
            name={oldSecurePass ? "eye" : "eye-slash"}
            onPress={() => setOldSecurePass(!oldSecurePass)}
          />
        </View>
        <View style={{ height: 20 }} />
        <Text style={loginStyles.text} allowFontScaling={false}>
          Ingresa tu nueva Contraseña
        </Text>
        <View style={loginStyles.inputContainer}>
          <TextInput
            style={loginStyles.inputPass}
            secureTextEntry={newSecurePass}
            autoCorrect={false}
            onChangeText={(text) => setNewPass(text)}
            placeholder="Contraseña"
            disableFullscreenUI
            testID="passwordInput"
          />
          <Icon
            size={22}
            color="grey"
            name={newSecurePass ? "eye" : "eye-slash"}
            onPress={() => setNewSecurePass(!newSecurePass)}
          />
        </View>
        <View style={{ height: 20 }} />
        <Text style={loginStyles.text} allowFontScaling={false}>
          Confirma tu nueva Contraseña
        </Text>
        <View style={loginStyles.inputContainer}>
          <TextInput
            style={loginStyles.inputPass}
            secureTextEntry={newSecurePassConfirm}
            autoCorrect={false}
            onChangeText={(text) => setNewPassConfirm(text)}
            placeholder="Contraseña"
            disableFullscreenUI
            testID="passwordInput"
          />
          <Icon
            size={22}
            color="grey"
            name={newSecurePassConfirm ? "eye" : "eye-slash"}
            onPress={() => setNewSecurePassConfirm(!newSecurePassConfirm)}
          />
        </View>
        <View style={{ height: 20 }} />
        <TouchableOpacity
          style={
            oldPass === "" ||
            newPass === "" ||
            newPassConfirm === "" ||
            newPass !== newPassConfirm
              ? loginStyles.buttonDisabled
              : loginStyles.button
          }
          testID="changeButton"
          onPress={changePass}
          disabled={
            oldPass === "" ||
            newPass === "" ||
            newPassConfirm === "" ||
            newPass !== newPassConfirm
          }
        >
          <Text style={loginStyles.textButton} allowFontScaling={false}>
            Guardar
          </Text>
        </TouchableOpacity>

        <View style={{ height: 80 }} />
        {openModal && (
          <ModalAuthError
            onPress={() => setOpenModal(false)}
            mensaje={
              isError
                ? "No se pudo cambiar tu contraseña"
                : "Contraseña cambiada con éxito."
            }
          />
        )}
      </View>
    </ScrollView>
  );
}
