import { StyleProp } from "react-native";

import {
  ExerciseUserDataType,
  OnlyConnectionType,
  WindowType,
} from "./ExerciseInterfaces";

export enum ExerciseActions {
  HIDE_INPUT_MODAL = "HIDE_INPUT_MODAL",
  //connection events
  CONNECTION_MOVING = "CONNECTION_MOVING",
  CONNECTION_RELEASE = "CONNECTION_RELEASE",
  TOUCH_EVENT = "TOUCH_EVENT",
  //input events
  OPEN_INPUT_MODAL = "OPEN_INPUT_MODAL",
  CLOSE_INPUT_MODAL = "CLOSE_INPUT_MODAL",
  CHANGE_TEXT = "CHANGE_TEXT",
  TEXT_ANSWER = "TEXT_ANSWER",
  //system events
  READY = "READY",
  RESET_PENDING_SAVE_FLAG = "RESET_PENDING_SAVE_FLAG",
  SAVE_TIME = "SAVE_TIME",
  SKIP_EXERCISE = "SKIP_EXERCISE",
}

export enum TypeEvents {
  TOUCH = "touch",
  DRAG = "drag",
  TEXT = "text",
}

type ResetPendingSaveFlagAction = {
  type: ExerciseActions.RESET_PENDING_SAVE_FLAG;
};
type HideInputModalAction = {
  type: ExerciseActions.HIDE_INPUT_MODAL;
};
export type OpenInputModalAction = {
  type: ExerciseActions.OPEN_INPUT_MODAL;
  payload: {
    styles: StyleProp<any>;
    questionText: string;
    answerIsNumber: boolean;
    idModal: string;
    correctAnswer: string;
    typeReviewAnswer: "phraseReview" | "letterReview";
    changeText: (text: string) => void;
  };
};
type ReadyAction = {
  type: ExerciseActions.READY;
  payload: {
    scalingFactor: number;
    window: WindowType;
    svgViewBox: string;
  };
  exercise: {
    id: number;
    tarea_asignada_id: number;
    position: number;
    user: ExerciseUserDataType;
  };
  onlyConnection: OnlyConnectionType;
};

export type ConnectionMovingAction = {
  type: ExerciseActions.CONNECTION_MOVING;
  inputEvent: {
    timestamp: number;
    typeEvent: TypeEvents;
    recuadroId: string;
    connectionId: string;
    segmentoId: string;
    remainingSegmentos: object[];
    x0: number;
    y0: number;
    dx: number;
    dy: number;
    startX: number;
    startY: number;
  };
};

export type ConnectionReleaseAction = {
  type: ExerciseActions.CONNECTION_RELEASE;
  inputEvent: {
    timestamp: number;
    typeEvent: TypeEvents;
    recuadroId: string;
    connectionId: string;
    segmentoId: string;
    remainingSegmentos: object[];
    x0: number;
    y0: number;
  };
};

export type TouchEventAction = {
  type: ExerciseActions.TOUCH_EVENT;
  inputEvent: {
    timestamp: number;
    typeEvent: TypeEvents;
    recuadroId: string;
    connectionId: string;
    segmentoId: string;
    remainingSegmentos: object[];
    x0: number;
    y0: number;
  };
};
export type TextAnswerAction = {
  type: ExerciseActions.TEXT_ANSWER;
  inputEvent: { timestamp: number; typeEvent: TypeEvents.TEXT };
  answer: string;
};
type SkipExerciseAction = {
  type: ExerciseActions.SKIP_EXERCISE;
};

export type ExerciseActionsType =
  | ResetPendingSaveFlagAction
  | HideInputModalAction
  | OpenInputModalAction
  | ReadyAction
  | ConnectionMovingAction
  | ConnectionReleaseAction
  | TouchEventAction
  | TextAnswerAction
  | SkipExerciseAction;
