import ConnectionValidator from "./ConnectionValidator";
import { Cx8File } from "../../Interfaces";
import { ScreenInfoType } from "../../hooks/useScreenInfo";
import {
  getExerciseDimensions,
  getPropertyAsArray,
  parseImages,
  getColor,
} from "../ParseUtils";

const AJUSTE = {
  BAR_HEIGHT_EX_VERTICAL: 50, // barra amarilla horizontal top
  SCREEN_WIDTH_OTHER: 0.94,
  SCREEN_HEIGHT: 150, // no se para que es esto, se resta a la pantalla en vertical
  BAR_WIDTH_EX_HORIZONTAL: 76, // barra amarilla vertical left 75 + 1 margin
  HALF: 2,
};

export function processCx8Exercise(
  result: Cx8File,
  screenInfo: ScreenInfoType
) {
  const cx8 = result["cnx_magicas"];
  const colorFondo = getColor(cx8.ejercicio.clr_fondo);
  const imagenes = parseImages(cx8);
  const recuadros = getPropertyAsArray(cx8.ejercicio.recuadros, "recuadro");
  const zonas = getPropertyAsArray(cx8.ejercicio.zonas, "zona");
  const validator = new ConnectionValidator(recuadros, zonas);
  const { exerciseWidth, exerciseHeight } = getExerciseDimensions(
    imagenes,
    recuadros
  );
  const isHorizontal = screenInfo.windowWidth > screenInfo.windowHeight;

  // Disponible para el ejercicio
  const screenSize = {
    w: isHorizontal
      ? screenInfo.windowWidth - AJUSTE.BAR_WIDTH_EX_HORIZONTAL
      : screenInfo.windowWidth * AJUSTE.SCREEN_WIDTH_OTHER,
    h: isHorizontal
      ? screenInfo.windowHeight
      : screenInfo.windowHeight - AJUSTE.SCREEN_HEIGHT,
  };

  const svgViewBox = `0 0 ${exerciseWidth} ${exerciseHeight}`;
  const factorX = screenSize.w / exerciseWidth;
  const factorY = screenSize.h / exerciseHeight;
  let scalingFactor = factorX;
  if (exerciseHeight * scalingFactor > screenSize.h) {
    scalingFactor = factorY;
  }

  if (isNaN(scalingFactor)) {
    throw new Error("Invalid scaling factor");
  }
  const window = {
    w: Math.trunc(exerciseWidth * scalingFactor),
    h: Math.trunc(exerciseHeight * scalingFactor),
  };

  const origenPosition = {
    x: isHorizontal
      ? AJUSTE.BAR_WIDTH_EX_HORIZONTAL +
        (screenInfo.windowWidth -
          exerciseWidth * scalingFactor -
          AJUSTE.BAR_WIDTH_EX_HORIZONTAL) /
          AJUSTE.HALF
      : (screenInfo.windowWidth - exerciseWidth * scalingFactor) / AJUSTE.HALF,
    y: isHorizontal ? 0 : AJUSTE.BAR_HEIGHT_EX_VERTICAL,
    ajusteTouchY: screenInfo.insets.top,
    ajusteTouchX: screenInfo.insets.left,
  };
  return {
    cx8,
    imagenes,
    recuadros,
    exerciseWidth,
    exerciseHeight,
    svgViewBox,
    scalingFactor,
    window,
    validator,
    colorFondo,
    origenPosition,
  };
}
