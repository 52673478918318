import React from "react";
import { View } from "react-native";

import ExerciseTest from "../test/ExerciseTest";

export default function TestScreen() {
  return (
    <View
      style={{
        flexDirection: "column",
        paddingHorizontal: 50,
        paddingVertical: 30,
      }}
    >
      <ExerciseTest />
    </View>
  );
}
