import React from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Linking,
  StyleSheet,
} from "react-native";

import Layout from "../constants/Layout";
import { modalBlueStyles as styles } from "../constants/ModalStyles";

export const VersionActions = {
  WARN: "advertir",
  BLOCK: "bloquear",
  OBSOLETO: "obsoleto",
};

const FONT_SIZE = {
  SMALL_SCREEN: 25,
  OTHER: 30,
  SMALL_LINK: 15,
  LINK: 20,
};

const color = "rgb(32,96,234)";

export default function ModalVersion({
  show,
  action,
  close,
}: {
  show: boolean;
  action?: string;
  close: () => void;
}) {
  let mensaje;
  if (action === VersionActions.BLOCK) {
    mensaje =
      "Tienes una versión antigua de ConectaIdeas, esta versión dejó de funcionar, para continuar, obtén la actualización en el siguiente link.";
  } else if (action === VersionActions.WARN) {
    mensaje =
      "Estás usando una versión antigua de ConectaIdeas, tu versión pronto dejará de funcionar, obtén la actualización en el siguiente link.";
  } else {
    mensaje =
      "El navegador que estás usando no es soportado, debes instalar ConectaIdeas Offline desde el siguiente link.";
  }

  const fontSize = Layout.isSmallScreen
    ? FONT_SIZE.SMALL_SCREEN
    : FONT_SIZE.OTHER;

  if (!show || !action) return <View />;

  return (
    <View style={styles.modalContainer}>
      <View style={[styles.modalContent, localStyles.content]}>
        <View style={[styles.containerTitle, { marginHorizontal: "5%" }]}>
          <Text
            style={[styles.modalTitle, { fontSize }]}
            allowFontScaling={false}
          >
            {mensaje}
          </Text>
          <Text
            style={localStyles.linkText}
            allowFontScaling={false}
            onPress={() =>
              Linking.openURL("https://www.conectaideas.com/offline")
            }
          >
            https://www.conectaideas.com/offline
          </Text>

          <ButtonContinue action={action} close={close} />
        </View>
      </View>
    </View>
  );
}

const ButtonContinue = ({
  action,
  close,
}: {
  action: string;
  close: () => void;
}) => {
  if (
    !action ||
    action === VersionActions.BLOCK ||
    action === VersionActions.OBSOLETO
  )
    return <View />;

  return (
    <View style={localStyles.buttonContainer}>
      <TouchableOpacity style={localStyles.button} onPress={close}>
        <Text style={styles.textButton} allowFontScaling={false}>
          Continuar con mi versión
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const localStyles = StyleSheet.create({
  buttonContainer: {
    width: "100%",
    marginVertical: 20,
  },
  button: {
    backgroundColor: color,
    shadowColor: color,
    width: 230,
    height: 25,
    borderRadius: 5,
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.2,
    alignItems: "center",
    justifyContent: "center",
    opacity: 1,
    elevation: 5,
  },
  content: {
    borderColor: color,
    shadowColor: color,
    height: 400,
    justifyContent: "center",
  },
  linkText: {
    fontSize: Layout.isSmallScreen ? FONT_SIZE.SMALL_LINK : FONT_SIZE.LINK,
    marginVertical: 20,
    textDecorationLine: "underline",
    color,
  },
});
