import React from "react";
import { Badge } from "react-native-elements";

export default function Notificaciones({ numero }: { numero: number }) {
  return numero > 0 ? (
    <Badge
      value={numero}
      status="error"
      containerStyle={{ position: "absolute", top: -10, right: -16, width: 40 }}
    />
  ) : null;
}
