import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React from "react";
import { View, Text } from "react-native";
import { ListItem } from "react-native-elements";

import { TareaWithStatus } from "../../Interfaces";
import { listStyles } from "../../constants/NewStyles";
import { ActividadesStackParamList } from "../../navigation/Routes";
import { getNextExercise } from "../ExerciseComponents/functionsExercise";

function goToExercise(tareasIndex, tarea, navigation) {
  const nextExercise = getNextExercise(tareasIndex, {
    tareaAsignadaId: tarea.tarea_asignada_id,
  });
  navigation.replace(nextExercise.screen, nextExercise.params);
}

interface BodyTareasListInputType {
  tareasIndex: TareaWithStatus[];
  navigation: NativeStackNavigationProp<
    ActividadesStackParamList,
    "Tareas",
    undefined
  >;
}

export default function BodyTareasList({
  tareasIndex,
  navigation,
}: BodyTareasListInputType) {
  if (tareasIndex.length === 0) {
    return <Text testID="cargando">Cargando</Text>;
  }
  return (
    <View
      data-test="tareasList"
      testID="tareasList"
      style={listStyles.containerButtons}
    >
      {tareasIndex.map((tarea) => {
        return (
          <ListItem
            key={tarea.tarea_asignada_id}
            onPress={() => {
              goToExercise(tareasIndex, tarea, navigation);
            }}
          >
            <ListItem.Content style={listStyles.buttonList}>
              <ListItem.Title>
                {" "}
                {`(${tarea.tarea_asignada_id})`}
                {"    "}
                {tarea.descripcion}
              </ListItem.Title>
            </ListItem.Content>
          </ListItem>
        );
      })}
    </View>
  );
}
