import React, { Dispatch, SetStateAction } from "react";
import { Text, View, TouchableOpacity, StyleSheet } from "react-native";

import { requestPermissionsAsync } from "../components/NotificacionesComponents/pushUtils";

interface PermissionsInputType {
  setRequestPermissions: Dispatch<SetStateAction<boolean>>;
}
export default function PermissionsScreen({
  setRequestPermissions,
}: PermissionsInputType) {
  const continueNextScreen = async () => {
    await requestPermissionsAsync();
    setRequestPermissions(false);
  };

  return (
    <View style={styles.container}>
      <Text
        style={styles.text}
        testID="warning_message"
        allowFontScaling={false}
      >
        ConectaIdeas puede avisarte cuando hayan nuevos ejercicios y preguntas
        disponibles, para eso acepta los permisos que se solicitarán a
        continuación.
      </Text>

      <View style={styles.containerButtons}>
        <TouchableOpacity
          style={styles.button}
          onPress={continueNextScreen}
          testID="next_button"
        >
          <Text style={styles.textButton} allowFontScaling>
            Continuar{" "}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 20,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  text: {
    paddingVertical: 3,
    marginTop: 10,
    marginBottom: 3,
    fontSize: 20,
    textAlign: "center",
  },
  containerButtons: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    bottom: 1,
    marginTop: 20,
    width: 300,
  },
  button: {
    backgroundColor: "rgb(32,96,234)",
    flexDirection: "row",
    height: 30,
    borderRadius: 5,
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.2,
    shadowColor: "rgb(32,96,234)",
    alignItems: "center",
    justifyContent: "center",
    opacity: 1,
    elevation: 5,
    marginBottom: 10,
    marginTop: 20,
    width: "100%",
  },
  textButton: {
    fontSize: 20,
    color: "white",
  },
});
