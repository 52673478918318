import _ from "lodash";

import { HTTP_SUCCESS } from "../../constants/numbers";
import parameters from "../../metadata.json";
import paises from "../../paises/paises.json";
import { Logger } from "../Logger";

const version = parameters.version;

const ENDPOINT =
  "https://j4vltickb2.execute-api.us-east-1.amazonaws.com/institutions";

type PlatformType = "ios" | "web" | "android" | "windows" | "macos";

interface State {
  loading: boolean;
  isAuthenticated: boolean;
}

type ActionType = { type: "SIGN_IN" | "SIGN_UP" };

export function loginReducer(_state: State, action: ActionType): State {
  switch (action.type) {
    case "SIGN_IN":
      return { loading: false, isAuthenticated: true };
    case "SIGN_UP":
      return { loading: false, isAuthenticated: false };
    default:
      throw new Error("Unknown action " + JSON.stringify(action));
  }
}

export const getPais = (id: string) => {
  const paisId = id === "Per%C3%BA" ? "peru" : _.toLower(id);
  const pais = _.find(paises, { id: paisId });
  if (pais) {
    return pais;
  } else {
    Logger.info(`País no encontrado: ${id}`);
    return null;
  }
};

export const getInstitutionsList = async (pais: string, username: string) => {
  const remotePath = `${ENDPOINT}/${pais}/${username}?version=${version}`;

  const data = await fetch(remotePath).then((response) => {
    if (response.status === HTTP_SUCCESS) {
      return response.json();
    } else {
      throw new Error("Failed to getInstitutionsList " + remotePath);
    }
  });
  return data;
};

export const getPlatform = (platform: PlatformType) => {
  switch (platform) {
    case "ios":
      return "iOS";
    case "web":
      return "Web";
    case "android":
      return "Android";
    default:
      return "Web";
  }
};
