import React, { Dispatch, SetStateAction } from "react";
import { Text, View, TouchableOpacity } from "react-native";

import Flags from "./Flags";
import { modalGreenStyles as styles } from "../../constants/ModalStyles";

interface ModalCompletedType {
  numberOfFlags: number;
  setForceHideBanderasModal: Dispatch<SetStateAction<boolean>>;
  onGoToNextExercise: () => void;
}

export default function ModalExerciseCompletedFlag({
  numberOfFlags,
  setForceHideBanderasModal,
  onGoToNextExercise,
}: ModalCompletedType) {
  return (
    <View style={styles.modalContainer}>
      <View style={styles.modalContent}>
        <View style={styles.containerTitle}>
          <Text style={styles.modalTitle} allowFontScaling={false}>
            ¡Ejercicio finalizado!
          </Text>
        </View>
        <View style={styles.containerFlags}>
          <Flags score={numberOfFlags} />
        </View>

        <View style={styles.containerButtons}>
          <TouchableOpacity
            style={styles.buttonBack}
            onPress={() => {
              setForceHideBanderasModal(true);
            }}
          >
            <Text allowFontScaling={false}>Volver </Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={onGoToNextExercise}>
            <Text style={styles.textButton} allowFontScaling={false}>
              Siguiente ejercicio
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
