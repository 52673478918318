import React, { useState, useRef, useEffect } from "react";
import {
  Keyboard,
  StyleSheet,
  Text,
  TextInput,
  View,
  Platform,
  KeyboardAvoidingView,
  TouchableOpacity,
} from "react-native";

import Layout from "../../constants/Layout";
import { defaultColors } from "../../constants/defaultColors";

const MARGIN_TOP = {
  WEB: 50,
  OTHER: 35,
};

const HEIGHT_TEXT_INPUT = 30;

interface InputRecFocus {
  recId: string;
  styles: any; //type any por conflicto con StyleProps<TextStyle>
  questionText: string;
  answerIsNumber: boolean;
  changeText: (text: string) => void;
  onCheckAnswer: (text: string) => void;
  onRemoveFocus: (recId: string, answer: string) => void;
  autoFocus: boolean;
}

export default function RectOnFocus({
  recId,
  styles,
  questionText,
  answerIsNumber,
  changeText,
  onCheckAnswer,
  onRemoveFocus,
  autoFocus,
}: InputRecFocus) {
  const ref = useRef<TextInput | null>(null);
  const background_color = defaultColors.modal;

  const isMobileOrApp = Layout.isSmallScreen || Platform.OS !== "web";

  const stylesRect = StyleSheet.create({
    box: {
      width: isMobileOrApp ? "80%" : questionText ? "60%" : "30%",
      backgroundColor: background_color,
      borderRadius: 3,
      paddingBottom: 30,
    },
    text: {
      color: styles.color,
      fontSize: styles.fontSize,
      fontWeight: styles.fontWeight,
      fontStyle: styles.fontStyle,
      marginTop: isMobileOrApp ? MARGIN_TOP.OTHER : MARGIN_TOP.WEB,
      marginBottom: isMobileOrApp ? 0 : HEIGHT_TEXT_INPUT,
      marginLeft: 10,
    },
    boxButton: {
      top: 2,
      right: 2,
      width: 100,
      height: 30,
      backgroundColor: "grey",
      borderColor: "black",
      borderWidth: 1,
      borderRadius: 3,
      position: "absolute",
    },
    buttonText: {
      fontSize: 22,
      textAlign: "center",
      color: "white",
    },
    stylesMobile: {
      flex: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: isMobileOrApp ? "center" : "flex-end",
      justifyContent: "center",
      backgroundColor: "rgba(128,128,128,0.7)",
      paddingRight: isMobileOrApp ? 0 : "10%",
    },
  });
  //siempre dibuja el cuadro sin la respuesta anterior para evitar que el niño tenga que borrar la respuesta previa
  const [answer, changeAnswer] = useState("");

  useEffect(() => {
    autoFocus && ref.current && ref.current.focus();
  });

  return (
    <KeyboardAvoidingView
      style={stylesRect.stylesMobile}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <View style={stylesRect.box}>
        <Text style={stylesRect.text} allowFontScaling={false}>
          {questionText || ""}
        </Text>

        <TextInput
          ref={ref}
          style={{
            marginHorizontal: 10,
            bottom: 2,
            backgroundColor: "white",
            borderRadius: 3,
            height: HEIGHT_TEXT_INPUT,
            paddingLeft: 3,
          }}
          onChangeText={(text) => {
            changeText(text);
            changeAnswer(text);
            onCheckAnswer(text);
          }}
          onBlur={() => {
            Keyboard.dismiss();
          }}
          disableFullscreenUI
          keyboardType={
            Platform.OS !== "web" && answerIsNumber ? "decimal-pad" : "default"
          }
          value={answer}
          testID="input_text"
        />
        <TouchableOpacity
          style={stylesRect.boxButton}
          onPress={() => {
            onRemoveFocus(recId, answer);
            Keyboard.dismiss();
          }}
          testID="close_button"
        >
          <Text allowFontScaling={false} style={stylesRect.buttonText}>
            CERRAR
          </Text>
        </TouchableOpacity>
      </View>
    </KeyboardAvoidingView>
  );
}
