import _ from "lodash";

import { Logger } from "../components/Logger";

export const isOldChromeVersion = (userAgent: string) => {
  Logger.info("User agent:", userAgent);
  if (_.includes(userAgent, "Chrome/")) {
    const subAgente = _.split(userAgent, "Chrome/")[1];
    const versionChrome = _.parseInt(_.split(subAgente, /\.|s/)[0]);
    return versionChrome < 60;
  } else {
    return false;
  }
};

export const isMacintosh = (userAgent: string) => {
  return _.includes(userAgent, "Macintosh");
};
