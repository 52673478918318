import React from "react";
import { View, Text } from "react-native";

import { getStyles } from "./getStyles";

interface ErrorCounterType {
  errorNumber: number;
}

export default function ErrorCounter({ errorNumber }: ErrorCounterType) {
  const styles = getStyles("red");

  return (
    <View style={styles.headerBox}>
      <View>
        <Text selectable={false} allowFontScaling={false}>
          Errores
        </Text>
      </View>
      <View style={styles.numberView}>
        <Text style={styles.number} selectable={false} allowFontScaling={false}>
          {String(errorNumber)}
        </Text>
      </View>
    </View>
  );
}
