import _ from "lodash";

import { AnswerStatus } from "../ExerciseComponents/AnswerFeedback/Draw";
import {
  CoordenadaType,
  OnlyConnectionType,
} from "../ExerciseComponents/ExerciseInterfaces";
import { getPropertyAsArray } from "../ParseUtils";
import { ConnectionType } from "../Recuadro/RectInterfaces";

/**
 * Retorna la cantidad de conexiones que aún pueden ser tomadas desde un recuadro
 * @param recuadro
 * @param connectionAnswer
 */

const RADIO_CONEXION = 7;
const NO_INDEX = -1;

export const NO_ACTIVE_CONNECTION = "NO_ACTIVE_CONNECTION";

export function getConnectionsCounter(
  rectId: string,
  rectConnections: any,
  userConnections: ConnectionType
) {
  if (rectConnections && rectConnections.conexion) {
    const totalSegmentos = _.sumBy(
      getPropertyAsArray(rectConnections, "conexion"),
      function (num) {
        return parseInt(num.num_seg.$, 10);
      }
    );

    const answers = _.filter(userConnections, {
      status: AnswerStatus.RIGHT,
      locked: true,
      recuadroId: rectId,
    });

    const totalAnswers = !answers || _.isEmpty(answers) ? 0 : answers.length;

    return totalSegmentos - totalAnswers;
  } else {
    return null;
  }
}

export function getActiveConnectionId(
  rectId: string,
  rectConnections: any,
  userConnections: ConnectionType
) {
  const numConnections = getPropertyAsArray(rectConnections, "conexion").length;
  const connectionsUserByRect = _.chain(userConnections)
    .pickBy((value, key) => {
      return (
        _.includes(key, `rec-${rectId}`) && value.status === AnswerStatus.RIGHT
      );
    })
    .keys()
    .value().length;

  const connectionId = numConnections - connectionsUserByRect - 1; //resta 1 para que los ConnectionId incluyan el 0 para compatibilidad con eventos touch

  if (connectionId >= 0) {
    return String(connectionId);
  } else {
    return NO_ACTIVE_CONNECTION;
  }
}

export function getMaxRightAnswers(recuadros) {
  const connections = _.chain(recuadros)
    .map((rec) => {
      if (rec.conexiones && rec.conexiones.conexion) {
        const totalSegmentos = _.sumBy(
          getPropertyAsArray(rec.conexiones, "conexion"),
          function (num) {
            return parseInt(num.num_seg.$, 10);
          }
        );
        return totalSegmentos;
      }
      return parseInt(rec.num_cnx.$, 10);
    })
    .sum()
    .value();

  const textAnswers = _.filter(recuadros, (rec) => {
    return rec.frase.$;
  }).length;

  const output = connections + textAnswers;

  if (output === 0) {
    throw new Error("Ejercicio no posee preguntas");
  }
  return output;
}

export function getCoords(event): CoordenadaType {
  const factorX = event.startX / event.x0;
  const factorY = event.startY / event.y0;
  return {
    x1:
      event.typeEvent === "drag"
        ? Math.round(event.x0 * factorX)
        : event.startX,
    y1:
      event.typeEvent === "drag"
        ? Math.round(event.y0 * factorY)
        : event.startY,
    x2:
      event.typeEvent === "drag"
        ? Math.round(
            event.x0 * factorX + event.dx / Math.abs(event.scalingFactor)
          )
        : event.x0,
    y2:
      event.typeEvent === "drag"
        ? Math.round(
            event.y0 * factorY + event.dy / Math.abs(event.scalingFactor)
          )
        : event.y0,
  };
}

export function getOnlyConnection(data, start = null): OnlyConnectionType {
  if (_.isEmpty(data)) {
    return { exist: false };
  } else {
    if (Object.keys(data).length !== 1) {
      return { exist: false };
    } else {
      const exist = true;
      const recuadroId = Object.keys(data)[0];
      const connectionId = Object.keys(data[String(Object.keys(data)[0])])[0];

      const segmentos = data[String(Object.keys(data)[0])][connectionId];
      const segmentoId = segmentos[0]["@id"];
      const remainingSegmentos = getRemainingSegments(segmentos);
      const nextConnections = getNextConnections(data);
      let newData = {};
      if (_.isEmpty(remainingSegmentos)) {
        if (!_.isEmpty(nextConnections)) {
          newData = { [`${recuadroId}`]: { ...nextConnections } };
        }
      } else {
        newData = {
          [`${recuadroId}`]: { 0: remainingSegmentos, ...nextConnections },
        };
      }
      const [xInicio, yInicio] =
        data[String(Object.keys(data)[0])][connectionId][0].ini_x;
      const startX = start ? start.x : parseInt(xInicio.$, 10) - RADIO_CONEXION;
      const startY = start ? start.y : parseInt(yInicio.$, 10) + RADIO_CONEXION;

      return {
        exist,
        startX,
        startY,
        recuadroId,
        connectionId,
        segmentoId,
        remainingSegmentos,
        newData,
      };
    }
  }
}

export function getRemainingSegments(segmentos) {
  const segm = _.cloneDeep(segmentos);
  const segmentoId = segm[0]["@id"];
  _.remove(segm, { "@id": segmentoId });
  return segm;
}

export function getNextConnections(data) {
  const cnxs = data[String(Object.keys(data)[0])];
  return _.omit(cnxs, [String(Object.keys(cnxs)[0])]);
}

export function isWindow() {
  const platform = window.navigator.platform;
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  if (windowsPlatforms.indexOf(platform) !== NO_INDEX) {
    return true;
  } else {
    return false;
  }
}
