import React from "react";
import { Text, View, TouchableOpacity, Keyboard } from "react-native";

import Layout from "../../constants/Layout";
import { modalBlueStyles as styles } from "../../constants/ModalStyles";

const DEFAULT_HEIGHT = 180;
const FONT_SIZE = {
  SMALL_SCREEN: 25,
  OTHER: 30,
};

interface ModalAuthErrorType {
  onPress: () => void;
  mensaje: string;
  height?: number;
  color?: string;
  button?: string;
}

export default function ModalAuthError({
  onPress,
  mensaje,
  height = DEFAULT_HEIGHT,
  color = "rgb(32,96,234)",
  button = "OK",
}: ModalAuthErrorType) {
  const localStyles = {
    button: {
      backgroundColor: color,
      shadowColor: color,
    },
    content: {
      borderColor: color,
      shadowColor: color,
      height,
    },
  };
  Keyboard.dismiss();
  return (
    <View style={styles.modalContainer}>
      <View style={[styles.modalContent, localStyles.content]}>
        <View style={[styles.containerTitle, { marginHorizontal: "5%" }]}>
          <Text
            style={[
              styles.modalTitle,
              {
                fontSize: Layout.isSmallScreen
                  ? FONT_SIZE.SMALL_SCREEN
                  : FONT_SIZE.OTHER,
              },
            ]}
            allowFontScaling={false}
          >
            {mensaje}
          </Text>
        </View>

        <View style={styles.containerButtons}>
          <TouchableOpacity
            style={[styles.button, localStyles.button]}
            onPress={onPress}
          >
            <Text style={styles.textButton} allowFontScaling={false}>
              {button}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
