import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

import ModalAuthError from "../../components/AuthComponents/ModalAuthError";
import LogoBrain from "../../components/LogoComponents/LogoBrain";
import LogoText from "../../components/LogoComponents/LogoText";
import {
  getInstitutionsList,
  getPais,
  getPlatform,
} from "../../components/functions/authFunctions";
import Layout from "../../constants/Layout";
import { loginStyles } from "../../constants/LoginStyles";
import { defaultColors } from "../../constants/defaultColors";
import useConnectionStatus from "../../hooks/useConnectionStatus";
import { InputUsernameProps } from "../../navigation/Routes";

const HEIGHT_MODAL_ERROR = {
  PERU: 270,
  OTRO: 180,
};

export default function LoginInputUsernameScreen({
  route,
  navigation,
}: InputUsernameProps) {
  const [name, onChangeName] = useState(null);
  const [isError, setError] = useState(false);
  const [showHelp, setHelp] = useState(false);
  const [loading, setLoading] = useState(false);
  const platformName = getPlatform(Platform.OS);

  const isOnline = useConnectionStatus();
  const [{ isSimple, cargando }, setIsSimple] = useState({
    isSimple: false,
    cargando: true,
  });
  const state = {
    pais: getPais(route.params.paisId),
    mobile: Layout.isSmallScreen,
  };

  useEffect(() => {
    if (state.pais) {
      setIsSimple({
        isSimple: state.pais.institucionPais === "Perú",
        cargando: false,
      });
    } else {
      navigation.navigate("SelectPais");
    }
  }, [state.pais, navigation]);

  const checkUser = async () => {
    setLoading(true);
    const institutions = await getInstitutionsList(
      state.pais.institucionPais,
      name
    );
    if (_.isEmpty(institutions)) {
      setLoading(false);
      setError(true);
    }

    if (institutions.length === 1) {
      setLoading(false);
      navigation.navigate("InputPassword", {
        paisId: state.pais.id,
        user: name,
        usernameId: institutions[0].usernameId,
      });
    }

    if (institutions.length > 1) {
      setLoading(false);
      navigation.navigate("SelectInstitution", {
        paisId: state.pais.id,
        user: name,
        instituciones: institutions,
      });
    }
  };
  if (cargando) return <ActivityIndicator />;
  return (
    <View style={loginStyles.containerFlex}>
      <View style={loginStyles.logoContainer}>
        <View style={loginStyles.logoBrain}>
          <LogoBrain color={isSimple ? defaultColors.fucsia : "black"} />
        </View>
        {!isSimple && (
          <View style={loginStyles.itemContainer}>
            <Text
              style={loginStyles.textWelcomeHeader}
              allowFontScaling={false}
            >
              ¡Bienvenido a{" "}
              <View style={loginStyles.logoText}>
                <LogoText />
              </View>{" "}
              para {platformName}!
            </Text>
            <Text style={loginStyles.textWelcome} allowFontScaling={false}>
              ConectaIdeas funciona sin internet, pero requiere que estés
              conectado para ingresar a tu sesión y sincronizar tu progreso.
            </Text>
          </View>
        )}
      </View>

      <View style={loginStyles.itemContainer}>
        <Text
          data-test-id="inputName"
          style={loginStyles.text}
          allowFontScaling={false}
        >
          Ingresa tu usuario
        </Text>
        <TextInput
          style={[
            loginStyles.inputContainer,
            {
              borderColor: isSimple ? defaultColors.yellow : defaultColors.blue,
            },
          ]}
          autoCorrect={false}
          autoCapitalize="none"
          autoFocus
          enablesReturnKeyAutomatically
          onChangeText={(text) => onChangeName(_.replace(text, /\s/g, ""))}
          placeholder={"Usuario " + state.pais.idExample}
          disableFullscreenUI
          data-test="usernameInput"
          testID="usernameInput"
        />
      </View>

      {isSimple && (
        <View style={loginStyles.loginContainerPeru}>
          <Text
            style={loginStyles.linkText}
            allowFontScaling={false}
            onPress={() => setHelp(true)}
            testID="userHelp"
          >
            No sé mi {state.pais.idType}
          </Text>
          <TouchableOpacity onPress={checkUser}>
            <Text style={loginStyles.flecha}>➡️</Text>
          </TouchableOpacity>
        </View>
      )}
      {!showHelp && !isError && !loading && !isSimple && (
        <TouchableOpacity
          style={name ? loginStyles.button : loginStyles.buttonDisabled}
          onPress={checkUser}
          disabled={!name}
          data-test="continueButton"
          testID="continueButton"
        >
          <Text style={loginStyles.textButton} allowFontScaling={false}>
            CONTINUAR
          </Text>
          <Icon size={22} name="sign-in" color="white" />
        </TouchableOpacity>
      )}
      {loading && (
        <ActivityIndicator
          size="large"
          color={isSimple ? defaultColors.calipso : defaultColors.blue}
        />
      )}
      {!isOnline && (
        <Text allowFontScaling={false}>
          Actualmente no estás conectado a internet. Recuerda que para descargar
          los ejercicios necesitas estar conectado.
        </Text>
      )}

      <View style={{ height: 80 }} />
      {showHelp && (
        <ModalAuthError
          onPress={() => setHelp(false)}
          mensaje={state.pais.helpUserMessage}
          height={350}
          color={isSimple ? defaultColors.calipso : defaultColors.blue}
          button={isSimple ? "ACEPTAR" : "Aceptar"}
        />
      )}
      {isError && (
        <ModalAuthError
          onPress={() => setError(false)}
          mensaje={state.pais.noUserMessage}
          color={isSimple ? defaultColors.calipso : defaultColors.blue}
          button={isSimple ? "ACEPTAR" : "Aceptar"}
          height={isSimple ? HEIGHT_MODAL_ERROR.PERU : HEIGHT_MODAL_ERROR.OTRO}
        />
      )}
    </View>
  );
}
