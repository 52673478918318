import { StyleSheet, TextStyle, ViewStyle } from "react-native";

import Layout from "./Layout";

const WIDTH = {
  SMALL_SCREEN: 300,
  OTHER: 400,
};

const BLUE = "rgb(32,96,234)";
const GRIS = "rgb(217, 217, 217)";

const BASIC_BUTTON: ViewStyle = {
  height: 25,
  borderRadius: 3,
  shadowOffset: { width: 3, height: 3 },
  shadowOpacity: 0.2,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  elevation: 5,
};

const CONTAINER: ViewStyle = {
  flex: 1,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  position: "absolute",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(128,128,128,0.8)",
};

const CONTENT: ViewStyle = {
  width: Layout.isSmallScreen ? WIDTH.SMALL_SCREEN : WIDTH.OTHER,
  height: 200,
  paddingTop: 20,
  backgroundColor: "white",
  flexDirection: "column",
  borderRadius: 8,
  borderWidth: 2,
  alignItems: "center",
  shadowOffset: { width: 5, height: 5 },
  shadowOpacity: 0.3,
};

const CONTAINER_BUTTON: ViewStyle = {
  alignItems: "center",
  flexDirection: "row",
  right: 5,
  bottom: 0,
  position: "absolute",
};

const TEXT_BUTTON: TextStyle = {
  fontSize: 14,
  color: "white",
};

export const modalGreenStyles = StyleSheet.create({
  modalContainer: CONTAINER,
  modalContent: {
    ...CONTENT,
    borderColor: "green",
    shadowColor: "green",
  },
  containerFlags: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderTopColor: GRIS,
    borderTopWidth: 1,
    borderBottomColor: GRIS,
    borderBottomWidth: 1,
    height: "50%",
    width: "100%",
    paddingTop: 1,
  },
  containerTitle: {
    height: "28%",
  },
  modalTitle: {
    fontSize: 30,
  },
  button: {
    ...BASIC_BUTTON,
    backgroundColor: "green",
    width: 140,
    shadowColor: "green",
  },
  containerButtons: {
    ...CONTAINER_BUTTON,
    justifyContent: "space-between",
    width: 220,
    height: "22%",
  },
  buttonBack: {
    ...BASIC_BUTTON,
    backgroundColor: "white",
    width: 70,
    borderColor: "grey",
    borderWidth: 1,
    shadowColor: BLUE,
  },
  textButton: TEXT_BUTTON,
  text: {
    textAlign: "center",
  },
});

export const modalBlueStyles = StyleSheet.create({
  modalContainer: CONTAINER,
  modalContent: {
    ...CONTENT,
    borderColor: BLUE,
    shadowColor: BLUE,
  },
  containerTitle: {
    minHeight: "70%",
    alignItems: "center",
  },
  modalTitle: {
    fontSize: 30,
    textAlign: "center",
  },
  button: {
    ...BASIC_BUTTON,
    height: 25,
    width: 70,
    backgroundColor: BLUE,

    shadowColor: BLUE,
  },
  containerButtons: {
    ...CONTAINER_BUTTON,
    width: 80,
    height: "30%",
  },
  textButton: TEXT_BUTTON,
  containerPairButtons: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "90%",
    height: "30%",
    bottom: 0,
  },
});
