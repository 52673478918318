import React from "react";
import { ImageSourcePropType } from "react-native";
import { Image as ImageSvg } from "react-native-svg";

interface ImageTypeInput {
  x: number;
  y: number;
  w: number;
  h: number;
  img64: ImageSourcePropType;
}

export default function Image(props: ImageTypeInput) {
  const x = props.x;
  const y = props.y;
  const w = props.w;
  const h = props.h;
  return (
    <ImageSvg
      preserveAspectRatio="none"
      x={x}
      y={y}
      width={w}
      height={h}
      xlinkHref={props.img64}
    />
  );
}
