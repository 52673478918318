import NetInfo from "@react-native-community/netinfo";
import { useEffect, useState } from "react";

export default function useConnectionStatus() {
  const [online, setOnline] = useState(false);
  useEffect(() => {
    NetInfo.fetch().then((state) => {
      setOnline(state.isConnected);
    });

    const unsubscribe = NetInfo.addEventListener((state) => {
      setOnline(state.isConnected);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return online;
}
