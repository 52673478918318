import _ from "lodash";
import React from "react";
import { View, Platform, Text, StyleSheet } from "react-native";

import useConnectionStatus from "../../hooks/useConnectionStatus";
import useScreenInfo from "../../hooks/useScreenInfo";
import parameters from "../../metadata.json";

const WIDTH_HORIZONTAL_SCREEN = 75;
const MARGEN = 2;

interface InfoType {
  tarea: number;
  exercise: number;
  isHorizontal: boolean;
  exerciseUpdatedAt: number;
}

export default function Info({
  tarea,
  exercise,
  isHorizontal,
  exerciseUpdatedAt,
}: InfoType) {
  const styles = StyleSheet.create({
    container: {
      flexDirection: isHorizontal ? "column" : "row",
      width: isHorizontal ? WIDTH_HORIZONTAL_SCREEN : "100%",
      left: 0,
      position: isHorizontal ? "absolute" : "relative",
      bottom: 0,
      justifyContent: isHorizontal ? "flex-start" : "center",
    },
    version: {
      textAlign: "center",
      fontSize: 8,
      bottom: 1,
      marginHorizontal: isHorizontal ? 0 : MARGEN,
    },
  });
  const screenInfo = useScreenInfo();
  const isOnline = useConnectionStatus();
  const connected = isOnline ? "Online" : "Offline";

  return (
    <View style={styles.container}>
      <Text style={styles.version} allowFontScaling={false}>
        {_.floor(screenInfo.insets.top)}-{connected}
      </Text>
      <Text style={styles.version} allowFontScaling={false}>
        TA-{tarea}
      </Text>
      <Text style={styles.version} allowFontScaling={false}>
        E-{exercise}
      </Text>
      <Text style={styles.version} allowFontScaling={false}>
        {screenInfo.windowWidth}x{screenInfo.windowHeight}{" "}
        {screenInfo.isMobile ? 1 : 0}
        {screenInfo.isHorizontal ? 1 : 0}
      </Text>
      <Text style={styles.version} allowFontScaling={false}>
        {exerciseUpdatedAt}
      </Text>
      <Text style={styles.version} allowFontScaling={false}>
        v{parameters.version}
        {Platform.OS[0]}
      </Text>
    </View>
  );
}
