import React from "react";
import { Svg, G, Path } from "react-native-svg";

export default function Logo({ color }: { color: string }) {
  return (
    <Svg height="100%" width="100%" viewBox="300 40 273 154" fill="black">
      <G id="brain">
        <Path
          fill={color}
          d="M442.1,115.9c0,24,0,48,0,72c0,4-0.5,8-1.8,11.8c-3.2,8.8-8.7,15.5-18.3,17.2c-10,1.8-18.2-1.9-24.7-9.7
		c-3.5-4.2-5.9-9.1-6.9-14.5c-0.4-2.3-1.2-2.8-3.5-2.7c-14.4,0.6-26.2-4.9-35.3-15.9c-7.8-9.5-9.1-20.4-4.6-31.9
		c1-2.4,0.7-3.4-1.9-4.2c-6.3-1.9-10.4-6.6-12.1-12.7c-2.6-9-3.1-18.2,0.8-27c3.2-7.2,8.2-13.1,16.3-15.2c1.9-0.5,1.6-1.6,1.4-3
		c-1.1-7-0.5-13.8,2.2-20.3c2.8-6.7,6.9-12.4,13-16.5c3.3-2.3,6.9-3.7,10.9-3.8c2.4-0.1,3.5-1.2,4.4-3.2c3.1-7.3,7.7-13.4,15.1-16.7
		c6.9-3,14.3-4.7,21.6-3.9c13.3,1.5,23.3,13.5,23.3,27.1C442.2,67.1,442.1,91.5,442.1,115.9z M433.5,144.9c0-14.4,0-28.8,0-43.2
		c0-1.7-0.2-3.3-1.4-4.8c-16-19.7-46-17.3-58.6,4.7c-0.7,1.3-0.9,2.5-0.6,4c0.8,4.7-2.3,8.8-6.9,9.6c-4.6,0.8-8.9-2.2-9.8-6.8
		c-0.8-4.5,2.2-8.8,6.9-9.7c0.6-0.1,1.3,0,1.8-0.3c1.3-0.8,2.8-1.8,1.3-3.5c-4.8-5.4-15.3-5.1-20.3,0.4c-6.8,7.5-8.5,19.9-3.9,28.9
		c3.4,6.7,9.9,8.1,15.7,3.4c2.2-1.8,4.5-3.3,6.9-4.8c0.7-0.4,1.6-1.1,2.2-0.4c1.7,1.6,2.5,3.9,3.2,6c0.5,1.7-1.4,1.8-2.4,2.6
		c-1.7,1.4-3.6,2.6-5.2,4.1c-7.4,6.7-10.5,14.9-8.4,24.8c2.3,10.6,15.4,20.5,27.5,21.5c13.5,1.1,26.4-8.8,30.2-21
		c0.6-1.8,0.3-3.1-0.8-4.7c-3-4-2.5-8.8,1.1-11.8c3.4-2.8,8.8-2.4,11.7,0.9c3,3.4,2.6,8.8-1,12.1c-0.8,0.8-1.6,1.4-1.9,2.6
		c-2.9,11.9-9.8,20.6-20.4,26.4c-1.6,0.9-2,2-1.7,3.7c0.7,3.8,1.9,7.4,4.2,10.5c4.1,5.8,9.5,9.5,17,8.4c7.6-1.2,11.1-6.6,12.9-13.4
		c0.8-2.8,0.8-5.7,0.8-8.6C433.5,172.7,433.5,158.8,433.5,144.9z M433.3,85.3c0-14.4,0-28.5,0-42.7c0-2.1-0.1-4.2-0.9-6.2
		c-3.1-8.3-10.1-12.7-19.1-12c-10,0.8-18.2,4-22.8,13.8c-1.3,2.8-1.3,4.4,1.6,5.8c1.6,0.8,2.8,2.2,4.1,3.5c2.4,2.4,4.6,4.8,7.9,6.3
		c3.9,1.8,5.2,6.7,3.3,10.3c-2.2,4.1-7.5,6-11.6,4.2c-4.3-1.8-6.1-6.2-4.4-11c0.3-1,0.8-1.8,0-2.8c-5.3-5.6-13-9.6-20.6-3.3
		c-9.8,8-12.1,18.4-10.1,29.9c0.3,1.6,1.2,2.4,2.6,2.9c2.2,0.7,4.4,1.6,6.2,3c2.2,1.7,3.8,1.4,5.9-0.5c17.1-14.8,37.5-15.5,55.8-2.2
		C431.6,84.6,432.2,85.3,433.3,85.3z"
        />
        <Path
          fill={color}
          d="M444.9,116.2c0-24.8,0-49.6,0-74.4c0-13,10-25.7,22.9-26.2c16.9-0.6,28.8,3.4,36,18.8c1.4,2.9,2.9,4.2,6.4,4.5
		c8.6,0.9,14.4,6.4,19,13.3c5.6,8.2,7.3,17.4,5.8,27.2c-0.2,1.5-0.2,2.4,1.5,3c10.9,3.5,16.1,11.9,18.6,22.4
		c1.7,7,0.8,13.9-1.8,20.6c-2.4,6.1-6.4,10.5-12.9,12.4c-1.6,0.4-2,0.9-1.4,2.7c6.2,16.1,2.2,30.3-11.8,40.4
		c-8.4,6.1-17.7,9.6-28.3,8.4c-1.9-0.2-2.1,0.9-2.4,2.2c-1.3,6.2-3.7,11.8-7.9,16.6c-7.4,8.4-18,11.4-27.6,7.9
		c-8.8-3.2-16-13.7-16.1-24.1c-0.1-16.2,0-32.4-0.1-48.6C444.9,134.2,444.9,125.2,444.9,116.2z M453.5,145.8c0,14.3,0,28.6,0,42.9
		c0,1.6,0,3.2,0.2,4.8c1.4,10.1,9,16.3,18.2,14.9c8.3-1.3,16.9-12.2,16.4-20.6c-0.1-1.5-0.9-2.1-2-2.7c-10.4-5.6-17-14.2-20.1-25.6
		c-0.4-1.3-0.9-2.3-2-3.2c-3.7-3.3-4.1-8.6-1.1-12.1c3-3.4,8-3.9,11.6-1c3.7,2.9,4.4,7.7,1.3,11.7c-1.4,1.8-1.4,3.3-0.8,5.2
		c6.2,17.8,28.6,30.2,49.9,11.2c10.5-9.4,11.1-22.8,2.3-33.9c-2-2.5-4.6-4.1-7-6.1c-4.3-3.5-4.4-3.8-1.5-8.5
		c0.8-1.3,1.7-1.5,2.9-0.8c2.6,1.4,4.9,3.1,7.2,4.9c5.9,4.7,12.4,3.4,15.6-3.4c3.4-7.1,3.6-14.6,0.7-21.8
		c-4.5-11.2-13.3-14.3-23-8.6c-1,0.6-2.6,0.8-2.3,2.3c0.3,1.6,1.5,2.3,3.3,2.6c5.2,0.8,8,4.9,7.1,10c-0.8,4.2-5.1,7.1-9.7,6.5
		c-4.4-0.6-7.6-5-6.9-9.7c0.2-1.3,0.3-2.5-0.5-3.7c-4-6.4-9.1-11.7-16-15c-14-6.7-29.4-3.5-40.9,8.2c-2,2.1-3,4.2-3,7.1
		C453.6,116.2,453.5,131,453.5,145.8z M453.5,61L453.5,61c0,7.1,0,14.2,0,21.3c0,1.8,0.4,2.6,2.2,1.2c0.5-0.4,1-0.7,1.4-1.1
		c8.4-5.8,17.7-8.8,27.9-8.2c10.6,0.6,19.7,4.8,27.3,12.2c1.2,1.2,2.2,1.5,3.6,0.5c2.5-1.7,5.2-3,8.1-3.9c1.2-0.4,1.8-1.1,2-2.3
		c2-10.2,0.5-19.5-6.8-27.4c-7.3-7.8-15.3-8-22.9-0.5c-1.2,1.2-1.5,2.2-0.9,3.9c1.8,4.4-0.4,9.1-4.7,10.8c-4.2,1.7-9.9-0.6-11.6-4.8
		c-1.8-4.2,0.3-8.6,5-10.6c1.1-0.5,2.2-0.8,3-1.9c2.3-3.2,5.3-5.7,8.6-7.8c1.3-0.9,1.6-1.9,1-3.3c-3-6.7-7.5-11.9-15-13.5
		c-2.7-0.6-5.5-1-8.3-1.4c-10.8-1.4-19.9,6.5-20,17.4C453.4,48.2,453.5,54.6,453.5,61z"
        />
        <Path
          fill={color}
          d="M444.4,236.7c4,0,8,0.1,12,0c2.3-0.1,3.4,0.8,3.2,3.2c-0.1,1.3,0,2.6,0.1,3.9c0.2,2.5-0.2,4.2-3.3,3.9
		c-1.5-0.1-1.9,0.7-1.8,2.1c0.1,2.2-1,2.9-3.1,2.9c-4.7-0.1-9.4-0.1-14.1,0c-2,0-3.4-0.4-3.2-2.8c0.1-1.5-0.7-2.2-2.2-2.2
		c-2,0-2.6-1-2.6-2.8c0.1-8.8-1.8-8,7.7-8.1C439.6,236.7,442,236.7,444.4,236.7z"
        />
        <Path
          fill={color}
          d="M444.1,223.1c-8.4,0-16.8,0-25.2,0c-1.8,0-3.4,0.1-3.4-2.5c0-2.5,1.3-2.7,3.2-2.7c17-0.5,33.9-0.4,50.9,0
		c1.9,0.1,3,0.5,3,2.7c0,2.2-1.3,2.6-3.1,2.6C461.1,223.1,452.6,223.1,444.1,223.1z"
        />
        <Path
          fill={color}
          d="M443.6,224.6c7.1,0,14.2,0,21.3,0c1.9,0,3.6-0.1,3.6,2.6c0,2.6-1.4,2.9-3.5,2.9c-14.3,0-28.5,0-42.8,0
		c-2,0-3.5-0.3-3.5-2.9c0-2.7,1.7-2.7,3.6-2.7C429.4,224.6,436.5,224.6,443.6,224.6z"
        />
        <Path
          fill={color}
          d="M444.5,236.4c-5.6,0-11.2-0.1-16.8,0c-2,0-3-0.6-3-2.7c0-2,0.8-2.9,2.9-2.9c11.1,0,22.2,0,33.2,0c2.1,0,3.1,0.9,3,3.1
		c-0.1,2.3-1.4,2.5-3.2,2.5C455.3,236.4,449.9,236.4,444.5,236.4z"
        />
        <Path
          fill={color}
          d="M396.9,126.5c-0.4-5.7-1.8-11.8-6.2-16.9c-2.9-3.3-2.7-8.1,0.4-11.1c3.2-3.2,8.2-3.4,11.7-0.4c3.3,2.8,4,7.6,1.2,11
		c-1.7,2.1-1.6,3.6-0.7,5.9c4.7,11.2,2,28.7-8.5,36c-1.1,0.7-1.4,1.9-2,2.9c-2.3,4.4-7,6.1-11.1,4.1c-4.1-1.9-6-6.4-4.5-10.5
		c1.7-4.4,6.4-6.6,11.1-4.8c1.9,0.7,3,0.2,4.1-1.1C395,138.5,396.9,132.9,396.9,126.5z"
        />
        <Path
          fill={color}
          d="M481.1,126.4c0.2-4.4,0.6-8.8,2.6-12.9c0.8-1.7,0.9-3-0.5-4.6c-3.2-3.7-2.7-8.7,1-11.7c3.5-3,8.4-2.7,11.6,0.7
		c3.2,3.4,2.8,7.9-0.2,11.6c-7.1,8.8-7.8,22.8-1.3,31.2c1.2,1.5,2.2,2.1,4.3,1.2c4.3-1.8,9.2,0.4,11,4.6c1.7,4.1-0.2,8.9-4.5,10.8
		c-4.3,1.9-9,0.2-11.1-4.1c-0.5-1.1-0.9-2.2-1.9-3C484,144.3,481.6,135.8,481.1,126.4z"
        />
      </G>
    </Svg>
  );
}
