import _ from "lodash";
import React from "react";
import { StyleSheet, View, Platform, Linking } from "react-native";
import "moment/locale/es";
import ParsedText from "react-native-parsed-text";

import { AnnouncementType } from "../dataTypes";

export default function Announcement({
  message,
}: {
  message: AnnouncementType;
}) {
  function handleUrlPress(url: string) {
    if (Platform.OS === "web") {
      window.open(url, "_blank");
    } else {
      Linking.openURL(url);
    }
  }
  if (!message || _.isEmpty(message)) {
    return null;
  }

  return (
    <View style={styles.container}>
      <ParsedText
        style={styles.text}
        parse={[{ type: "url", style: styles.url, onPress: handleUrlPress }]}
        childrenProps={{ allowFontScaling: false }}
      >
        {message}
      </ParsedText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "rgb(243,245,247)",
    borderRadius: 3,
    width: "98%",
    padding: 5,
    borderWidth: 1,
    borderColor: "green",
  },
  text: {
    fontWeight: "bold",
    fontStyle: "italic",
  },

  url: {
    color: "blue",
    textDecorationLine: "underline",
  },
});
