import AsyncStorage from "@react-native-async-storage/async-storage";

import key from "../../constants/StorageKeys";
import { Logger } from "../Logger";

//get key from asyncStorage
export const getKeyFromAsyncStorage = async (
  key: string,
  defaultValue: any
) => {
  let data = null;
  try {
    Logger.info("Looking for key", key);
    await AsyncStorage.getItem(key).then((result) => {
      if (result) {
        data = JSON.parse(result);
      } else {
        Logger.info("no data for key", key);
      }
    });
  } catch (error) {
    Logger.captureException(`error al rescatar ${key} desde el storage`, error);
  }
  return data ? data : defaultValue;
};
//save key in asyncStorage
export const saveKeyInAsyncStorage = (key: string, data: any) => {
  try {
    AsyncStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    Logger.captureException(`error al guardar ${key} en el storage`, error);
  }
};

export const getTokenFromStorage = async () => {
  let token: string = null;
  try {
    token = await AsyncStorage.getItem(key.NOTIFICATIONS_TOKEN);
  } catch (error) {
    Logger.captureException(`error al rescatar TOKEN desde el storage`, error);
  }
  return token ? token : "no-token";
};
