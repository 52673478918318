import React from "react";
import { StyleSheet, View, Text, Platform } from "react-native";

import renderFlagsInfo from "./renderFlagsInfo";
import { FlagsInfoType } from "../../Interfaces";
import parameters from "../../metadata.json";
import { getFlagsResume } from "../functions/flagsFunctions";

interface NoExercisesAvailableType {
  flags: FlagsInfoType;
}

export default function NoExercisesAvailable({
  flags,
}: NoExercisesAvailableType) {
  const flagsResume = getFlagsResume(flags);
  console.log("flagsResume", flagsResume);

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Text style={styles.text}>No tienes actividades pendientes.</Text>

        <View style={styles.subContainerFlags}>
          {renderFlagsInfo("blue", "Banderas semanales:", flagsResume.week)}
          {renderFlagsInfo("green", "Banderas mensuales:", flagsResume.month)}
          {renderFlagsInfo("yellow", "Banderas anuales:", flagsResume.year)}
        </View>

        <Text style={styles.version} allowFontScaling={false}>
          v{parameters.version}
          {Platform.OS[0]}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 2,
    maxWidth: 1000,
    backgroundColor: "rgb(164,203,244)",
    alignItems: "center",
    justifyContent: "center",
  },
  subContainer: {
    paddingVertical: 15,
    paddingHorizontal: "5%",
    maxWidth: 1000,
    backgroundColor: "white",
    width: "90%",
    height: "90%",
    marginVertical: 40,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "space-between",
  },
  subContainerFlags: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  text: {
    fontSize: 20,
    textAlign: "center",
  },
  version: {
    bottom: 1,
  },
});
