import { ExerciseDataType } from "../components/ExerciseComponents/ExerciseInterfaces";
import { EXERCISE_INITIAL_STATUS } from "../components/ExerciseComponents/ExerciseReducer";

export const exerciseInitialStatus = (
  exerciseId,
  tareaAsignadaId
): ExerciseDataType => {
  return {
    ...EXERCISE_INITIAL_STATUS,
    exerciseId,
    tareaAsignadaId,
  };
};
