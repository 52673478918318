import { MutationFunctionOptions } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";

import StorageKeys from "../../constants/StorageKeys";
import parameters from "../../metadata.json";
import { Logger } from "../Logger";

const version = parameters.version;

const PATTERN = 250;

export type TokenObject = {
  token: string;
  shouldReport: boolean;
};

export async function requestPermissionsAsync() {
  return await Notifications.requestPermissionsAsync({
    ios: {
      allowAlert: true,
      allowBadge: true,
      allowSound: true,
    },
    android: {},
  });
}

export async function shouldRequestPermissions() {
  const requestPermissions = await Notifications.getPermissionsAsync().then(
    (settings) => {
      return settings.canAskAgain && !settings.granted;
    }
  );
  return requestPermissions;
}

export async function getCurrentExpoToken(): Promise<TokenObject> {
  try {
    const projectId = Constants.expoConfig.extra?.eas?.projectId;
    const expoPushTokenAsync = await Notifications.getExpoPushTokenAsync({
      projectId,
    });
    return {
      token: expoPushTokenAsync.data,
      shouldReport: true,
    };
  } catch (e) {
    console.warn("Error getting token", e);
    const storedToken = await AsyncStorage.getItem(
      StorageKeys.NOTIFICATIONS_TOKEN
    );
    if (storedToken) {
      return {
        token: storedToken,
        shouldReport: false,
      };
    }
  }
  return {
    token: "no-token",
    shouldReport: false,
  };
}

/**
 * Consulta por el token actual y lo reporta al servidor.
 * En caso de que no se pueda obtener el token entonces se asume que el anterior aún es válido y se ignora el error
 *
 */
export async function registerForPushNotificationsAsync(
  mutationSaveToken: (options: MutationFunctionOptions) => Promise<any>
): Promise<void> {
  try {
    const permissionsAsync = await Notifications.getPermissionsAsync();

    let permissionStatus = permissionsAsync.status;
    if (permissionsAsync && permissionsAsync.status !== "granted") {
      const { status } = await requestPermissionsAsync();
      permissionStatus = status;
    }
    if (permissionStatus !== "granted") {
      Logger.error("No hay permisos para obtener token", permissionStatus);
      throw new Error("No hay permisos para obtener token" + permissionStatus);
    }

    if (Platform.OS === "android") {
      await Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.DEFAULT,
        vibrationPattern: [0, PATTERN, PATTERN, PATTERN],
        lightColor: "#FF231F7C",
      });
    }

    const token = await getCurrentExpoToken();
    if (token.shouldReport) {
      try {
        await mutationSaveToken({
          variables: { token: token.token, platform: Platform.OS, version },
        });
        await AsyncStorage.setItem(
          StorageKeys.NOTIFICATIONS_TOKEN,
          token.token
        );
        Logger.info(`TOKEN reported successfully`);
      } catch (e) {
        Logger.error("Error reporting token", e);
      }
    }
  } catch (e) {
    Logger.error("Error registering for push notifications", e);
  }
}
