/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AWSDate: { input: undefined; output: undefined };
  AWSDateTime: { input: undefined; output: undefined };
  AWSEmail: { input: undefined; output: undefined };
  AWSIPAddress: { input: undefined; output: undefined };
  AWSJSON: { input: undefined; output: undefined };
  AWSPhone: { input: undefined; output: undefined };
  AWSTime: { input: undefined; output: undefined };
  AWSTimestamp: { input: undefined; output: undefined };
  AWSURL: { input: undefined; output: undefined };
};

export type AlternativasPreguntasMetacognitivas = {
  __typename?: "AlternativasPreguntasMetacognitivas";
  alternativeId?: Maybe<Scalars["Int"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type Curso = {
  __typename?: "Curso";
  alumnos_count?: Maybe<Scalars["Int"]["output"]>;
  flagsMonth?: Maybe<Array<Maybe<Flags>>>;
  flagsWeek?: Maybe<Array<Maybe<Flags>>>;
  flagsYear?: Maybe<Array<Maybe<Flags>>>;
  id?: Maybe<Scalars["Int"]["output"]>;
  institucion_id?: Maybe<Scalars["Int"]["output"]>;
  message?: Maybe<Message>;
  nivel?: Maybe<Scalars["Int"]["output"]>;
  nombre?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["Int"]["output"]>;
};

export type Ejercicio = {
  __typename?: "Ejercicio";
  id: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  position: Scalars["Int"]["output"];
  tarea_asignada_id: Scalars["Int"]["output"];
  updated_at: Scalars["String"]["output"];
};

export type EvaluacionInput = {
  banderas: Scalars["Int"]["input"];
  deviceId: Scalars["String"]["input"];
  elapsedTime: Scalars["Int"]["input"];
  errorCount: Scalars["Int"]["input"];
  exerciseId: Scalars["Int"]["input"];
  nota: Scalars["Float"]["input"];
  omitido: Scalars["Boolean"]["input"];
  plataform?: InputMaybe<Scalars["String"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["Int"]["input"]>;
  respuesta: Scalars["AWSJSON"]["input"];
  startTime: Scalars["AWSDateTime"]["input"];
  tareaAsignadaId: Scalars["Int"]["input"];
  version: Scalars["String"]["input"];
};

export type Flags = {
  __typename?: "Flags";
  flags: Scalars["Int"]["output"];
  key: Scalars["String"]["output"];
};

export type Index = {
  __typename?: "Index";
  tareas?: Maybe<Array<Maybe<Tarea>>>;
};

export type Message = {
  __typename?: "Message";
  endTime?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startTime?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  deleteToken?: Maybe<Scalars["String"]["output"]>;
  heartbeat?: Maybe<Scalars["String"]["output"]>;
  postAnswer?: Maybe<Scalars["Int"]["output"]>;
  reportMessage?: Maybe<Scalars["String"]["output"]>;
  saveToken?: Maybe<Scalars["String"]["output"]>;
  uploadEvaluacion?: Maybe<Scalars["String"]["output"]>;
};

export type MutationDeleteTokenArgs = {
  token?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationHeartbeatArgs = {
  payload: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPostAnswerArgs = {
  userAnswer?: InputMaybe<UserAnswer>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationReportMessageArgs = {
  messageId: Scalars["String"]["input"];
  token?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSaveTokenArgs = {
  platform?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUploadEvaluacionArgs = {
  evaluacion: EvaluacionInput;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type PaginatedPreguntasMetacognitivas = {
  __typename?: "PaginatedPreguntasMetacognitivas";
  items?: Maybe<Array<Maybe<PreguntasMetacognitivas>>>;
  nextToken?: Maybe<Scalars["String"]["output"]>;
};

export type PaginatedUsuarioCurso = {
  __typename?: "PaginatedUsuarioCurso";
  items?: Maybe<Array<Maybe<UsuarioCurso>>>;
  nextToken?: Maybe<Scalars["String"]["output"]>;
};

export type PreguntasMetacognitivas = {
  __typename?: "PreguntasMetacognitivas";
  choices?: Maybe<Array<Maybe<AlternativasPreguntasMetacognitivas>>>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  multiple?: Maybe<Scalars["Boolean"]["output"]>;
  question?: Maybe<Scalars["String"]["output"]>;
  randomChoices?: Maybe<Scalars["Boolean"]["output"]>;
  survey?: Maybe<Scalars["Boolean"]["output"]>;
  tipo?: Maybe<Scalars["Int"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  getActividades?: Maybe<Index>;
  getBanderasCurso?: Maybe<PaginatedUsuarioCurso>;
  getExerciseKey?: Maybe<Scalars["String"]["output"]>;
  getMensaje?: Maybe<Message>;
  getUsuario?: Maybe<Usuario>;
};

export type QueryGetActividadesArgs = {
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetBanderasCursoArgs = {
  cursoId?: InputMaybe<Scalars["Int"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetExerciseKeyArgs = {
  exerciseId: Scalars["Int"]["input"];
};

export type QueryGetMensajeArgs = {
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetUsuarioArgs = {
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type ResponseMessage = {
  __typename?: "ResponseMessage";
  payload?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  onUserUpdates?: Maybe<ResponseMessage>;
};

export type Tarea = {
  __typename?: "Tarea";
  clase: Scalars["Int"]["output"];
  descripcion: Scalars["String"]["output"];
  ejercicios: Array<Maybe<Ejercicio>>;
  end_time_utc: Scalars["String"]["output"];
  nivel: Scalars["Int"]["output"];
  start_time_utc: Scalars["String"]["output"];
  tarea_asignada_id: Scalars["Int"]["output"];
  total_ejercicios: Scalars["Int"]["output"];
};

export type UserAnswer = {
  banderas?: InputMaybe<Scalars["Int"]["input"]>;
  ejercicioId?: InputMaybe<Scalars["Int"]["input"]>;
  elapsedTime?: InputMaybe<Scalars["Int"]["input"]>;
  errores?: InputMaybe<Scalars["Int"]["input"]>;
  horaInicio?: InputMaybe<Scalars["String"]["input"]>;
  nota?: InputMaybe<Scalars["Float"]["input"]>;
  plataform?: InputMaybe<Scalars["String"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  tareaAsignadaId?: InputMaybe<Scalars["Int"]["input"]>;
  version?: InputMaybe<Scalars["String"]["input"]>;
};

export type Usuario = {
  __typename?: "Usuario";
  alumnoCurso?: Maybe<UsuarioCurso>;
  alumnoCursoFlags?: Maybe<UsuarioCurso>;
  alumnoCursoId?: Maybe<Scalars["Int"]["output"]>;
  apellido_materno?: Maybe<Scalars["String"]["output"]>;
  apellido_paterno?: Maybe<Scalars["String"]["output"]>;
  cursos?: Maybe<PaginatedUsuarioCurso>;
  flagsMonth?: Maybe<Array<Maybe<Flags>>>;
  flagsWeek?: Maybe<Array<Maybe<Flags>>>;
  flagsYear?: Maybe<Array<Maybe<Flags>>>;
  id?: Maybe<Scalars["Int"]["output"]>;
  nombre?: Maybe<Scalars["String"]["output"]>;
  pais?: Maybe<Scalars["String"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type UsuarioCursosArgs = {
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UsuarioCurso = {
  __typename?: "UsuarioCurso";
  curso?: Maybe<Curso>;
  curso_id?: Maybe<Scalars["Int"]["output"]>;
  flagsMonth?: Maybe<Array<Maybe<Flags>>>;
  flagsWeek?: Maybe<Array<Maybe<Flags>>>;
  flagsYear?: Maybe<Array<Maybe<Flags>>>;
  preguntasMetacognitivasPendientes?: Maybe<PaginatedPreguntasMetacognitivas>;
  usuario_id?: Maybe<Scalars["Int"]["output"]>;
};

export type SaveTokenMutationVariables = Exact<{
  token?: InputMaybe<Scalars["String"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  version: Scalars["String"]["input"];
}>;

export type SaveTokenMutation = {
  __typename?: "Mutation";
  saveToken?: string | null;
};

export type GetEverythingQueryVariables = Exact<{ [key: string]: never }>;

export type GetEverythingQuery = {
  __typename?: "Query";
  getActividades?: {
    __typename?: "Index";
    tareas?: Array<{
      __typename?: "Tarea";
      tarea_asignada_id: number;
      clase: number;
      nivel: number;
      descripcion: string;
      total_ejercicios: number;
      end_time_utc: string;
      start_time_utc: string;
      ejercicios: Array<{
        __typename?: "Ejercicio";
        id: number;
        tarea_asignada_id: number;
        name: string;
        position: number;
        updated_at: string;
        key: string;
      } | null>;
    } | null> | null;
  } | null;
  getUsuario?: {
    __typename?: "Usuario";
    id?: number | null;
    nombre?: string | null;
    username?: string | null;
    apellido_materno?: string | null;
    apellido_paterno?: string | null;
    pais?: string | null;
    alumnoCurso?: {
      __typename?: "UsuarioCurso";
      usuario_id?: number | null;
      curso_id?: number | null;
      flagsMonth?: Array<{
        __typename?: "Flags";
        flags: number;
        key: string;
      } | null> | null;
      flagsWeek?: Array<{
        __typename?: "Flags";
        flags: number;
        key: string;
      } | null> | null;
      flagsYear?: Array<{
        __typename?: "Flags";
        flags: number;
        key: string;
      } | null> | null;
      curso?: {
        __typename?: "Curso";
        id?: number | null;
        nivel?: number | null;
        nombre?: string | null;
        message?: {
          __typename?: "Message";
          text?: string | null;
          name?: string | null;
        } | null;
      } | null;
      preguntasMetacognitivasPendientes?: {
        __typename?: "PaginatedPreguntasMetacognitivas";
        items?: Array<{
          __typename?: "PreguntasMetacognitivas";
          id?: number | null;
          title?: string | null;
          question?: string | null;
          multiple?: boolean | null;
          created_at?: string | null;
          choices?: Array<{
            __typename?: "AlternativasPreguntasMetacognitivas";
            alternativeId?: number | null;
            text?: string | null;
          } | null> | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
  getMensaje?: {
    __typename?: "Message";
    text?: string | null;
    name?: string | null;
    id?: string | null;
  } | null;
};

export type GetExerciseKeyQueryVariables = Exact<{
  exerciseId: Scalars["Int"]["input"];
}>;

export type GetExerciseKeyQuery = {
  __typename?: "Query";
  getExerciseKey?: string | null;
};

export const SaveTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "platform" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "version" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "token" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "platform" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "platform" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "version" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "version" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveTokenMutation, SaveTokenMutationVariables>;
export const GetEverythingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getEverything" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getActividades" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "tareas" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tarea_asignada_id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "clase" } },
                      { kind: "Field", name: { kind: "Name", value: "nivel" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "descripcion" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "total_ejercicios" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "end_time_utc" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "start_time_utc" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ejercicios" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "tarea_asignada_id",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "position" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updated_at" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "key" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "getUsuario" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "nombre" } },
                { kind: "Field", name: { kind: "Name", value: "username" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apellido_materno" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "apellido_paterno" },
                },
                { kind: "Field", name: { kind: "Name", value: "pais" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "alumnoCurso" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usuario_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "curso_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "flagsMonth" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "flags" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "key" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "flagsWeek" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "flags" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "key" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "flagsYear" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "flags" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "key" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "curso" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nivel" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nombre" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "text" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "preguntasMetacognitivasPendientes",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "question" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "choices" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "alternativeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "text" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "multiple" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "created_at" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "getMensaje" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEverythingQuery, GetEverythingQueryVariables>;
export const GetExerciseKeyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getExerciseKey" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "exerciseId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getExerciseKey" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "exerciseId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "exerciseId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetExerciseKeyQuery, GetExerciseKeyQueryVariables>;
