import { StyleSheet, Platform } from "react-native";

import { ScreenInfoType } from "../../hooks/useScreenInfo";

const HEIGHT = {
  TOP_BAR: 50,
  TITLE_COUNTERS_IOS: 30,
  TITLE_COUNTERS_OTHER: 35,
  COUNTERS_BOX_HORIZONTAL_SCREEN: 150,
  TEXT_SKIP_WEB: 35,
  TEXT_SKIP_OTHER: 26,
  SKIP_BOX_HORIZONTAL_SCREEN: 45,
};
const WIDTH = {
  HORIZONTAL_SCREEN: 75,
  VERTICAL_SCREEN: 250,
  SKIP_BOX_HORIZONTAL_SCREEN: 68,
  SKIP_BOX_OTHER: 78,
};

const PADDING_HORIZONTAL_SCREEN = 5;

export const getStyles = (color: string) => {
  return StyleSheet.create({
    headerBox: {
      top: 0,
      left: 0,
      position: "absolute",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "space-around",
      paddingBottom: 2,
    },
    number: {
      fontSize: 24,
      color,
      fontWeight: "bold",
      height:
        Platform.OS === "ios"
          ? HEIGHT.TITLE_COUNTERS_IOS
          : HEIGHT.TITLE_COUNTERS_OTHER,
    },
    numberView: {
      paddingTop: 0,
      marginTop: 0,
      height: "60%",
      justifyContent: "center",
      alignItems: "center",
    },
  });
};

export const getTopBarStyles = (screenInfo: ScreenInfoType) => {
  const isHorizontal = screenInfo.isHorizontal;
  return StyleSheet.create({
    headerBox: {
      width: isHorizontal ? WIDTH.HORIZONTAL_SCREEN : "100%",
      height: isHorizontal ? screenInfo.windowHeight : HEIGHT.TOP_BAR,
      backgroundColor: "yellow",
      flexDirection: isHorizontal ? "column" : "row",
      marginTop: 0,
      borderColor: "green",
      borderWidth: 1,
      marginRight: isHorizontal ? 1 : 0,
    },
    arrowButton: {
      height: isHorizontal ? HEIGHT.TOP_BAR : "100%",
      marginLeft: 10,
      justifyContent: "center",
    },
    rightBox: {
      bottom: isHorizontal
        ? screenInfo.windowHeight - WIDTH.VERTICAL_SCREEN
        : 0,
      right: 0,
      height: isHorizontal ? HEIGHT.COUNTERS_BOX_HORIZONTAL_SCREEN : "100%",
      width: isHorizontal ? WIDTH.HORIZONTAL_SCREEN : WIDTH.VERTICAL_SCREEN,
      position: "absolute",
      flexDirection: isHorizontal ? "column" : "row",
    },
    timerBox: {
      height: isHorizontal ? HEIGHT.TOP_BAR : "100%",
      width: 75,
      backgroundColor: "khaki",
      marginLeft: 1,
      borderColor: "grey",
      borderWidth: 1,
      paddingHorizontal: 1,
      marginBottom: isHorizontal ? PADDING_HORIZONTAL_SCREEN : 0,
    },
    countBox: {
      height: isHorizontal ? HEIGHT.TOP_BAR : "100%",
      width: 75,
      backgroundColor: "khaki",
      marginLeft: 1,
      borderColor: "grey",
      borderWidth: 1,
      paddingHorizontal: 1,
      marginBottom: isHorizontal ? PADDING_HORIZONTAL_SCREEN : 0,
    },
    skipBox: {
      right: isHorizontal ? 1 : 0,
      height: isHorizontal ? HEIGHT.SKIP_BOX_HORIZONTAL_SCREEN : "90%",
      width: isHorizontal
        ? WIDTH.SKIP_BOX_HORIZONTAL_SCREEN
        : WIDTH.SKIP_BOX_OTHER,
      backgroundColor: "khaki",
      marginLeft: 5,
      borderColor: "grey",
      borderWidth: 1,
      marginVertical: isHorizontal ? "1%" : 1,
      borderRadius: 5,
      shadowOffset: { width: 3, height: 3 },
      shadowOpacity: 0.5,
      shadowColor: "grey",
      elevation: 5,
    },
    skipButton: {
      height: "100%",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: Platform.OS === "android" ? PADDING_HORIZONTAL_SCREEN : 0,
    },
    textSkipButton: {
      color: "green",
      fontSize: 22,
      fontWeight: "bold",
      height:
        Platform.OS === "web" ? HEIGHT.TEXT_SKIP_WEB : HEIGHT.TEXT_SKIP_OTHER,
    },
  });
};
