import { Platform, StyleSheet, TextStyle, ViewStyle } from "react-native";

const MIN_HEIGHT = 10;
export const LIST_BUTTON_HEIGHT = Platform.OS === "web" ? MIN_HEIGHT : "100%";

export const BASIC_BUTTON: ViewStyle = {
  backgroundColor: "rgb(32,96,234)",
  flexDirection: "row",
  height: 30,
  borderRadius: 5,
  shadowOffset: { width: 3, height: 3 },
  shadowOpacity: 0.2,
  shadowColor: "rgb(32,96,234)",
  alignItems: "center",
  justifyContent: "center",
  opacity: 1,
  elevation: 5,
  width: 300,
  marginTop: 10,
  marginBottom: 10,
};

export const BASIC_BUTTON_LIST: ViewStyle = {
  backgroundColor: "rgb(164,203,244)",
  height: 90,
  padding: 6,
  borderRadius: 3,
  shadowOffset: { width: 5, height: 5 },
  shadowOpacity: 0.5,
  shadowColor: "rgb(32,96,234)",
  elevation: 5,
};

export const BASIC_CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: "#fff",
  alignItems: "center",
  justifyContent: "center",
};

export const BASIC_TEXT: TextStyle = {
  paddingVertical: 3,
  marginTop: 10,
  marginBottom: 3,
  fontSize: 20,
};

export const containerStyles = StyleSheet.create({
  centered: {
    justifyContent: "center",
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  vertical: {
    flex: 1,
    flexDirection: "column",
    maxWidth: 1000,
  },
  info: {
    margin: 12,
    padding: 10,
    borderRadius: 5,
  },
});

export const listStyles = StyleSheet.create({
  containerButtons: {
    width: "100%",
    height: LIST_BUTTON_HEIGHT,
  },
  buttonList: BASIC_BUTTON_LIST,
  boxProgress: {
    height: "30%",
    marginTop: 10,
    marginLeft: "10%",
    borderWidth: 1,
    width: "90%",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export const textStyles = StyleSheet.create({
  advice: {
    fontSize: 20,
    margin: 5,
  },
  subTextInfo: {
    fontSize: 15,
    margin: 3,
  },
});

export const stylesExitScreen = StyleSheet.create({
  container: BASIC_CONTAINER,
  text: { ...BASIC_TEXT, textAlign: "center" },
  containerButtons: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    bottom: 1,
    marginTop: 20,
    width: 300,
  },
  button: { ...BASIC_BUTTON, marginTop: 20, width: "100%" },
  textButton: { fontSize: 20, color: "white" },
});

export const perfilStyles = StyleSheet.create({
  containerInfoBorder: {
    ...containerStyles.info,
    borderColor: "rgb(32,96,234)",
    borderWidth: 1,
  },
  containerVertical: {
    ...containerStyles.vertical,
    backgroundColor: "white",
  },
  buttonList: {
    ...BASIC_BUTTON_LIST,
    height: 70,
  },
});
