import React from "react";
import { Text, View } from "react-native";
import { ListItem } from "react-native-elements";

import {
  listStyles,
  textStyles,
  containerStyles,
} from "../../constants/NewStyles";
import { PassNoVerifiedProps } from "../../navigation/Routes";

export default function ChangePassNoVerified({
  navigation,
}: PassNoVerifiedProps) {
  return (
    <View style={containerStyles.vertical}>
      <View style={containerStyles.info}>
        <Text style={textStyles.advice} allowFontScaling={false}>
          Para poder cambiar tu contraseña debes registrar un correo electrónico
          o un teléfono para que puedas recuperar la contraseña en caso de que
          la pierdas.
        </Text>

        <View style={listStyles.containerButtons}>
          <ListItem
            key={1}
            onPress={() => {
              navigation.navigate("RegistroEmailPhone", {
                type: "email",
              });
            }}
          >
            <ListItem.Content style={listStyles.buttonList}>
              <ListItem.Title>Registrar email</ListItem.Title>
            </ListItem.Content>
          </ListItem>
          <ListItem
            key={2}
            onPress={() => {
              navigation.navigate("RegistroEmailPhone", {
                type: "phone",
              });
            }}
          >
            <ListItem.Content style={listStyles.buttonList}>
              <ListItem.Title>Registrar número telefónico</ListItem.Title>
            </ListItem.Content>
          </ListItem>
        </View>
      </View>
    </View>
  );
}
