import _ from "lodash";
import React, { useState } from "react";
import { Text, View, TextInput, TouchableOpacity } from "react-native";

import ModalAuthError from "../../components/AuthComponents/ModalAuthError";
import { Logger } from "../../components/Logger";
import { loginStyles } from "../../constants/LoginStyles";
import { RegistroEmailPhoneProps } from "../../navigation/Routes";
//TODO Pantalla no funcional aun
export default function RegisterEmailOrPhone({
  navigation,
  route,
}: RegistroEmailPhoneProps) {
  const [input, setInput] = useState("");
  const [codigo, setCodigo] = useState("");

  const [isError, setError] = useState(false);
  const [sent, setSent] = useState(false);

  const type = route.params.type;
  const text = type === "email" ? "Correo Electrónico" : "Número Telefónico";

  return (
    <View style={[loginStyles.container, { justifyContent: "flex-start" }]}>
      {sent ? (
        <>
          <Text style={{ textAlign: "center" }}>
            Te hemos enviado un código de validación a tu {_.toLower(text)},
            ingresalo aquí para continuar.
          </Text>
          <View style={{ height: 20 }} />
          <View style={loginStyles.inputContainer}>
            <TextInput
              style={loginStyles.inputContainer}
              autoCorrect={false}
              onChangeText={(text) => setCodigo(text)}
              placeholder="Código"
              disableFullscreenUI
              testID="input"
            />
          </View>

          <View style={{ height: 10 }} />
          <TouchableOpacity
            style={
              codigo === "" ? loginStyles.buttonDisabled : loginStyles.button
            }
            onPress={() => navigation.navigate("PassVerified")}
            testID="sentButton"
            disabled={codigo === ""}
          >
            <Text style={loginStyles.textButton} allowFontScaling={false}>
              Continuar
            </Text>
          </TouchableOpacity>
        </>
      ) : (
        <>
          <Text style={loginStyles.text} allowFontScaling={false}>
            Ingresa un {text}
          </Text>
          <View style={loginStyles.inputContainer}>
            <TextInput
              style={loginStyles.inputContainer}
              autoCorrect={false}
              onChangeText={(text) => setInput(text)}
              placeholder={text}
              disableFullscreenUI
              testID="input"
            />
          </View>

          <View style={{ height: 10 }} />
          <TouchableOpacity
            style={
              sent || input === ""
                ? loginStyles.buttonDisabled
                : loginStyles.button
            }
            onPress={() => {
              Logger.info(input);
              setSent(true);
            }}
            disabled={sent || input === ""}
            testID="sentButton"
          >
            <Text style={loginStyles.textButton} allowFontScaling={false}>
              Enviar
            </Text>
          </TouchableOpacity>
        </>
      )}

      <View style={{ height: 80 }} />
      {isError && <ModalAuthError onPress={() => setError(false)} mensaje="" />}
    </View>
  );
}
