import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";

import ConectaStorage from "../ConectaIdeas/ConectaStorage";

const KB = 1024;
const INDEX = 2;

const storage = new ConectaStorage();

export const getStorageInfo = async () => {
  let keys = null;

  await AsyncStorage.getAllKeys().then((result) => {
    if (result) {
      keys = _.filter(result, (k) => {
        return _.includes(k, "EXERCISE");
      });
    }
  });
  if (keys) {
    let totalSize = 0;
    for (let k = 0; k < keys.length; k++) {
      const name = _.split(keys[k], "#");
      const exerciseId = _.parseInt(name[1]);
      const tareaId = _.parseInt(name[INDEX]);
      const Size = await storage
        .getSizeInDisc(exerciseId, tareaId)
        .then((size) => {
          return size.size;
        });
      totalSize = totalSize + Size;
    }
    return { numExercises: keys.length, space: getMb(totalSize) };
  }
};

const getMb = (b: number) => {
  const Kb = _.ceil(b / KB);
  const Mb = _.ceil(Kb / KB, 1);
  const Gb = _.ceil(Mb / KB, INDEX);

  if (Gb >= 1) {
    return String(Gb) + " Gb";
  } else {
    if (Mb >= 1) {
      return String(Mb) + " Mb";
    } else {
      return String(Kb) + " Kb";
    }
  }
};
