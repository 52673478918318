import React, { Dispatch, SetStateAction } from "react";
import { Text, View, TouchableOpacity } from "react-native";

import { modalGreenStyles as styles } from "../../constants/ModalStyles";

interface ModalSkippedType {
  setShowSkipModal: Dispatch<SetStateAction<boolean>>;
  onGoToNextExercise: () => void;
}

export default function ModalExerciseSkipped({
  setShowSkipModal,
  onGoToNextExercise,
}: ModalSkippedType) {
  const omitir = () => {
    setShowSkipModal(false);
    onGoToNextExercise();
  };
  return (
    <View style={styles.modalContainer}>
      <View style={styles.modalContent}>
        <View style={styles.containerTitle}>
          <Text style={styles.modalTitle} allowFontScaling={false}>
            ¡Omitir ejercicio!
          </Text>
        </View>
        <Text style={styles.text} selectable={false} allowFontScaling={false}>
          No has completado correctamente el ejercicio.
        </Text>
        <Text style={styles.text} selectable={false} allowFontScaling={false}>
          No podrás volver para completar las respuestas.
        </Text>
        <Text style={styles.text} selectable={false} allowFontScaling={false}>
          ¿Deseas continuar de todas maneras?
        </Text>
        <View style={styles.containerButtons}>
          <TouchableOpacity
            style={styles.buttonBack}
            onPress={() => {
              setShowSkipModal(false);
            }}
          >
            <Text allowFontScaling={false}>Cancelar </Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={omitir}>
            <Text style={styles.textButton} allowFontScaling={false}>
              Sí
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}
