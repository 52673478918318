import PropTypes from "prop-types";
import React from "react";
import { Svg, G, Line, Path } from "react-native-svg";

interface BanderaType {
  active: boolean;
  color: string;
}

export default function Bandera(props: BanderaType) {
  const color = props.active ? props.color : "rgb(217, 217, 217)";

  return (
    <Svg width="100%" height="100%" viewBox="0 0 300 300">
      <G>
        <Path
          d="M10 180 L10 40 Q 70 0, 138 40 T 272 40 L272 180 Q 190 225, 130 175 T 10 180"
          stroke="black"
          strokeWidth={4}
          fill={color}
        />
        <Line
          x1="12"
          y1="10"
          x2="12"
          y2="280"
          stroke="black"
          strokeWidth="25"
        />
      </G>
    </Svg>
  );
}

Bandera.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
};

Bandera.defaultProps = {
  color: "rgb(217, 217, 217)",
  active: true,
};
