import React, { useEffect, useRef } from "react";

import { Answer } from "./AnswerFeedback";
import Draw, { AnswerStatus } from "./Draw";

const wrongAnswer = require("../../../../assets/err_01.mp3");
const rightAnswer = require("../../../../assets/ok_01.mp3");

const audioWrong = new Audio(wrongAnswer);
const audioRight = new Audio(rightAnswer);

export function getAudio(answer: string) {
  switch (answer) {
    case AnswerStatus.WRONG:
      return audioWrong;
    case AnswerStatus.RIGHT:
      return audioRight;
    default:
      throw new Error("Unknown audio for " + JSON.stringify(answer));
  }
}

export default function AnswerFeedback({ answer }: Answer) {
  const audioRef = useRef(audioWrong);

  useEffect(() => {
    if (answer && answer.status !== AnswerStatus.UNKNOWN) {
      audioRef.current = getAudio(answer.status);
      audioRef.current.play();
    }
  }, [answer.status, answer.ts]);

  return <Draw status={answer.status} />;
}
