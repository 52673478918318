import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { ActividadesStackParamList } from "./Routes";
import TopRightButton from "../components/TopRightButton";
import { headerNavigatorHeight } from "../constants/Layout";
import ExerciseScreen from "../screens/ExerciseScreen";
import ListaTareasScreen from "../screens/ListaTareasScreen";

const Stack = createStackNavigator<ActividadesStackParamList>();

export default function ActividadesStackNavigator() {
  return (
    <SafeAreaProvider>
      <Stack.Navigator
        screenOptions={({ navigation }) => ({
          headerRight: () => {
            return <TopRightButton navigation={navigation} />;
          },
        })}
      >
        <Stack.Screen
          name="Tareas"
          component={ListaTareasScreen}
          options={{
            title: "Tareas disponibles",
            headerLeft: () => {
              //never show go back button on this page
              return null;
            },
          }}
        />

        <Stack.Screen
          name="Ejercicio"
          component={ExerciseScreen}
          options={{
            title: "ConectaIdeas",
            headerLeft: () => {
              //never show go back button on this page
              return null;
            },
            headerStyle: { height: headerNavigatorHeight },
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    </SafeAreaProvider>
  );
}
