import React from "react";
import { View, Text, ScrollView } from "react-native";
import { ListItem } from "react-native-elements";

import Logo from "../../components/LogoComponents/Logo";
import Layout from "../../constants/Layout";
import { loginStyles } from "../../constants/LoginStyles";
import useConnectionStatus from "../../hooks/useConnectionStatus";
import { SelectInstitutionProps } from "../../navigation/Routes";

export default function LoginSelectInstitutionScreen({
  route,
  navigation,
}: SelectInstitutionProps) {
  const isOnline = useConnectionStatus();

  const state = {
    paisId: route.params.paisId,
    user: route.params.user,
    instituciones: route.params.instituciones,
    mobile: Layout.isSmallScreen,
  };

  return (
    <ScrollView style={{ backgroundColor: "white" }}>
      <View style={loginStyles.container}>
        <View style={loginStyles.stretch}>
          <Logo />
        </View>

        <View style={loginStyles.itemContainer}>
          <Text style={loginStyles.flatListText} allowFontScaling={false}>
            {state.user}
          </Text>
        </View>

        <Text style={loginStyles.text} allowFontScaling={false}>
          Selecciona una Institución
        </Text>
        <View data-test="institutionsList" style={loginStyles.containerButtons}>
          {state.instituciones.map((inst) => {
            return (
              <ListItem
                key={inst.id}
                onPress={() => {
                  navigation.navigate("InputPassword", {
                    paisId: state.paisId,
                    user: state.user,
                    usernameId: inst.usernameId,
                  });
                }}
                disabled={!isOnline}
              >
                <ListItem.Content
                  style={
                    !isOnline
                      ? loginStyles.disabledButtonList
                      : loginStyles.buttonList
                  }
                >
                  <ListItem.Title>{inst.name}</ListItem.Title>
                </ListItem.Content>
              </ListItem>
            );
          })}
        </View>

        {!isOnline && (
          <Text allowFontScaling={false}>
            Actualmente no estás conectado a internet. Recuerda que para
            descargar los ejercicios necesitas estar conectado.
          </Text>
        )}
      </View>
    </ScrollView>
  );
}
