import _ from "lodash";
import moment from "moment";

import {
  CacheInfoFlagsType,
  ExerciseDataType,
  GroupFlagsType,
} from "./ExerciseInterfaces";
import { ScreenType, TareaWithStatus } from "../../Interfaces";

export enum TYPES {
  start = "start",
  error = "error",
  finish = "finish",
  progress = "progress",
}

interface RouteParamsType {
  tareaAsignadaId: number;
  exerciseId?: number;
  position?: number;
}

export const reportExercise = (
  client,
  type: TYPES,
  status: ExerciseDataType
) => {
  //TODO nueva implementacion
  const payload = JSON.stringify({
    exerciseId: status.exerciseId,
    tareaAsignadaId: status.tareaAsignadaId,
    tiempo: status.user.elapsedTime,
    errores: status.user.errorCount,
    nota: status.user.nota,
    completed: status.user.completed,
  });
  const tipoReporte =
    type === TYPES.start ? "EXERCISE_LOAD" : "EXERCISE_PROGRESS";

  if (type === TYPES.start) {
    //para evitar un número demasiado grande de notificaciones solo reportamos
    //el inicio de un ejercicio
    console.log("reporte: ", tipoReporte, payload);
    // client
    //   .mutate({
    //     mutation: gql(heartbeat),
    //     variables: {
    //       type: tipoReporte,
    //       payload,
    //     },
    //   })
    //   .catch((error) => {
    //     Logger.info("No se pudo reportar ejercicio:", error);
    //   });
  }
};

export const modifyCacheFlags = (banderas: number) => {
  return {
    flagsWeek(cachedFlagsWeek: CacheInfoFlagsType[]) {
      return increaseFlags("week", cachedFlagsWeek, banderas);
    },
    flagsMonth(cachedFlagsMonth: CacheInfoFlagsType[]) {
      return increaseFlags("month", cachedFlagsMonth, banderas);
    },
    flagsYear(cachedFlagsYear: CacheInfoFlagsType[]) {
      return increaseFlags("year", cachedFlagsYear, banderas);
    },
  };
};

const increaseFlags = (
  type: GroupFlagsType,
  cachedFlags: CacheInfoFlagsType[],
  increment: number
) => {
  const flags = [...cachedFlags];
  let key: string;

  if (type === "year") {
    key = "Y" + moment().year().toString();
  } else if (type === "week") {
    key = "W" + moment().format("YYYY#WW").toString();
  } else {
    key = "M" + moment().format("YYYY#MM").toString();
  }

  const index = _.findIndex(flags, { key });

  if (index !== -1) {
    flags[index] = { ...flags[index], flags: flags[index].flags + increment };
  } else {
    flags.push({
      __typename: "Flags",
      flags: increment,
      key,
    });
  }
  return flags;
};

export function getNextExercise(
  tareasIndex: TareaWithStatus[],
  routeParams: RouteParamsType
): ScreenType {
  if (_.isEmpty(tareasIndex)) {
    return { screen: "Tareas" };
  }
  if (routeParams.tareaAsignadaId) {
    const tareaAsignadaId = routeParams.tareaAsignadaId;
    const tarea = _.find(tareasIndex, { tarea_asignada_id: tareaAsignadaId });
    if (tarea) {
      const newEjercicio = _.find(tarea.ejercicios, (ejercicio) => {
        return (
          !ejercicio.user.completed &&
          ejercicio.id !== routeParams.exerciseId &&
          ejercicio.position !== routeParams.position
        );
      });
      if (newEjercicio) {
        return {
          screen: "Ejercicio",
          params: {
            tareaAsignadaId,
            exerciseId: newEjercicio.id,
            position: newEjercicio.position,
          },
        };
      } else {
        //there are no more exercises available on current task, go to activity list
        return { screen: "Tareas" };
      }
    }
  }
  //there are no more tasks, go back to the index
  return { screen: "Tareas" };
}
