import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { Platform } from "react-native";

import { RecoveryPassStackParamList } from "./Routes";
import Layout from "../constants/Layout";
import ChangePassNoVerified from "../screens/PerfilScreens/ChangePassNoVerified";
import ChangePassVerified from "../screens/PerfilScreens/ChangePassVerified";
import RegisterEmailOrPhone from "../screens/PerfilScreens/RegisterEmailOrPhone";

const Stack = createStackNavigator<RecoveryPassStackParamList>();

const ALINIGS = {
  ICON: 8,
  TITLE_WEB: 10,
  TITLE_SMALL_ANDROID: 5,
  OTHER: 0,
};

const alignIcon = Platform.OS !== "web" ? ALINIGS.ICON : ALINIGS.OTHER;
const alignTitle =
  Platform.OS === "web"
    ? ALINIGS.TITLE_WEB
    : Layout.isSmallScreen && Platform.OS === "android"
    ? ALINIGS.TITLE_SMALL_ANDROID
    : ALINIGS.OTHER;
const backButtonVisible = !Layout.isSmallScreen && Platform.OS === "ios";

export default function RecoveryPassStackNavigator({ user }) {
  const verified =
    user.attributes.email_verified || user.attributes.phone_verified;
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        headerStyle: { backgroundColor: "rgb(164,203,244)", height: 40 },
        headerTitleStyle: { color: "black", fontSize: 15 },
        headerTintColor: "black",
        headerLeftContainerStyle: { top: alignIcon },
        headerTitleContainerStyle: { top: alignTitle },
      }}
      initialRouteName={verified ? "PassVerified" : "PassNoVerified"}
    >
      <Stack.Screen
        name="PassVerified"
        options={{
          title: "Nueva Contraseña",
          headerLeft: () => {
            return null;
          },
        }}
      >
        {(props) => <ChangePassVerified {...props} user={user} />}
      </Stack.Screen>
      <Stack.Screen
        name="PassNoVerified"
        component={ChangePassNoVerified}
        options={{
          title: "Seleccionar medio de recuperación",
          headerLeft: () => {
            return null;
          },
        }}
      />

      <Stack.Screen
        name="RegistroEmailPhone"
        component={RegisterEmailOrPhone}
        options={() => ({
          title: "Registra medio de recuperación",
          headerBackTitle: "Selección",
          headerBackTitleVisible: backButtonVisible,
        })}
      />
    </Stack.Navigator>
  );
}
