import _ from "lodash";
import React from "react";
import { Platform } from "react-native";
import Svg, { Rect, Text, G, Circle } from "react-native-svg";
//Aca props:any si uso RectProps,etc aparece tsc error
//porque componentes svg no aceptan onClick,etc
export function RectB9(props: any) {
  if (Platform.OS === "web") {
    const newProps = _.omit(props, ["onPress", "onPressIn"]) as any;
    return (
      <Rect
        {...newProps}
        onClick={props.onPress}
        onMouseDown={props.onPressIn}
      />
    );
  } else {
    return (
      <Rect {...props} onClick={props.onPress} onMouseDown={props.onPressIn} />
    );
  }
}

export function TextB9(props: any) {
  if (Platform.OS === "web") {
    const newProps = _.omit(props, ["onPress", "onPressIn"]) as any;
    return (
      <Text
        {...newProps}
        onClick={props.onPress}
        onMouseDown={props.onPressIn}
      />
    );
  } else {
    return (
      <Text {...props} onClick={props.onPress} onMouseDown={props.onPressIn} />
    );
  }
}

export function CircleB9(props: any) {
  return <Circle {...props} />;
}

export function GB9(props: any) {
  return <G {...props} />;
}

export default function SvgB9(props: any) {
  const web = Platform.OS === "web";
  if (web) {
    const onClick = props.onPress || props.onPressIn;
    const newProps = { ...props };
    delete newProps.onPress;
    delete newProps.onPressIn;
    return <Svg {...newProps} onClick={onClick} />;
  } else {
    return <Svg {...props} />;
  }
}
