import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  ActivityIndicator,
  RefreshControl,
  ScrollView,
  Text,
  View,
} from "react-native";

import { ConectaIdeasContext } from "../components/ConectaIdeas";
import BodyTareasList from "../components/ExercisesListComponents/BodyTareasList";
import NoExercisesAvailable from "../components/ExercisesListComponents/NoExercisesAvailable";
import Top from "../components/ExercisesListComponents/Top";
import { containerStyles } from "../constants/NewStyles";
import { listAvailableHomework } from "../hooks/indexFunctions";
import { TareasProps } from "../navigation/Routes";

function ListaTareasScreen({ navigation }: TareasProps) {
  const { exerciseIndex, refreshIndex, announcementData } =
    useContext(ConectaIdeasContext);

  const [tareasDisponibles, setTareasDisponibles] = useState([]);

  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (!exerciseIndex.loading) {
      const newTareasDisponibles = listAvailableHomework(exerciseIndex.tareas);
      setTareasDisponibles(newTareasDisponibles);
    }
  }, [exerciseIndex.loading, exerciseIndex.tareas]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    refreshIndex().then(() => {
      setRefreshing(false);
    });
  }, []);

  if (exerciseIndex.loading) {
    return (
      <View style={containerStyles.centered}>
        <Text>Cargando listado de actividades</Text>
        <ActivityIndicator size="large" />
      </View>
    );
  } else {
    if (_.isEmpty(tareasDisponibles)) {
      return (
        <ScrollView
          style={containerStyles.vertical}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        >
          <Top announcementData={announcementData} />
          <NoExercisesAvailable flags={exerciseIndex.flags} />
        </ScrollView>
      );
    } else {
      return (
        <ScrollView
          style={containerStyles.vertical}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        >
          <Top
            flags={exerciseIndex.flags}
            announcementData={announcementData}
          />
          <BodyTareasList
            tareasIndex={tareasDisponibles}
            navigation={navigation}
          />
        </ScrollView>
      );
    }
  }
}

export default ListaTareasScreen;
