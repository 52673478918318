import * as FileSystem from "expo-file-system";

export function getExerciseLocalPath(
  exerciseId: number,
  tareaAsignadaId: number
) {
  if (exerciseId && tareaAsignadaId) {
    return `${FileSystem.documentDirectory}EX_${exerciseId}_${tareaAsignadaId}.cx8`;
  } else {
    throw new Error(`Wrong path ${exerciseId} ${tareaAsignadaId}`);
  }
}
