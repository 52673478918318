import _ from "lodash";
import React from "react";
import { StyleSheet, View } from "react-native";

import "moment/locale/es";
import Announcement from "./Announcement";
import FlagsCounter from "./FlagsCounter";
import { FlagsInfoType } from "../../Interfaces";
import { AnnouncementType } from "../dataTypes";

export default function Top({
  flags,
  announcementData,
}: {
  flags?: FlagsInfoType;
  announcementData: AnnouncementType;
}) {
  const announcementStyles = flags ? styles.item : [styles.item, { top: 20 }];
  const showAnuncio = !!announcementData && !_.isEmpty(announcementData);
  return (
    <View style={styles.container}>
      <FlagsCounter flags={flags} />
      {showAnuncio && (
        <View style={announcementStyles}>
          <Announcement message={announcementData} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: 3,
    paddingHorizontal: 2,
    marginBottom: 4,
    paddingLeft: "1%",
  },
  item: {
    top: 40,
    width: "100%",
    marginBottom: 45,
    alignItems: "center",
  },
});
