import React from "react";
import { Dimensions, View, StyleSheet } from "react-native";
import Svg, { G, Line, Polygon } from "react-native-svg";

const LATERAL = 250;
const MIDDLE = 2;

export const AnswerStatus = {
  RIGHT: "right",
  WRONG: "wrong",

  //this is when we haven't evaluated the answer yet
  UNKNOWN: "unknown",
};

export default function Draw({ status }: { status: string }) {
  const styles = StyleSheet.create({
    container: {
      width: LATERAL,
      height: LATERAL,
      top: (Dimensions.get("window").height - LATERAL) / MIDDLE,
      left: (Dimensions.get("window").width - LATERAL) / MIDDLE,
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
    },
  });

  switch (status) {
    case AnswerStatus.RIGHT:
      return (
        <View style={styles.container}>
          <Svg height={LATERAL} width={LATERAL}>
            <DrawTicket />
          </Svg>
        </View>
      );
    case AnswerStatus.WRONG:
      return (
        <View style={styles.container}>
          <Svg height={LATERAL} width={LATERAL}>
            <DrawCross />
          </Svg>
        </View>
      );
    default:
      return <View />;
  }
}

function DrawCross() {
  return (
    <G>
      <Line x1="10" y1="10" x2="210" y2="210" stroke="red" strokeWidth="20" />
      <Line x1="10" y1="210" x2="210" y2="10" stroke="red" strokeWidth="20" />
    </G>
  );
}

function DrawTicket() {
  return (
    <G>
      <Polygon
        points="20,130 50,100 80,170 170,10 210,40 80,210"
        fill="green"
      />
    </G>
  );
}
