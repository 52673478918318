import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import React, { useEffect, useState } from "react";
import { View, StyleSheet, Text, Platform } from "react-native";

import { Config } from "../../app.config";
import TopRightButton from "../components/TopRightButton";
import parameters from "../metadata.json";

const extraConfig = Constants.expoConfig.extra as Config;

export default function SecretScreen() {
  const [{ userId, allowNotifications }, setInfo] = useState({
    userId: "",
    allowNotifications: "",
  });
  const navigation = useNavigation();
  useEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerRight: () => {
        return <TopRightButton navigation={navigation} />;
      },
      headerLeft: () => {
        return null;
      },
    });
    const user = AsyncStorage.getItem("username");
    const allow = Notifications.getPermissionsAsync();

    Promise.all([user, allow]).then((r) => {
      setInfo({ userId: r[0], allowNotifications: r[1].granted ? "Sí" : "No" });
    });
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Text style={{ fontSize: 20, textAlign: "center" }}>
          ID usuario: {userId}
        </Text>
        <View>
          <Item field="ID usuario" value={userId} />
          <Item field="Notificaciones permitidas" value={allowNotifications} />
          <Item field="graphqlLink" value={extraConfig.graphqlLink} />
          <Item field="cognitoPool" value={extraConfig.cognitoPool} />
          <Item field="cx8Url" value={extraConfig.cx8Url} />
        </View>
        <Text style={styles.version} allowFontScaling={false}>
          v{parameters.version}
          {Platform.OS[0]}
        </Text>
      </View>
    </View>
  );
}

const Item = ({ field, value }: { field: string; value: string }) => {
  const text = field + ": " + value;

  return <Text style={styles.text}>{text}</Text>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 2,
    maxWidth: 1000,
    backgroundColor: "rgb(164,203,244)",
    alignItems: "center",
    justifyContent: "center",
  },
  subContainer: {
    paddingVertical: 15,
    paddingHorizontal: "5%",
    maxWidth: 1000,
    backgroundColor: "white",
    width: "90%",
    height: "90%",
    marginTop: 40,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    fontSize: 16,
    paddingVertical: 4,
  },
  version: {
    bottom: 1,
  },
});
