import { ApolloQueryResult } from "@apollo/client";

export type PushNotification = {
  title: string;
  type: "anuncio";
  mensaje: string;
};
export const parseNotification = (input: any): PushNotification => {
  const request = input.request || input.notification.request;
  return request.content.data as PushNotification;
};
export const processNotification = (
  notification: any,
  setAnnouncementData: (announcement: string) => void,
  refreshIndex: () => Promise<ApolloQueryResult<any>>
) => {
  const n = parseNotification(notification);
  if (n.type === "anuncio") {
    setAnnouncementData(n.mensaje);
  }
  //XXX: Esto debe ser reemplazado por modificar el cache de graphql, de manera que pueda funcionar incluso con problemas de internet
  refreshIndex();

  return n;
};

/**
 * En el caso de una interacción, no solo se debe actualizar el estado de la base de datos sino que se debe navegar hacia la pantalla que corresponda con la interacción.
 * @param notification
 * @param setAnnouncementData
 * @param refreshIndex
 */
export const processInteraction = (
  notification: any,
  setAnnouncementData: (announcement: string) => void,
  refreshIndex: () => Promise<ApolloQueryResult<any>>
) => {
  const n = processNotification(
    notification,
    setAnnouncementData,
    refreshIndex
  );

  if (n.type === "anuncio") {
    //TODO: Navegar hasta pantalla de ejercicios
  }
};
