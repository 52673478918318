import React from "react";
import { Svg, G, Path, Polygon } from "react-native-svg";

export default function BanderaChile() {
  return (
    <Svg width="100%" height="100%" viewBox="0 0 496 496">
      <G>
        <Path
          fill="#E13E3E"
          d="M0,249.2v121.6c0,27.2,21.6,46.4,48,46.4h400c26.4,0,48-19.2,48-46.4V249.2H0z"
        />
        <Path
          fill="#D12A2A"
          d="M248,249.2l197.6,168c26.4,0,50.4-19.2,50.4-46.4V249.2H248z"
        />
        <Path
          fill="#FFFFFF"
          d="M448,78.8H48c-26.4,0-48,21.6-48,48.8v121.6h496V127.6C496,100.4,474.4,78.8,448,78.8z"
        />
        <Path
          fill="#E9F3F4"
          d="M448,78.8H48l200,170.4h248V127.6C496,100.4,474.4,78.8,448,78.8z"
        />
        <Path
          fill="#3757A6"
          d="M48,81.2c-26.4,0-48,19.2-48,46.4v121.6h192v-168H48z"
        />
        <Path
          fill="#C11414"
          d="M496,370c0,27.2-21.6,47.2-48,47.2H48c-26.4,0-48-20.8-48-48"
        />
        <Path fill="#D9ECED" d="M48,78.8h400c26.4,0,48,21.6,48,48.8v88.8" />
        <Polygon fill="#2A4D93" points="192,81.2 48,81.2 192,202 " />
        <Path
          fill="#C11414"
          d="M496,370c0,29.6-21.6,47.2-48,47.2H48c-26.4,0-48-20.8-48-48"
        />
        <Polygon fill="#19407F" points="192,81.2 48,81.2 192,122.8 " />
        <Polygon
          fill="#FFFFFF"
          points="96,124.4 106.4,156.4 140,156.4 112.8,176.4 123.2,207.6 96,188.4 69.6,207.6 79.2,176.4 52.8,156.4 85.6,156.4 "
        />
        <Polygon
          fill="#D9ECED"
          points="122.4,206 112.8,176.4 140,156.4 106.4,156.4 96,124.4 85.6,156.4 52.8,156.4 "
        />
      </G>
    </Svg>
  );
}
