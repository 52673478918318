import Constants from "expo-constants";

import { Config } from "./app.config";
import { Logger } from "./src/components/Logger";

const extraConfig = Constants.expoConfig.extra as Config;

Logger.debug("ExtraConfig", extraConfig);

export const authConfig = {
  // Amazon Cognito Region
  region: "us-east-1",

  // Amazon Cognito User Pool ID
  userPoolId: extraConfig?.cognitoPool,

  // Amazon Cognito Web Client ID
  userPoolWebClientId: extraConfig?.cognitoWebClientId,

  // Authentication type
  authenticationFlowType: "USER_PASSWORD_AUTH",

  identityPoolId: extraConfig?.cognitoIdentityPoolId,

  identityPoolRegion: "us-east-1",
};
