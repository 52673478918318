import { throttle } from "lodash";
import React from "react";

import { InputConnectionType } from "./RectInterfaces";
import { useGestureResponder } from "../../react-gesture-responder";
import { CircleB9, GB9 } from "../ExerciseComponents/SvgB9";
import { NO_ACTIVE_CONNECTION } from "../functions/exerciseFunctions";
const WAIT_MS = 100;

const CONEXION = {
  RADIO: 7,
  TOP: 6,
  LEFT: 7,
};

function Connection({
  x,
  y,
  onConnectionMoving,
  onConnectionRelease,
  recuadroId,
  segmentoId,
  activeConnections,
  remainingSegmentos,
  scalingFactor,
}: InputConnectionType) {
  const styles = {
    circle: {
      left: x - CONEXION.LEFT,
      top: y + CONEXION.TOP,
      backgroundColor: "yellow",
      width: 15,
      height: 15,
      borderRadius: 15,
      borderColor: "black",
      borderWidth: 1,
    },
  };

  const throttledOnConnectionMoving = throttle(onConnectionMoving, WAIT_MS, {
    leading: true,
    trailing: true,
  });

  const onEnd = ({ delta }) => {
    // The user has released all touches while this view is the
    // responder. This typically means a gesture has succeeded
    if (activeConnections === NO_ACTIVE_CONNECTION) {
      return;
    }
    onConnectionRelease({
      connectionId: activeConnections,
      recuadroId,
      segmentoId,
      remainingSegmentos,
      x0: x,
      y0: y,
      dx: delta[0],
      dy: delta[1],
      startX: x - CONEXION.RADIO,
      startY: y + CONEXION.RADIO,
      scalingFactor,
    });
  };

  const { bind } = useGestureResponder({
    onStartShouldSet: () => true,
    onRelease: onEnd,
    onMove: ({ delta }) => {
      if (activeConnections === NO_ACTIVE_CONNECTION) {
        return;
      }

      throttledOnConnectionMoving({
        action: "move",
        connectionId: activeConnections,
        recuadroId,
        segmentoId,
        remainingSegmentos,
        x0: x,
        y0: y,
        dx: delta[0],
        dy: delta[1],
        startX: x - CONEXION.RADIO,
        startY: y + CONEXION.RADIO,
        scalingFactor,
      });
    },
  });

  return (
    <GB9 {...bind}>
      <CircleB9
        cx={styles.circle.left}
        cy={styles.circle.top}
        r={7}
        stroke="black"
        strokeWidth={1}
        fill={styles.circle.backgroundColor}
      />
      <CircleB9
        fillOpacity={0}
        fill="red"
        r={30}
        cx={styles.circle.left}
        cy={styles.circle.top}
      />
    </GB9>
  );
}

export default Connection;
