import * as Speech from "expo-speech";
import _ from "lodash";
import { Platform } from "react-native";

import { isMacintosh } from "../../helpers/helpersFunctions";
import { Logger } from "../Logger";

const MAX_NUMBER_RETRIES = 10;
const SLEEP = 100;

async function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

let selectedVoice;

/**
 * Returns a voice compatible with the language requested
 * @param languages list of languages in order of preference
 */
export async function getVoiceForExpo(languages: string[]) {
  if (selectedVoice) {
    return selectedVoice;
  }
  let voices = await Speech.getAvailableVoicesAsync();
  let numberOfRetries = 0;

  while (_.isEmpty(voices) && numberOfRetries < MAX_NUMBER_RETRIES) {
    //chrome se demora en cargar la lista de voces disponibles, es necesario esperar y reintentar
    //https://stackoverflow.com/questions/49506716/speechsynthesis-getvoices-returns-empty-array-on-windows
    await sleep(SLEEP);
    voices = await Speech.getAvailableVoicesAsync();
    numberOfRetries++;
    Logger.info(`Got available voices after ${numberOfRetries} retries`);
  }

  if (_.isEmpty(voices)) {
    throw new Error("No voice available on platform");
  }

  const supportedLanguages = _.map(voices, "language");
  Logger.info("Available languages", supportedLanguages);
  Logger.info("Available voices", voices);
  for (let i = 0; i < languages.length; i++) {
    const match = _.chain(voices)
      .filter((voice) => {
        return voice.language.toUpperCase().includes(languages[i]);
      })
      .sortBy("quality")
      .reverse()
      .value();
    if (match[0]) {
      Logger.info("selected voice", match[0]);
      selectedVoice = {
        language: match[0].language,
        identifier: match[0].identifier,
      };
      return selectedVoice;
    }
  }
  throw new Error("No voices available");
}

export async function speechToText(text: string, options: object) {
  getVoiceForExpo(["ES-419", "ES-CL", "ES-MX", "ES-ES", "ES-US", "ES-", "ES"])
    .then((voice) => {
      console.log("TTS", text);
      Speech.speak(text, {
        ...options,
        language: voice.language,
        voice: voice.identifier,
        rate:
          Platform.OS === "web" && isMacintosh(window.navigator.userAgent)
            ? 0.87
            : 1,
      });
    })
    .catch(() => {
      Logger.error("No voices available for speechToText");
    });
}

export async function stop() {
  Speech.isSpeakingAsync().then(async (isSpeaking) => {
    if (isSpeaking) {
      await Speech.stop();
    }
  });
}
