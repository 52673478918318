import React from "react";

import BanderaChile from "./FlagsComponents/BanderaChile";
import BanderaParaguay from "./FlagsComponents/BanderaParaguay";
import BanderaPeru from "./FlagsComponents/BanderaPeru";

export default function getFlagComponent(pais: string) {
  switch (pais) {
    case "Chile":
      return <BanderaChile />;
    case "Perú":
      return <BanderaPeru />;
    case "Paraguay":
      return <BanderaParaguay />;
    default:
      return <BanderaChile />;
  }
}
