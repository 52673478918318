import ChangePass from "./ChangePass";
import { PerfilStackParamList } from "../../navigation/Routes";

interface BotonesType {
  id: number;
  title: string;
  name: keyof PerfilStackParamList;
  component: (props: any) => JSX.Element;
}

export const BotonesPerfil: BotonesType[] = [
  {
    id: 1,
    title: "Cambiar Contraseña",
    name: "ChangePass",
    component: ChangePass,
  },
];
