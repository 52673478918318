import { CognitoUserSession } from "amazon-cognito-identity-js";
import { createContext } from "react";

export const AuthenticationContext = createContext({
  //implementations for these functions are defined on App.js
  signIn: (data: CognitoUserSession) => {
    console.log("does nothing: ", data);
  },
  signUp: () => {
    //does nothing
  },
  signOut: async () => {
    //does nothing
  },
});
