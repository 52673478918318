import * as FileSystem from "expo-file-system";
import { Platform } from "react-native";

import { HTTP_SUCCESS } from "../../constants/numbers";
import { Sentry } from "../../lib/Sentry";
import { getExerciseLocalPath } from "../ExerciseStorage";
import { Logger } from "../Logger";

const WAIT_TO_RETRY_MS = 5000;

class ConectaStorage {
  async getExercise(
    exerciseId: number,
    tareaAsignadaId: number,
    updatedAt: string,
    fullUrl: string
  ) {
    if (!(exerciseId && tareaAsignadaId && updatedAt && fullUrl)) {
      throw new Error(
        `Missing parameter E${exerciseId} TA${tareaAsignadaId} UT${updatedAt} FU${fullUrl}`
      );
    }
    if (Platform.OS === "web") {
      return fetch(fullUrl, {
        headers: {
          Accept: "application/json",
          AcceptEncoding: "gzip",
        },
      })
        .then((response) => {
          if (response.status === HTTP_SUCCESS) {
            return response.json();
          } else {
            throw new Error("Failed to download " + fullUrl);
          }
        })
        .catch((error) => {
          Logger.error("Error downloading " + fullUrl, error.message);
          Sentry.captureException(error);
          throw error;
        });
    } else {
      const localPath = getExerciseLocalPath(exerciseId, tareaAsignadaId);
      Logger.info("localPath for exercise " + localPath);
      const metadata = await FileSystem.getInfoAsync(localPath);

      const platform = Platform.OS as string;
      if (metadata.exists) {
        Logger.info("file exists in local storage", localPath);
        try {
          const data = await FileSystem.readAsStringAsync(localPath);
          const o = JSON.parse(data);
          Logger.info("success, returning data from local storage");
          return o;
        } catch (e) {
          if (platform !== "web") {
            FileSystem.getFreeDiskStorageAsync().then((freeDiskStorage) => {
              Logger.info(
                "Error parseando archivo" +
                  localPath +
                  ", tratando de descargar nuevamente. StorageAvailable " +
                  freeDiskStorage +
                  " " +
                  platform,
                e
              );
            });
          } else {
            Logger.info(
              "Error parseando archivo, tratando de descargar nuevamente " +
                platform,
              e
            );
          }

          try {
            await FileSystem.deleteAsync(localPath);
          } catch (ignored) {
            //Archivo no encontrado, ignora error
          }
        }
      } else {
        Logger.info("file doesn't exist, downloading " + fullUrl);
      }
      //if data doesn't exist try to fetch it
      const status = await this.cacheExercise(
        exerciseId,
        tareaAsignadaId,
        fullUrl,
        metadata.exists
      );
      if (status !== HTTP_SUCCESS) {
        throw new Error(
          "Error descargando archivo. Status " + status + " : " + fullUrl
        );
      }
      const data = await FileSystem.readAsStringAsync(localPath);
      return JSON.parse(data);
    }
  }

  async sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async cacheExercise(exerciseId, tareaAsignadaId, fullUrl, exists) {
    const localPath = getExerciseLocalPath(exerciseId, tareaAsignadaId);
    if (exists) {
      try {
        await FileSystem.deleteAsync(localPath);
      } catch (ignored) {
        //Archivo no encontrado, ignora error
      }
    }
    Logger.info("downloading", fullUrl, exerciseId);
    let response = await FileSystem.downloadAsync(fullUrl, localPath);
    if (response.status === HTTP_SUCCESS) {
      Logger.info(`downloaded ${exerciseId} into ${localPath}`);
    } else {
      Logger.info("Retrying one more time", exerciseId);
      await this.sleep(WAIT_TO_RETRY_MS);
      response = await FileSystem.downloadAsync(fullUrl, localPath);
    }
    if (response.status !== HTTP_SUCCESS) {
      if (Platform.OS === "web") {
        Logger.error("Error en descarga de archivo", response.status);
      } else {
        FileSystem.getFreeDiskStorageAsync().then((freeDiskStorage) => {
          Logger.error(
            "Error en descarga de archivo. Storage available: " +
              freeDiskStorage
          );
        });
      }
    }

    return response.status;
  }

  async getSizeInDisc(exerciseId: number, tareaAsignadaId: number) {
    if (Platform.OS !== "web") {
      const localPath = getExerciseLocalPath(exerciseId, tareaAsignadaId);
      try {
        Logger.info("Size file from cache", localPath);
        return await FileSystem.getInfoAsync(localPath, { size: true }).then(
          (output) => {
            if (output.exists) {
              return { size: output.size };
            } else {
              return { size: 0 };
            }
          }
        );
      } catch (e) {
        Logger.captureException(`Error`, e);
        return { size: 0 };
      }
    }
    return { size: 0 };
  }
}

export default ConectaStorage;
