import { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { THRESHOLD_SMALL_SCREEN } from "../constants/Layout";

export interface ScreenInfoType {
  loading: boolean;
  isHorizontal: boolean;
  isMobile: boolean;
  windowWidth: number;
  windowHeight: number;
  insets: { left: number; right: number; top: number; bottom: number };
}

export default function useScreenInfo(): ScreenInfoType {
  const initialStatus = {
    loading: true,
    isHorizontal: true,
    isMobile: true,
    windowWidth: -1,
    windowHeight: -1,
    insets: { left: 0, right: 0, top: 0, bottom: 0 },
  };
  const [status, setStatus] = useState(initialStatus);
  const window = useWindowDimensions();
  const insets = useSafeAreaInsets();

  useEffect(() => {
    setStatus({
      loading: false,
      windowWidth: Math.floor(window.width - insets.left - insets.right),
      windowHeight: Math.floor(window.height - insets.top - insets.bottom),
      isMobile:
        window.width < THRESHOLD_SMALL_SCREEN ||
        window.height < THRESHOLD_SMALL_SCREEN,
      isHorizontal: window.width > window.height,
      insets,
    });
  }, [window, insets]);

  return status;
}
