import { Dimensions, Platform } from "react-native";

const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height;

export const THRESHOLD_SMALL_SCREEN = 500;

const isSmallScreen =
  width < THRESHOLD_SMALL_SCREEN || height < THRESHOLD_SMALL_SCREEN;

const HEIGHT = {
  WEB: 60,
  SMALL_IOS: 100,
  OTHER: 80,
};

function computeNavigtatorHeight() {
  if (Platform.OS === "web") {
    return HEIGHT.WEB;
  } else if (Platform.OS === "ios" && isSmallScreen) {
    return HEIGHT.SMALL_IOS;
  } else {
    return HEIGHT.OTHER;
  }
}

export const headerNavigatorHeight = computeNavigtatorHeight();

export default {
  isSmallScreen,
};
