import { DrawerActions } from "@react-navigation/native";
import _ from "lodash";
import React, { useContext } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

import { ConectaIdeasContext } from "./ConectaIdeas";
import Notificaciones from "./NotificacionesComponents/Notificaciones";

const marginRight = 28;

export default function TopRightButton({ navigation }) {
  const { preguntasIndex } = useContext(ConectaIdeasContext);

  const toggleDrawer = () => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  };

  const numPreguntas = _.filter(preguntasIndex, (p) => {
    return !p.completed;
  }).length;

  const showBadge = numPreguntas > 0;

  return (
    <TouchableOpacity
      onPress={toggleDrawer}
      style={styles.pressable}
      data-test="menuButton"
      testID="icon_menu"
    >
      <View style={{ width: "70%" }}>
        <Icon size={25} name="ellipsis-h" color="black" testID="icon_menu" />
        {showBadge && <Notificaciones numero={numPreguntas} />}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  pressable: {
    backgroundColor: "rgba(255,255,255,0)",
    width: 90,
    height: 60,
    top: 0,
    right: 0,
    position: "absolute",
    paddingHorizontal: marginRight,
    justifyContent: "center",
    alignContent: "center",
  },
});
