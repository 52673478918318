import _ from "lodash";

const HALF = 2;
const FONT_SIZE_ADJUSTMENT = 1.1;
const MARGIN = 4;
const DIFERENCIA = 3;

export const getProperties = (props) => {
  const fontSize =
    props.fontAnswerSize < props.fontTextSize
      ? props.fontTextSize
      : props.fontAnswerSize;

  const inputSize =
    props.styles.box.height / (fontSize * FONT_SIZE_ADJUSTMENT) < DIFERENCIA &&
    !props.hasText
      ? props.styles.box.height - MARGIN * HALF
      : fontSize * FONT_SIZE_ADJUSTMENT;

  const dimensions = {
    y: props.styles.box.top + props.styles.box.height - inputSize - MARGIN,
    x: props.styles.box.left + MARGIN,
    width: props.styles.box.width - MARGIN * HALF,
    height: inputSize,
  };

  const textCoors = {
    x: dimensions.x + dimensions.width / HALF,
    y: dimensions.y + dimensions.height - (dimensions.height - fontSize) / HALF,
  };

  return { dimensions, fontSize, textCoors };
};

export function answerIsANumber(frase?: { $: string }) {
  if (frase) {
    const containLetters = /[a-zA-Z]/.test(frase.$);
    return (
      _.isFinite(parseInt(frase.$, 10)) &&
      !_.includes(frase.$, " ") &&
      !_.includes(frase.$, ".") &&
      !_.includes(frase.$, ",") &&
      !_.includes(frase.$, "/") &&
      !containLetters
    );
  }
  return false;
}
