import React from "react";
import { TouchableOpacity, ViewStyle, StyleProp } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

export default function BackArrow({
  styles,
  goToTareasList,
}: {
  styles: StyleProp<ViewStyle>;
  goToTareasList: () => void;
}) {
  return (
    <TouchableOpacity
      style={styles}
      testID="icon_arrow"
      onPress={goToTareasList}
    >
      <Icon size={25} name="arrow-left" color="green" />
    </TouchableOpacity>
  );
}
