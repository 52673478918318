import { useIsFocused } from "@react-navigation/core";
import { useEffect, useRef, useState } from "react";
import { AppState } from "react-native";

import { Logger } from "../components/Logger";

export default function useIsActiveExercise() {
  const isFocused = useIsFocused();
  const [active, setIsActive] = useState(
    isFocused && AppState.currentState === "active"
  );
  const exerciseState = useRef(AppState.currentState);

  useEffect(() => {
    const handleChange = (nextAppState: string) => {
      const inactive =
        nextAppState === "inactive" || nextAppState === "background";
      Logger.info(
        `exerciseState ${nextAppState} active? ${isFocused && !inactive}`
      );
      setIsActive(isFocused && !inactive);
    };

    const subscription = AppState.addEventListener("change", handleChange);

    return () => {
      try {
        subscription.remove();
      } catch (e) {
        Logger.captureException("error removing app status listener", e);
      }
    };
  }, [exerciseState.current, isFocused]);

  return active;
}
