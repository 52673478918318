import { Auth } from "@aws-amplify/auth";
import _ from "lodash";
import React, { useState } from "react";
import {
  Platform,
  Text,
  View,
  ActivityIndicator,
  TextInput,
} from "react-native";
import { ListItem } from "react-native-elements";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome5";

import { BotonesPerfil } from "./PerfilScreens/BotonesPerfil";
import ModalAuthError from "../components/AuthComponents/ModalAuthError";
import { getStorageInfo } from "../components/functions/perfilFunctions";
import { modalBlueStyles as stylesButton } from "../constants/ModalStyles";
import { textStyles, listStyles, perfilStyles } from "../constants/NewStyles";
import { PerfilProps } from "../navigation/Routes";

function EmailEditor({ closeEditor, user, setUser, showError }) {
  const [correo, setCorreo] = useState("");
  const onChangeEmail = async () => {
    Auth.updateUserAttributes(user, {
      email: correo,
    })
      .then(() => {
        const updatedUser = _.cloneDeep(user);
        updatedUser.attributes.email = correo;
        setUser(updatedUser);
        closeEditor();
      })
      .catch(() => {
        showError();
      });
  };

  return (
    <>
      <TextInput
        style={{
          borderWidth: 1,
          borderColor: "rgb(32,96,234)",
          borderRadius: 3,
        }}
        autoCorrect={false}
        autoCapitalize="none"
        autoFocus
        enablesReturnKeyAutomatically
        onChangeText={(text) => setCorreo(text)}
        placeholder=" Ingresa tu email"
        disableFullscreenUI
        testID="email_input"
      />
      <View style={{ height: 25, marginLeft: 5, flexDirection: "row" }}>
        <TouchableOpacity
          style={[stylesButton.button, { marginTop: 0 }]}
          onPress={onChangeEmail}
        >
          <Text style={stylesButton.textButton} allowFontScaling={false}>
            Guardar
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[stylesButton.button, { marginTop: 0, marginLeft: 5 }]}
          onPress={closeEditor}
        >
          <Text style={stylesButton.textButton} allowFontScaling={false}>
            Cancelar
          </Text>
        </TouchableOpacity>
      </View>
    </>
  );
}

export default function PerfilScreen({ navigation }: PerfilProps) {
  const [user, setUser] = useState(null);
  const [almacenamiento, setAlmacenamiento] = useState(null);
  const [showEmailEditor, setShowEmailEditor] = useState(false);
  const [error, setError] = useState(false);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then((currentAuth) => {
      if (currentAuth) {
        setUser(currentAuth);
        setShowEmailEditor(!currentAuth.attributes.email);
      }
    });

    getStorageInfo().then((almacen) => {
      setAlmacenamiento(almacen);
    });
  }, []);

  return (
    <ScrollView style={perfilStyles.containerVertical}>
      {user ? (
        <>
          <View style={perfilStyles.containerInfoBorder}>
            <Text style={textStyles.advice}>
              Hola {user.attributes.name} {user.attributes.family_name}!{"\n"}
            </Text>
            <Text style={textStyles.subTextInfo}>
              Institución: {user.attributes["custom:institucion"]}
            </Text>
            <Text style={textStyles.subTextInfo}>
              País: {user.attributes["custom:pais"]}
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={textStyles.subTextInfo}>Email:</Text>
              {showEmailEditor ? (
                <EmailEditor
                  closeEditor={() => setShowEmailEditor(false)}
                  user={user}
                  setUser={setUser}
                  showError={() => setError(true)}
                />
              ) : (
                <>
                  <Text style={textStyles.subTextInfo}>
                    {user.attributes.email + " "}
                  </Text>
                  <Icon
                    size={18}
                    color="rgb(32,96,234)"
                    name="edit"
                    onPress={() => setShowEmailEditor(true)}
                  />
                </>
              )}
            </View>
          </View>
          {almacenamiento && Platform.OS !== "web" && (
            <View style={perfilStyles.containerInfoBorder}>
              <Text style={textStyles.advice}>Almacenamiento{"\n"}</Text>
              <Text style={textStyles.subTextInfo}>
                Ejercicios Descargados: {String(almacenamiento.numExercises)}
              </Text>

              <Text style={textStyles.subTextInfo}>
                Espacio Utilizado: {String(almacenamiento.space)}
              </Text>
            </View>
          )}
          <View style={listStyles.containerButtons}>
            {BotonesPerfil.map((boton) => {
              return (
                <ListItem
                  key={boton.id}
                  onPress={() => {
                    navigation.navigate(boton.name);
                  }}
                  disabled={!user.email_verified}
                >
                  <ListItem.Content style={perfilStyles.buttonList}>
                    <ListItem.Title>{boton.title}</ListItem.Title>
                  </ListItem.Content>
                </ListItem>
              );
            })}
          </View>
          {error && (
            <ModalAuthError
              onPress={() => setError(false)}
              mensaje="No fue posible modificar tu correo"
            />
          )}
        </>
      ) : (
        <ActivityIndicator />
      )}
    </ScrollView>
  );
}
