import { ENV, isDEV } from "../constants/dev";
import { Sentry } from "../lib/Sentry";

const loggerLevel: any = {
  //Severity | SeverityLevel no encontre los type
  DEBUG: {
    name: "DEBUG",
    color: "blue",
    sentry: "debug",
  },
  INFO: {
    name: "INFO",
    color: "orange",
    sentry: "info",
  },
  ERROR: {
    name: "ERROR",
    color: "red",
    sentry: "error",
  },
};

function serialize(initialValue, current) {
  return initialValue + " " + JSON.stringify(current);
}

function serializeMessage(...params) {
  const leftParam = params[0];
  const rightParams = params.slice(1);
  return rightParams.reduce(serialize, leftParam);
}

export const Logger = {
  debug(...message) {
    this.report(loggerLevel.DEBUG, ...message);
  },

  info(...message) {
    this.report(loggerLevel.INFO, ...message);
  },

  error(...message) {
    this.report(loggerLevel.ERROR, ...message);
  },

  captureException(message, exception) {
    // eslint-disable-next-line no-console
    console.error(message, exception);
    try {
      Sentry.addBreadcrumb({
        category: "ui",
        message,
        level: loggerLevel.ERROR.sentry,
      });
      Sentry.captureException(exception);
    } catch (ignored) {
      //ignore sentry errors
    }
  },

  report(loggerLevel, ...message) {
    if (loggerLevel === loggerLevel.ERROR || isDEV || ENV === "beta") {
      // eslint-disable-next-line no-console
      console.log(
        "%c%s",
        `color:${loggerLevel.color}`,
        loggerLevel.name,
        ...message
      );
    }
    try {
      if (loggerLevel === loggerLevel.ERROR && !isDEV) {
        Sentry.captureMessage(serializeMessage(message));
      }

      if (!isDEV) {
        Sentry.addBreadcrumb({
          category: "ui",
          message: serializeMessage(message),
          level: loggerLevel.sentry,
        });
      }
    } catch (ignored) {
      //ignore sentry errors
    }
  },
};
