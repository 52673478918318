import _ from "lodash";
import React from "react";
import { G, Line } from "react-native-svg";

import { AnswerStatus } from "./AnswerFeedback/Draw";
import Connection from "../Recuadro/Connection";
import { ConnectionType } from "../Recuadro/RectInterfaces";
import { getRemainingSegments } from "../functions/exerciseFunctions";

const RADIO = 7;

function color(status: string) {
  switch (status) {
    case AnswerStatus.RIGHT:
      return "green";
    case AnswerStatus.WRONG:
      return "red";
    default:
      return "yellow";
  }
}

interface ConnectionRendererInputType {
  connections: ConnectionType;
  onConnectionMoving: (event) => void;
  onConnectionRelease: (event) => void;
  scalingFactor: number;
}

export default function ConnectionRenderer({
  connections,
  onConnectionMoving,
  onConnectionRelease,
  scalingFactor,
}: ConnectionRendererInputType) {
  if (_.isEmpty(connections)) {
    return null;
  }
  const gArray = _.map(connections, (value, key) => {
    const hasSegments =
      value.remainingSegmentos.length > 0 &&
      value.status === AnswerStatus.RIGHT &&
      value.locked;

    return (
      <G key={`cr${key}`}>
        <Line
          strokeWidth={7}
          stroke="black"
          x1={value.x1}
          y1={value.y1}
          x2={value.x2}
          y2={value.y2}
        />
        <Line
          strokeWidth={5}
          stroke={color(value.status)}
          x1={value.x1}
          y1={value.y1}
          x2={value.x2}
          y2={value.y2}
        />

        {hasSegments && (
          <Connection
            x={value.x2 + RADIO}
            y={value.y2 - RADIO}
            onConnectionMoving={onConnectionMoving}
            onConnectionRelease={onConnectionRelease}
            recuadroId={String(value.recuadroId)}
            segmentoId={value.remainingSegmentos[0]["@id"]}
            remainingSegmentos={getRemainingSegments(value.remainingSegmentos)}
            activeConnections="0"
            scalingFactor={scalingFactor}
          />
        )}
      </G>
    );
  });
  return <>{gArray}</>;
}
