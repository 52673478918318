import _ from "lodash";
import React from "react";
import { View, Text, Platform, StyleSheet } from "react-native";
import { G, ForeignObject } from "react-native-svg";

import BoxSpeech from "./BoxSpeech";
import ConnectionCounter from "./ConnectionCounter";
import { RecuadroInputType } from "./RectInterfaces";
import RenderTextInput from "./RenderTextInput";
import { AnswerStatus } from "../ExerciseComponents/AnswerFeedback/Draw";
import { RectB9 } from "../ExerciseComponents/SvgB9";
import { getColor } from "../ParseUtils";
import { isWindow } from "../functions/exerciseFunctions";

const OPACITY = 0.8;
const AJUSTE = {
  FONTSIZE_IOS: 2,
  SCALE_FACTOR: 22,
  PLAY_BUTTON: 28,
  FONTSIZE_WIN: 5,
};
const PROMEDIO = 2;

const getAjuste = () => {
  if (Platform.OS === "ios") {
    return AJUSTE.FONTSIZE_IOS;
  }
  if (isWindow()) {
    return AJUSTE.FONTSIZE_WIN;
  }
  return 0.5;
};

export default function Recuadro(props: RecuadroInputType) {
  const hasConnections = props.num_cnx.$ !== "0";
  const hasTextInput = Object.entries(props.frase).length !== 0;
  const x = parseInt(props.x["$"], 10);
  const y = parseInt(props.y["$"], 10);
  const w = parseInt(props.w["$"], 10);
  const h = parseInt(props.h["$"], 10);

  const color_fondo = getColor(props.prop.clr_fnd);
  const border_Color = getColor(props.prop.clr_brd);

  const border_Width = parseInt(props.prop.grosor_brd["$"], 10);
  const fondoTransparente = props.prop.fnd_transp["$"] !== "0";

  const text = props.txt["$"] !== "Texto" ? props.txt["$"] : "";

  const hasText = text !== undefined && text !== "";
  const mostrarBordeText = parseInt(props.prop.mostrar_brd_txt.$, 10);
  const colorText = getColor(props.prop.clr_txt);
  const colorBordeText = getColor(props.prop.clr_brd_Txt);

  const font_Weight = props.prop.fnt_txt.bold["$"] === "1" ? "bold" : "normal";
  const font_Style =
    props.prop.fnt_txt.italic["$"] === "0" ? "normal" : "italic";
  const font_Size = parseInt(props.prop.fnt_txt.size["$"], 10);

  const font_answer_size = parseInt(props.prop.fnt_frs.size["$"], 10);
  const font_answer_weight =
    props.prop.fnt_frs.bold["$"] === "1" ? "bold" : "normal";

  const textIsNumber = _.isFinite(parseInt(text, 10));
  const isPlayButtonVisible =
    Object.entries(props.txt).length !== 0 && text !== "" && !textIsNumber;

  const scalingToText = Platform.OS === "ios" ? props.scalingFactor : 1;

  const styles = StyleSheet.create({
    box: {
      top: y,
      left: x,
      width: w,
      height: h,
      backgroundColor: color_fondo,
      borderWidth: border_Width,
      borderColor: border_Color,
      opacity: fondoTransparente ? OPACITY : 1,
    },
    text: {
      color: colorText,
      fontSize: font_Size * scalingToText - getAjuste(),
      fontWeight: font_Weight,
      fontStyle: font_Style,
      paddingRight: 2,
      textShadowColor: colorBordeText,
      textShadowOffset: {
        width: mostrarBordeText,
        height: mostrarBordeText,
      },
      textShadowRadius: mostrarBordeText,
    },
  });

  const scaleFactor =
    font_Size < AJUSTE.SCALE_FACTOR ? font_Size / AJUSTE.SCALE_FACTOR : 1;

  const typeReviewAnswer =
    props.prop.det_let.$ === "0" ? "phraseReview" : "letterReview";

  const userTextAnswer = props.userAnswer?.answer;
  const userAnswerStatus = props.userAnswer?.status || AnswerStatus.UNKNOWN;

  const RenderText = ({ left, top }) => {
    return (
      <View
        style={{
          left: scalingToText * left,
          top: scalingToText * top,
          width:
            scalingToText *
            (isPlayButtonVisible && (hasConnections || hasTextInput)
              ? styles.box.width - AJUSTE.PLAY_BUTTON * scaleFactor
              : styles.box.width),
          height: scalingToText * styles.box.height,
        }}
      >
        <Text
          style={styles.text}
          selectable={false}
          allowFontScaling={false}
          adjustsFontSizeToFit
        >
          {text}
        </Text>
      </View>
    );
  };

  return (
    <G>
      <RectB9
        x={styles.box.left}
        y={styles.box.top}
        rx={3}
        ry={3}
        width={styles.box.width}
        height={styles.box.height}
        stroke={styles.box.borderColor}
        strokeWidth={styles.box.borderWidth}
        fill={styles.box.backgroundColor}
      />
      {Platform.OS === "ios" ? (
        <RenderText
          left={styles.box.left + styles.box.borderWidth / PROMEDIO}
          top={styles.box.top + styles.box.borderWidth / PROMEDIO}
        />
      ) : (
        <ForeignObject
          x={styles.box.left + styles.box.borderWidth / PROMEDIO}
          y={styles.box.top + styles.box.borderWidth / PROMEDIO}
          width={styles.box.width}
          height={styles.box.height}
        >
          <RenderText left={0} top={0} />
        </ForeignObject>
      )}

      {isPlayButtonVisible ? (
        <BoxSpeech
          box={styles.box}
          text={text}
          fontSize={font_Size}
          hasConnections={hasConnections || hasTextInput}
        />
      ) : null}

      <RenderTextInput
        id={props["@id"]}
        frase={props.frase}
        fontAnswerSize={font_answer_size}
        fontTextSize={font_Size}
        fontAnswerWeight={font_answer_weight}
        typeReviewAnswer={typeReviewAnswer}
        text={text}
        userAnswer={userTextAnswer}
        userAnswerStatus={userAnswerStatus}
        isPlayButtonVisible={isPlayButtonVisible}
        styles={styles}
        onInputOnFocus={props.onInputOnFocus}
        hasText={hasText}
      />
      {hasConnections && (
        <ConnectionCounter
          recuadroId={props["@id"]}
          conexiones={props.conexiones}
          onConnectionMoving={props.onConnectionMoving}
          onConnectionRelease={props.onConnectionRelease}
          scalingFactor={props.scalingFactor}
          userConnections={props.userConnections}
        />
      )}
    </G>
  );
}
