import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";
import moment from "moment";

import {
  PendingUploadCollectionType,
  ExerciseWithStatus,
  TareaWithStatus,
} from "../Interfaces";
import { USER_INFO_PREFIX } from "../components/Utils";

const MAX_STORED_DAYS_DELIVERED_EXERCISE = 2;

export function listAvailableExercises(tareasIndex) {
  return _.chain(tareasIndex)
    .map((tarea) => {
      const filteredEjercicios = _.filter(tarea.ejercicios, {
        completed: false,
        availableOffline: true,
      });
      return { ...tarea, ejercicios: filteredEjercicios };
    })
    .filter((tarea) => {
      return !_.isEmpty(tarea.ejercicios);
    })
    .value();
}

export function listAvailableHomework(
  tareasIndex: TareaWithStatus[]
): TareaWithStatus[] {
  const now = new Date();

  return _.chain(tareasIndex)
    .map((tarea) => {
      const filteredEjercicios: ExerciseWithStatus[] = _.filter(
        tarea.ejercicios,
        (e) => {
          return e.user.completed === false && e.availableOffline === true;
        }
      );
      return { ...tarea, ejercicios: filteredEjercicios };
    })
    .filter((tarea) => {
      const limitsTime = {
        end: new Date(tarea.end_time_utc),
        start: new Date(tarea.start_time_utc),
      };

      return (
        !_.isEmpty(tarea.ejercicios) &&
        limitsTime.end > now &&
        now > limitsTime.start
      );
    })
    .value();
}

export function listPendingDownloadExercises(tareasIndex: TareaWithStatus[]) {
  return _.chain(tareasIndex)
    .flatMap((t) => {
      return t.ejercicios;
    })
    .filter((e) => {
      return e.availableOffline === false && e.user.completed === false;
    })
    .value();
}

export async function listPendingUploadExercises(): Promise<PendingUploadCollectionType> {
  return AsyncStorage.getAllKeys()
    .then((keys) => {
      return _.filter(keys, (key) => {
        return key.startsWith(USER_INFO_PREFIX);
      });
    })
    .then((keys) => {
      return AsyncStorage.multiGet(keys);
    })
    .then((data) => {
      return _.chain(data)
        .map((kvp) => {
          const key = kvp[0];
          const value = JSON.parse(kvp[1]);
          return [key, value];
        })
        .fromPairs()
        .value();
    })
    .then((data) => {
      if (data) {
        return [
          _.pickBy(data, (e) => {
            return !e.delivered;
          }),
          _.pickBy(data, (e) => {
            return e.delivered;
          }),
        ];
      } else {
        return [{}, {}];
      }
    })
    .then(([pending, delivered]) => {
      const deliveredToRemove = _.pickBy(delivered, (d) => {
        const timeStored = moment().diff(d.delivered, "days");
        return timeStored >= MAX_STORED_DAYS_DELIVERED_EXERCISE;
      });
      AsyncStorage.multiRemove(_.keys(deliveredToRemove));
      return pending;
    });
}

export function getListAvailableExercises(ejercicios, isOnline) {
  if (isOnline) {
    return _.filter(ejercicios, {
      completed: false,
    });
  } else {
    return _.filter(ejercicios, {
      completed: false,
      availableOffline: true,
    });
  }
}
