import moment from "moment";

import { FlagsInfoType, FlagsResume } from "../../Interfaces";

export const getFlagsResume = (flagsInfo: FlagsInfoType): FlagsResume => {
  const thisYear = "Y" + moment().year().toString();
  const thisWeek = "W" + moment().format("YYYY#WW").toString();
  const thisMonth = "M" + moment().format("YYYY#MM").toString();

  const flagsYear = flagsInfo.flagsYear[thisYear] || 0;
  const flagsMonth = flagsInfo.flagsMonth[thisMonth] || 0;
  const flagsWeek = flagsInfo.flagsWeek[thisWeek] || 0;

  return { year: flagsYear, month: flagsMonth, week: flagsWeek };
};
