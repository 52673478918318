import _ from "lodash";

import { Logger } from "../Logger";
import { getPropertyAsArray } from "../ParseUtils";
import { RecuadrosType, ZonasType } from "../Recuadro/RectInterfaces";

class ConnectionValidator {
  data: object;
  zonas: object;
  constructor(recuadros: RecuadrosType[], zonas: ZonasType[]) {
    this.data = {};
    this.zonas = {};
    _.each(zonas, (zona) => {
      const zonaId = zona["id"]["$"];
      this.zonas[zonaId] = {
        x: parseInt(zona.x.$, 10),
        y: parseInt(zona.y.$, 10),
        w: parseInt(zona.w.$, 10),
        h: parseInt(zona.h.$, 10),
      };
    });

    _.each(recuadros, (recuadro) => {
      const recuadroId = recuadro["@id"];
      const conexiones = getPropertyAsArray(recuadro.conexiones, "conexion");
      _.each(conexiones, (conexion) => {
        const conexionId = conexion["@id"];
        const segmentos = getPropertyAsArray(conexion.segmentos, "segmento");

        if (_.isUndefined(this.data[recuadroId])) {
          this.data[recuadroId] = {};
        }

        this.data[recuadroId][conexionId] = segmentos;
      });
    });
  }

  validateConnection(
    recuadroId,
    conexionId,
    segmentoId,
    coords,
    zonesAlreadyTaken
  ) {
    const conexiones = this.data[String(recuadroId)];
    const validZonesId = _.map(conexiones, (v) => {
      const connection = _.filter(v, { "@id": segmentoId });
      return connection[0].id.$;
    });

    for (const zoneId of validZonesId) {
      const zone = this.zonas[zoneId];
      const bbox = {
        x1: zone.x,
        y1: zone.y,
        x2: zone.x + zone.w,
        y2: zone.y + zone.h,
      };

      if (
        coords.x2 >= bbox.x1 &&
        coords.x2 <= bbox.x2 &&
        coords.y2 >= bbox.y1 &&
        coords.y2 <= bbox.y2
      ) {
        if (zonesAlreadyTaken.includes(`rec${recuadroId}-zone${zoneId}`)) {
          Logger.info("zone already choosen, skipping", zoneId);
        } else {
          return `rec${recuadroId}-zone${zoneId}`;
        }
      }
    }
    return false;
  }
}

export default ConnectionValidator;
