import { gql } from "@apollo/client";

export const saveToken = gql`
  mutation saveToken($token: String, $platform: String, $version: String!) {
    saveToken(token: $token, platform: $platform, version: $version)
  }
`;

export const deleteToken = `
  mutation deleteToken($token: String,$version:String!) {
    deleteToken(token: $token,version:$version)
  }
`;

export const uploadEvaluacion = `
  mutation uploadEvaluacion($evaluacion: EvaluacionInput!,$version:String!) {
    uploadEvaluacion(evaluacion: $evaluacion,version:$version)
  }
`;

export const reportMessageMutation = `
  mutation reportMessage($messageId: String!, $token: String,$version:String!) {
    reportMessage(messageId: $messageId, token: $token,version:$version)
  }
`;
