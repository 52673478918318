import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/core";
import _ from "lodash";
import React, { useState } from "react";
import { Text, TextInput, View, TouchableOpacity } from "react-native";
import { CheckBox } from "react-native-elements";

import paises from "../paises/paises.json";

export default function TestScreen() {
  const [exerciseKey, setExerciseKey] = useState("");
  const [pais, setPais] = useState("");
  const navigation = useNavigation<any>(); //TODO any temporal

  const onChangeValue = (value) => {
    AsyncStorage.getItem("userAttributes").then((data) => {
      const newData = { ...JSON.parse(data), "custom:pais": value };
      AsyncStorage.setItem("userAttributes", JSON.stringify(newData));
    });
    setPais(value);
  };

  const goToExercise = () => {
    navigation.navigate("Ejercicio", {
      tareaAsignadaId: 1337,
      exerciseId: exerciseKey,
      position: 1,
    });
  };

  return (
    <>
      <Text style={{ fontWeight: "bold", fontSize: 20, paddingBottom: 10 }}>
        Test: Carga ejercicio por KEY
      </Text>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Text style={{ width: 110 }}>Selecciona Pais:</Text>
        <View style={{ flexDirection: "column" }}>
          {_.map(paises, (p) => {
            return (
              <CheckBox
                key={p.id}
                title={p.name}
                checked={pais === p.name}
                onPress={() => onChangeValue(p.name)}
              />
            );
          })}
        </View>
      </View>

      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Text style={{ width: 110 }}>Ejercicio key:</Text>
        <TextInput
          style={{
            borderColor: "blue",
            borderWidth: 1,
            height: 30,
            width: 200,
          }}
          value={exerciseKey}
          onChangeText={(text) => setExerciseKey(text)}
        />
        <TouchableOpacity
          style={{ height: 30, backgroundColor: "blue" }}
          onPress={goToExercise}
        >
          <Text style={{ color: "white" }}>GO</Text>
        </TouchableOpacity>
      </View>
    </>
  );
}
