import { ApolloQueryResult, useMutation } from "@apollo/client";
import { Subscription } from "expo-modules-core";
import * as Notifications from "expo-notifications";
import React, { Dispatch, createContext, useEffect, useRef } from "react";

import { Logger } from "./Logger";
import {
  processInteraction,
  processNotification,
} from "./NotificacionesComponents/NotificationHandler";
import { registerForPushNotificationsAsync } from "./NotificacionesComponents/pushUtils";
import { AnnouncementType } from "./dataTypes";
import { ExerciseIndexState } from "../Interfaces";
import { saveToken } from "../graphql/mutation";
import { ExerciseIndexAction } from "../hooks/reducerIntefaces";
import useExerciseIndex, {
  INDEX_INITIAL_STATUS,
} from "../hooks/useExerciseIndex";
import {
  MetaAnswerType,
  MetacognitivasWithStatus,
} from "../metacognitivas/MetaInterfaces";

interface DefaultValueType {
  exerciseIndex: ExerciseIndexState;
  getExercise: (exerciseId: number, tareaAsignadaId: number) => void;
  refreshIndex: () => Promise<ApolloQueryResult<any>>;
  exerciseIndexDispatch: Dispatch<ExerciseIndexAction>;
  preguntasIndex: MetacognitivasWithStatus[];
  refreshPreguntasIndex: () => void;
  saveAnswer: (
    preguntaId: number,
    answer: MetaAnswerType,
    completed: boolean
  ) => Promise<boolean>;
  announcementData: AnnouncementType;
  ready: boolean;
  isOnline: boolean;
}

const defaultValue: DefaultValueType = {
  exerciseIndex: INDEX_INITIAL_STATUS,
  getExercise: undefined,
  refreshIndex: undefined,
  exerciseIndexDispatch: undefined,
  preguntasIndex: [],
  refreshPreguntasIndex: undefined,
  saveAnswer: undefined,
  announcementData: undefined,
  ready: false,
  isOnline: false,
};
const ConectaIdeasContext = createContext(defaultValue);

Notifications.setNotificationHandler({
  handleNotification: async () => {
    return {
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    };
  },
});
const ConectaIdeasProvider = (props) => {
  const [mutationSaveToken] = useMutation(saveToken);

  const notificationListener = useRef<Subscription>();
  const responseListener = useRef<Subscription>();

  const {
    exerciseIndex,
    refreshIndex,
    getExercise,
    exerciseIndexDispatch,
    preguntasIndex,
    refreshPreguntasIndex,
    saveAnswer,
    announcementData,
    setAnnouncementData,
    ready,
    isOnline,
  } = useExerciseIndex();

  useEffect(() => {
    registerForPushNotificationsAsync(mutationSaveToken);

    //This listener is fired whenever a notification is received while the app is foregrounded.
    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        Logger.info("notification", JSON.stringify(notification));
        processNotification(notification, setAnnouncementData, refreshIndex);
      });

    //This listener is fired whenever a user taps on or interacts with a notification (works when an app is foregrounded, backgrounded, or killed).
    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        Logger.info("interacted with notification", JSON.stringify(response));
        processInteraction(response, setAnnouncementData, refreshIndex);
      });

    return () => {
      if (notificationListener.current) {
        Notifications.removeNotificationSubscription(
          notificationListener.current
        );
      }
      if (responseListener.current) {
        Notifications.removeNotificationSubscription(responseListener.current);
      }
    };
  }, []);

  return (
    <ConectaIdeasContext.Provider
      value={{
        exerciseIndex,
        refreshIndex,
        getExercise,
        exerciseIndexDispatch,
        preguntasIndex,
        refreshPreguntasIndex,
        saveAnswer,
        announcementData,
        ready,
        isOnline,
      }}
    >
      {props.children}
    </ConectaIdeasContext.Provider>
  );
};

export { ConectaIdeasContext, ConectaIdeasProvider };
