import _ from "lodash";
import moment from "moment";

import { AnswerStatus } from "./AnswerFeedback/Draw";
import { ExerciseDataType } from "./ExerciseInterfaces";
import {
  ConnectionMovingAction,
  ConnectionReleaseAction,
  ExerciseActions,
  ExerciseActionsType,
  TextAnswerAction,
  TouchEventAction,
} from "./reducerExerciseInterfaces";
import {
  calcularBanderas,
  calcularNota,
} from "../ConectaIdeas/ConectaIdeasClient";
import {
  getCoords,
  getMaxRightAnswers,
  getOnlyConnection,
} from "../functions/exerciseFunctions";

const WAIT = 500;

export const EXERCISE_INITIAL_STATUS: ExerciseDataType = {
  exerciseId: 0,
  tareaAsignadaId: 0,
  position: 0,

  transient: {
    pendingSave: false,
    loading: true,
    modalInputVisible: false,
    showErrorCross: false,
    //these variables are set by open input modal styles
    questionText: undefined,
    answerIsNumber: undefined,
    idModal: undefined,
    correctAnswer: undefined,
    typeReviewAnswer: undefined,
    styles: undefined,
    changeText: undefined,
    svgViewBox: undefined,
    scalingFactor: undefined,
    scalingUsed: undefined,
    window: {
      h: 600,
      w: 800,
    },

    //ui state
    answerFeedback: AnswerStatus.UNKNOWN,
    lastEvent: 0, //TODO revisar y decidir que usar, seg, miliseg o iso
  },

  user: {
    delivered: false,
    completed: false,
    elapsedTime: 0,
    errorCount: 0,
    startTime: moment(Date.now()).utc().format(),
    omitido: false,
    banderas: undefined,
    nota: undefined,
    onlyConnection: undefined,
    zonesAlreadyTaken: [],
    //user answers
    connections: {},
    answers: {},
  },

  fixed: {
    //these variables are set by loading the exercise
    cx8: undefined,
    imagenes: undefined,
    recuadros: undefined,
    exerciseWidth: undefined,
    exerciseHeight: undefined,
    validator: {
      validateConnection: () => {
        throw new Error("Validator not loaded");
      },
    },
    origenPosition: {
      x: 0,
      y: 0,
      ajusteTouchX: 0,
      ajusteTouchY: 0,
    },
  },
};

function connectionMoving(
  state: ExerciseDataType,
  action: ConnectionMovingAction | TouchEventAction
): ExerciseDataType {
  const event = action.inputEvent;

  const connectionId = `rec-${event.recuadroId}/cnx-${event.connectionId}/seg-${event.segmentoId}`;
  const connection = state.user.connections[connectionId];

  if (state.user.completed) {
    //reject if exercise is already finished
    return state;
  }
  if (connection) {
    if (connection.status === AnswerStatus.RIGHT) {
      //reject event if the answer is already present
      return state;
    }
    if (connection.locked && connection.status === AnswerStatus.WRONG) {
      if (event.timestamp - connection.timestamp < WAIT) {
        //allow to choose a new answer after 500ms
        return state;
      }
    }
  }

  const coords = getCoords(event);

  const newState = _.cloneDeep(state);
  const newConnections = newState.user.connections;
  newConnections[connectionId] = {
    ...newConnections[connectionId],
    ...coords,
    status: AnswerStatus.UNKNOWN,
    locked: false,
    remainingSegmentos: event.remainingSegmentos,
    timestamp: event.timestamp,
  };

  return newState;
}

function connectionRelease(
  action: ConnectionReleaseAction | TouchEventAction,
  state: ExerciseDataType
): ExerciseDataType {
  const event = action.inputEvent;
  const zonesAlreadyTaken = [...state.user.zonesAlreadyTaken];
  let onlyConnection = { ...state.user.onlyConnection };
  const connectionKey = `rec-${event.recuadroId}/cnx-${event.connectionId}/seg-${event.segmentoId}`;
  const connection = state.user.connections[connectionKey];
  if (_.isUndefined(connection)) {
    //this happens when the user click on the ball but don't drag it.
    return state;
  }
  if (connection.locked) {
    //reject event if the answer is locked
    return state;
  }
  if (state.user.completed) {
    //reject if exercise is already finished
    return state;
  }
  const coords = getCoords(event);

  const answer = state.fixed.validator.validateConnection(
    event.recuadroId,
    event.connectionId,
    event.segmentoId,
    coords,
    state.user.zonesAlreadyTaken
  );

  const status = answer ? AnswerStatus.RIGHT : AnswerStatus.WRONG;

  const newState = _.cloneDeep(state);
  const newConnections = newState.user.connections;
  newConnections[connectionKey] = {
    ...newConnections[connectionKey],
    ...coords,
    status,
    locked: true,
    remainingSegmentos: event.remainingSegmentos,
    timestamp: event.timestamp,
    recuadroId: event.recuadroId,
    connectionId: event.connectionId,
    segmentoId: event.segmentoId,
  };

  const errorCount = state.user.errorCount + (answer ? 0 : 1);
  let banderas = 0;
  let nota = 0;
  let completed = false;

  if (answer) {
    const maxRightAnswers = getMaxRightAnswers(state.fixed.recuadros);
    const userTextAnswers = _.filter(state.user.answers, {
      status: AnswerStatus.RIGHT,
    }).length;

    const userConnections = _.filter(newConnections, {
      status: AnswerStatus.RIGHT,
    }).length;

    if (userTextAnswers + userConnections >= maxRightAnswers) {
      nota = calcularNota(maxRightAnswers, state.user.errorCount);
      banderas = calcularBanderas(maxRightAnswers, state.user.errorCount);
      completed = true;
    }
    if (state.user.onlyConnection.exist) {
      if (!_.isEmpty(state.user.onlyConnection.remainingSegmentos)) {
        const start =
          event.typeEvent === "touch"
            ? {
                x: event.x0,
                y: event.y0,
              }
            : {
                x: coords.x2,
                y: coords.y2,
              };
        onlyConnection = getOnlyConnection(
          state.user.onlyConnection.newData,
          start
        );
      } else {
        onlyConnection = getOnlyConnection(state.user.onlyConnection.newData);
      }
    }
    zonesAlreadyTaken.push(answer);
  }

  newState.transient.answerFeedback = status;
  newState.transient.pendingSave = true;
  newState.user.errorCount = errorCount;
  newState.user.completed = completed;
  newState.user.banderas = banderas;
  newState.user.nota = nota;
  newState.user.onlyConnection = onlyConnection;
  newState.user.zonesAlreadyTaken = zonesAlreadyTaken;
  newState.transient.lastEvent = event.timestamp;
  return newState;
}

function textAnswer(state: ExerciseDataType, action: TextAnswerAction) {
  if (state.user.completed) {
    //reject if exercise is already finished
    return state;
  }
  const answer = action.answer;
  const newAnswers = _.cloneDeep(state.user.answers);
  const recId = "rec" + state.transient.idModal;
  let banderas = 0;
  let nota = 1;
  let completed = false;
  const event = action.inputEvent;
  if (
    state.transient.correctAnswer.substring(0, answer.length).toUpperCase() ===
    answer.toUpperCase()
  ) {
    if (state.transient.correctAnswer.toUpperCase() === answer.toUpperCase()) {
      newAnswers[recId] = { answer, status: AnswerStatus.RIGHT };

      const maxRightAnswers = getMaxRightAnswers(state.fixed.recuadros);
      const userTextAnswers = _.filter(newAnswers, {
        status: AnswerStatus.RIGHT,
      }).length;

      const userConnections = _.filter(state.user.connections, {
        status: AnswerStatus.RIGHT,
      }).length;

      if (userTextAnswers + userConnections >= maxRightAnswers) {
        nota = calcularNota(maxRightAnswers, state.user.errorCount);
        banderas = calcularBanderas(maxRightAnswers, state.user.errorCount);
        newAnswers[recId].nota = nota;
        completed = true;
      }

      return {
        ...state,
        transient: {
          ...state.transient,
          pendingSave: true,
          modalInputVisible: false,
          answerFeedback: AnswerStatus.RIGHT,
          lastEvent: event.timestamp,
        },
        user: {
          ...state.user,
          answers: newAnswers,
          banderas,
          nota,
          completed,
        },
      };
    } else {
      return state;
    }
  } else {
    if (
      state.transient.correctAnswer.length === answer.length ||
      state.transient.typeReviewAnswer === "letterReview"
    ) {
      newAnswers[recId] = { answer, status: AnswerStatus.WRONG };

      return {
        ...state,
        transient: {
          ...state.transient,
          answerFeedback: AnswerStatus.WRONG,
          pendingSave: true,
          modalInputVisible: false,
          lastEvent: event.timestamp,
        },
        user: {
          ...state.user,
          answers: newAnswers,
          errorCount: state.user.errorCount + 1,
        },
      };
    } else {
      return state;
    }
  }
}

export function exerciseReducer(
  state: ExerciseDataType,
  action: ExerciseActionsType
): ExerciseDataType {
  switch (action.type) {
    case ExerciseActions.RESET_PENDING_SAVE_FLAG: {
      const newState = _.cloneDeep(state);
      newState.transient.pendingSave = false;
      return newState;
    }
    case ExerciseActions.HIDE_INPUT_MODAL: {
      const newState = _.cloneDeep(state);
      newState.transient.modalInputVisible = false;
      return newState;
    }
    case ExerciseActions.OPEN_INPUT_MODAL: {
      const recId = "rec" + action.payload.idModal;
      const answer = state.user.answers[recId];
      if (state.user.completed) {
        //reject if exercise is already finished
        return state;
      }
      if (answer && answer.status === AnswerStatus.RIGHT) {
        return state;
      }
      return {
        ...state,
        transient: {
          ...state.transient,
          ...action.payload,
          modalInputVisible: true,
        },
      };
    }
    case ExerciseActions.READY: {
      const n = {
        ...EXERCISE_INITIAL_STATUS,
        exerciseId: action.exercise.id,
        tareaAsignadaId: action.exercise.tarea_asignada_id,
        position: action.exercise.position,
      };
      n.user = {
        ...n.user,
        ...action.exercise.user,
        onlyConnection: action.onlyConnection,
      };
      n.fixed = { ...n.fixed, ...action.payload };
      n.transient = {
        ...n.transient,
        loading: false,
        scalingFactor: action.payload.scalingFactor,
        window: action.payload.window,
        svgViewBox: action.payload.svgViewBox,
      };
      return n;
    }
    case ExerciseActions.CONNECTION_MOVING: {
      return connectionMoving(state, action);
    }

    case ExerciseActions.CONNECTION_RELEASE: {
      return connectionRelease(action, state);
    }

    case ExerciseActions.TOUCH_EVENT: {
      //this action executes connection moving and connection release in order
      //this is to synthesize a drag and drop move with just one touch event
      const afterMoveState = connectionMoving(state, action);
      return connectionRelease(action, afterMoveState);
    }

    case ExerciseActions.TEXT_ANSWER:
      return textAnswer(state, action);

    case ExerciseActions.SKIP_EXERCISE:
      return {
        ...state,
        transient: {
          ...state.transient,
          pendingSave: true,
        },
        user: {
          ...state.user,
          banderas: 0,
          nota: 1,
          completed: true,
          omitido: true,
        },
      };

    default:
      throw new Error("Unknown action " + JSON.stringify(action));
  }
}
