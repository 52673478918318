import _ from "lodash";
import React, { useEffect, useState } from "react";

import Connection from "./Connection";
import { ConnectionType } from "./RectInterfaces";
import { RectB9, TextB9, GB9 } from "../ExerciseComponents/SvgB9";
import { getPropertyAsArray } from "../ParseUtils";
import {
  getActiveConnectionId,
  getConnectionsCounter,
} from "../functions/exerciseFunctions";

const CONEXION_BOX = {
  top: 9,
  left: 22,
};

const NUMBER_POSITION = {
  x: 32,
  y: 20,
};

function getOriginalPositionConnection(segmentos: any[]) {
  const segm = _.cloneDeep(segmentos);
  const [xInicio, yInicio] = segm[0].ini_x;
  const segmentoId = segm[0]["@id"];
  _.remove(segm, { "@id": segmentoId });
  return [parseInt(xInicio.$, 10), parseInt(yInicio.$, 10), segmentoId, segm];
}

function getState(
  recuadroId: string,
  conexiones: object,
  userConnections: ConnectionType
) {
  const [x, y, segmentoId, remainingSegmentos] = getOriginalPositionConnection(
    getPropertyAsArray(
      getPropertyAsArray(conexiones, "conexion")[0].segmentos,
      "segmento"
    )
  );
  const connectionsCounter = getConnectionsCounter(
    recuadroId,
    conexiones,
    userConnections
  );

  const stylesConnection = {
    rectangle: {
      backgroundColor: "lightgray",
      left: x - CONEXION_BOX.left,
      top: y - CONEXION_BOX.top,
      width: 45,
      height: 28,
      borderRadius: 3,
      alignItems: "center",
      borderColor: "black",
      borderWidth: 1,
    },
  };

  return {
    status: "not drawing",
    activeConnectionId: getActiveConnectionId(
      recuadroId,
      conexiones,
      userConnections
    ),
    styles: stylesConnection,
    x,
    y,
    segmentoId,
    remainingSegmentos,
    connectionsCounter,
  };
}

export default function ConnectionCounter({
  recuadroId,
  conexiones,
  userConnections,
  scalingFactor,
  onConnectionMoving,
  onConnectionRelease,
}: ConnectionCounterTypes) {
  const [state, setState] = useState(() =>
    getState(recuadroId, conexiones, userConnections)
  );

  useEffect(() => {
    const newState = getState(recuadroId, conexiones, userConnections);
    setState(newState);
  }, [recuadroId, conexiones, userConnections]);
  return (
    <GB9>
      <RectB9
        x={state.styles.rectangle.left}
        y={state.styles.rectangle.top}
        height={state.styles.rectangle.height}
        width={state.styles.rectangle.width}
        stroke="black"
        strokeWidth={1}
        fill="lightgray"
        rx={3}
        ry={3}
      />

      <TextB9
        x={state.styles.rectangle.left + NUMBER_POSITION.x}
        y={state.styles.rectangle.top + NUMBER_POSITION.y}
        textAnchor="middle"
        fill="black"
        fontSize={18}
      >
        {state.connectionsCounter}
      </TextB9>

      <Connection
        x={state.x}
        y={state.y}
        onConnectionMoving={onConnectionMoving}
        onConnectionRelease={onConnectionRelease}
        recuadroId={recuadroId}
        segmentoId={state.segmentoId}
        remainingSegmentos={state.remainingSegmentos}
        activeConnections={state.activeConnectionId}
        scalingFactor={scalingFactor}
      />
    </GB9>
  );
}

interface ConnectionCounterTypes {
  onConnectionMoving: (event: any) => void;
  onConnectionRelease: (event: any) => void;
  conexiones: object;
  recuadroId: string;
  userConnections: ConnectionType;
  scalingFactor: number;
}
