import {
  LinkingOptions,
  NavigatorScreenParams,
} from "@react-navigation/native";
import * as Linking from "expo-linking";

type ActivityType = {
  Tareas: unknown;
  Ejercicio: unknown;
};

type MetaType = {
  Pregunta: unknown;
  PreguntasMeta: unknown;
};

type LoginType = {
  SelectPais: unknown;
  InputUsername: unknown;
  SelectInstitution: unknown;
  InputPassword: unknown;
};

type LinkType = {
  Actividades: NavigatorScreenParams<ActivityType>;
  MetaStack: NavigatorScreenParams<MetaType>;
  Login: NavigatorScreenParams<LoginType>;
  Exit: unknown;
};

const config = {
  screens: {
    Actividades: {
      screens: {
        Tareas: "tareas",
        Ejercicio: {
          path: "ejercicio/:tareaAsignadaId/:exerciseId/:position",
          parse: {
            tareaAsignadaId: Number,
            exerciseId: Number,
            position: Number,
          },
        },
      },
    },
    Metacognitivas: {
      screens: {
        PreguntasMeta: "preguntas",
        Pregunta: {
          path: "pregunta/:preguntaId",
          parse: {
            preguntaId: Number,
          },
        },
      },
    },
    SelectPais: "login",
    InputUsername: {
      path: "login/:paisId",
      parse: {
        paisId: String,
      },
    },
    SelectInstitution: {
      path: "login/instituciones/:paisId/:user/:instituciones",
      parse: {
        paisId: String,
        user: String,
      },
    },
    InputPassword: {
      path: "login/:paisId/:user/:usernameId",
      parse: {
        paisId: String,
        user: String,
        usernameId: Number,
      },
    },
    Exit: "logout",
  },
};

const linking: LinkingOptions<LinkType> = {
  prefixes: [Linking.createURL("/"), "conectaideas://"],
  config,
};

export default linking;
