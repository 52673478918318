import { StyleSheet, Platform, Dimensions, ViewStyle } from "react-native";

import {
  BASIC_BUTTON,
  BASIC_BUTTON_LIST,
  BASIC_CONTAINER,
  BASIC_TEXT,
} from "./NewStyles";

const AJUSTE_HEIGHT_LIST_CONTAINER = 0.5;

const CONTAINER: ViewStyle = { ...BASIC_CONTAINER, flexDirection: "column" };

const styles = StyleSheet.create({
  buttonList: {
    ...BASIC_BUTTON_LIST,
    height: 70,
    marginBottom: 4,
    marginHorizontal: 10,
    width: "100%",
    paddingLeft: 10,
  },
});

export const loginStyles = StyleSheet.create({
  containerFlex:
    Platform.OS === "ios"
      ? { ...CONTAINER, justifyContent: "flex-start" }
      : CONTAINER,
  container: CONTAINER,
  itemContainer: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
  },
  text: BASIC_TEXT,
  textWelcomeHeader: {
    marginBottom: 3,
    fontSize: 14,
    width: 300,
    fontWeight: "bold",
  },
  textWelcome: {
    fontSize: 14,
    width: 300,
  },
  button: BASIC_BUTTON,
  textButton: { fontSize: 20, color: "white" },
  buttonDisabled: {
    ...BASIC_BUTTON,
    opacity: 0.5,
  },
  stretch: {
    width: 200,
    height: 95,
    resizeMode: "stretch",
  },
  inputContainer: {
    marginHorizontal: 2,
    bottom: 2,
    backgroundColor: "white",
    borderRadius: 3,
    borderColor: "rgb(32,96,234)",
    borderWidth: 2,
    height: 30,
    width: 300,
    paddingLeft: 5,
    flexDirection: "row",
  },
  inputPass: {
    backgroundColor: "white",
    height: "95%",
    width: "85%",
    paddingLeft: 5,
  },
  flatListContainer: {
    maxHeight: Dimensions.get("window").height * AJUSTE_HEIGHT_LIST_CONTAINER,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  flatListText: {
    fontWeight: "bold",
    fontSize: 20,
    marginTop: 20,
  },
  linkText: {
    fontSize: 15,
    marginVertical: 5,
    textDecorationLine: "underline",
  },
  buttonList: styles.buttonList,
  disabledButtonList: {
    ...styles.buttonList,
    opacity: 0.5,
  },
  containerButtons: {
    width: "100%",
    height: 10,
  },
  logoText: {
    height: 40,
    width: 85,
    resizeMode: "stretch",
    marginBottom: 0,
    paddingBottom: 0,
  },
  logoBrain: {
    height: 80,
    width: 80,
    resizeMode: "stretch",
    padding: 0,
    margin: 0,
  },
  loginContainerPeru: {
    flexDirection: "row",
    width: 300,
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoContainer: {
    flexDirection: "row",
    maxWidth: "99%",
    alignItems: "center",
  },
  flecha: {
    fontSize: 30,
  },
});
