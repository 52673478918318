import { Auth, CognitoUser } from "@aws-amplify/auth";
import React, { useState } from "react";
import { View, ActivityIndicator } from "react-native";

import RecoveryPassStackNavigator from "../../navigation/RecoveryPassStackNavigator";

export default function ChangePass() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<CognitoUser>();

  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
      if (user) {
        setUser(user);
        setLoading(false);
      }
    });
  }, []);
  return loading ? (
    <View>
      <ActivityIndicator />
    </View>
  ) : (
    <RecoveryPassStackNavigator user={user} />
  );
}
